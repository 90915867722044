import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import history from '../history'
import { getAllSupportPages } from '../store/actions/supportPageActions'
import StyleIcon from '@material-ui/icons/Style'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'

const Support = () => {
    const dispatch = useDispatch()
    const supportPageState = useSelector((state) => state.supportPage)
    const { styles } = supportPageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!styles) {
            dispatch(getAllSupportPages())
        }
    }, [dispatch, styles])
    return (
        <>
            {styles && (
                <Container disableGutters={true}>
                    <div className="support_box">
                        <div className="support_section_one">
                            <Grid container spacing={3}>
                                {styles &&
                                    styles.slice(0, 2).map((style) => (
                                        <Grid item xs={6} key={style.id}>
                                            <div
                                                className="support_item"
                                                style={{
                                                    background: `url(${style.picLink})`,
                                                    backgroundPosition:
                                                        'center',
                                                    backgroundSize: 'cover',
                                                }}
                                            >
                                                <div className="support_item_box">
                                                    <div
                                                        className="support_item_title_box"
                                                        onClick={() =>
                                                            history.push(
                                                                style.url
                                                            )
                                                        }
                                                    >
                                                        <h2>{style.name}</h2>
                                                        <p className="support_item_full">
                                                            {style.slogan}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    ))}
                            </Grid>
                        </div>
                        <div className="support_section_two">
                            <div
                                className="support_item_2"
                                style={{
                                    background: `url(${styles[2].picLink})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div className="support_item_box_2">
                                    <div
                                        className="support_item_title_box_2"
                                        onClick={() =>
                                            history.push(styles[2].url)
                                        }
                                    >
                                        <h2>{styles[2].name}</h2>
                                        <p>{styles[2].slogan}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            )}

            <Container disableGutters={true}>
                <div className="support_bottom">
                    <div
                        className="support_bottom_item"
                        onClick={() => history.push('/faq')}
                    >
                        <div className="support_bottom_item_icon">
                            <HelpOutlineIcon
                                style={{ fontSize: 40, marginRight: 10 }}
                            />
                        </div>

                        <div className="support_bottom_item_title">
                            Questions? Check FAQ
                        </div>
                    </div>
                    <div
                        className="support_bottom_item"
                        onClick={() => history.push('/sample/cabinet-sample')}
                    >
                        <div className="support_bottom_item_icon">
                            <StyleIcon
                                style={{ fontSize: 40, marginRight: 10 }}
                            />
                        </div>

                        <div className="support_bottom_item_title">
                            Order our beautiful wood sample!
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Support
