import { Container } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { getAllOrders } from '../store/actions/orderActions'
import moment from 'moment'
import { getOrderStatus } from '../constant/formula'
import history from '../history'

const ClientOrder = () => {
    const dispatch = useDispatch()
    const orderState = useSelector((state) => state.order)
    const { orders } = orderState

    useEffect(() => {
        dispatch(getAllOrders())
    }, [dispatch])

    const handleClick = (id) => {
        history.push(`/clientorderinfo/${id}`)
    }
    return (
        <Layout>
            {orders && (
                <>
                    <Container disableGutters={true}>
                        <div className="order-box">
                            <div className="order-header">
                                <div className="order-header-item">
                                    Order Date
                                </div>
                                <div className="order-header-item">
                                    Order Number
                                </div>
                                <div className="order-header-item">Status</div>
                                <div className="order-header-item">
                                    Tracking Detail
                                </div>
                            </div>
                            <div className="order-header-mobile">MY ORDERS</div>
                            {orders.map((order) => (
                                <div className="order-item" key={order.id}>
                                    <div className="order-item-info">
                                        {moment(order.orderDate).format('ll')}
                                    </div>
                                    <div className="order-item-info">
                                        <span className="order-item-mobile">
                                            Order Number:{' '}
                                        </span>
                                        {order.orderNo}
                                    </div>
                                    <div className="order-item-info mobile-strong">
                                        <span className="order-item-mobile">
                                            Status:{' '}
                                        </span>
                                        {
                                            getOrderStatus(order.orderStatus)
                                                .status
                                        }
                                    </div>
                                    <div className="order-item-info">
                                        <button
                                            className="order-item-button"
                                            onClick={() =>
                                                handleClick(order.id)
                                            }
                                        >
                                            View detail
                                        </button>
                                        <button
                                            className="order-item-button-mobile"
                                            onClick={() =>
                                                handleClick(order.id)
                                            }
                                        >
                                            Track & detail
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </Container>
                </>
            )}
        </Layout>
    )
}

export default ClientOrder
