import { useMemo } from 'react'
import Backdrop from '../backDrop/Backdrop'
import { useDispatch, useSelector } from 'react-redux'
import { addToCartPopUpAction } from '../../store/actions/productsActions'
import AddToCartList from './AddToCartList'
import history from '../../history'
import { setClientNavId } from '../../store/actions/clientActions'
import { promoCodeList } from '../../constant/promoCode'

const AddToCartPopUp = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts, promoCode, promoRate } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const subTotal = useMemo(() => {
        return carts.reduce((a, b) => (a += b.price * b.qty), 0)
    }, [carts])

    const subPromoTotal = useMemo(() => {
        let result = 0
        if (promoCode === promoCodeList.BOGO) {
            result =
                carts.reduce(
                    (a, b) => (a += b.price * parseInt(b.promoActive)),
                    0
                ) || 0
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = carts
                .filter((item) => item.promoActive === 1)
                .reduce(
                    (sum, product) =>
                        sum +
                        Number(
                            (
                                product.qty *
                                (product.price * (promoRate / 100))
                            ).toFixed(2)
                        ),
                    0
                )
        }
        return result
    }, [carts, promoRate, promoCode])
    const productsState = useSelector((state) => state.products)
    const { successAdd, addItem } = productsState
    const handleClose = () => {
        dispatch(addToCartPopUpAction(false, false, ''))
    }

    const goToCart = () => {
        handleClose()
        if (clientInfo) {
            dispatch(setClientNavId(3))
            history.push('/clientcart')
        } else {
            history.push('/cart')
        }
    }

    return (
        <div>
            <Backdrop click={handleClose} />
            <div className="atc-popup-box">
                <div className="atc-popup-header">
                    <div
                        className="atc-popup-header-left"
                        onClick={() => handleClose()}
                    >
                        ← Continue Shopping
                    </div>
                    <div
                        className="atc-popup-header-right"
                        onClick={() => goToCart()}
                    >
                        View Shopping Cart
                    </div>
                </div>
                <div className="atc-popup-addItem">
                    <div
                        className={`atc-popup-addItem-box ${
                            successAdd ? 'success' : 'error'
                        }`}
                    >
                        <span
                            style={
                                successAdd
                                    ? { color: '#106E1F' }
                                    : { color: '#DE0303' }
                            }
                        >
                            {successAdd
                                ? `You added ${addItem} to your shopping cart.`
                                : `× Error. You were unable to add ${addItem} to your shopping cart.`}
                        </span>
                    </div>
                </div>
                <div className="addToCarts-lists">
                    {carts &&
                        carts.map((cart) => (
                            <AddToCartList key={cart.id} cart={cart} />
                        ))}
                    <div className="addToCarts-Total">
                        <div>Order Total</div>
                        <div>${subTotal.toFixed(2)}</div>
                    </div>
                    {subPromoTotal > 0 && promoCode && (
                        <div className="addToCarts-promoCode">
                            <div>
                                Promo Code {promoCode}{' '}
                                {promoRate ? `-${promoRate}%` : ''}
                            </div>
                            <div> - ${subPromoTotal.toFixed(2)}</div>
                        </div>
                    )}
                </div>
                <div className="addToCarts-button">
                    <button
                        onClick={() => {
                            dispatch(addToCartPopUpAction(false, false, ''))
                            clientInfo
                                ? history.push('/clientCheckout')
                                : history.push('/continue')
                        }}
                    >
                        Proceed to checkout
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AddToCartPopUp
