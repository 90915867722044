import { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import { renderCheckbox, renderInput } from '../components/ReduxFormInputs'
import { Container, Divider } from '@material-ui/core'
import history from '../history'
import { login } from '../store/actions/clientActions'
import DisplayMessages from '../components/DisplayMessages'
import ReCAPTCHA from 'react-google-recaptcha'
import { Link } from 'react-router-dom'

const Login = (props) => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const { handleSubmit } = props
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const redirectLocation = props?.location?.state?.redirectLocation

    useEffect(() => {
        const rememberEmail = localStorage.getItem('HSClientRemember')
            ? JSON.parse(localStorage.getItem('HSClientRemember'))
            : ''
        dispatch(change('loginForm', 'email', rememberEmail))
    }, [dispatch])

    const redirect = redirectLocation ? redirectLocation : '/clientmain'
    useEffect(() => {
        window.scrollTo(0, 0)
        if (clientInfo) {
            history.push(redirect)
        }
    }, [clientInfo, redirect])
    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        dispatch(login(formValues))
    }
    return (
        <>
            <div className="divider_mobile">
                <Divider />
            </div>
            <Container disableGutters={true} maxWidth="xs">
                <div className="login-box">
                    <div className="login-title">LOGIN TO YOUR ACCOUNT</div>
                    <div>
                        <DisplayMessages />
                    </div>
                    <form
                        onSubmit={handleSubmit(submitForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Field
                            name="email"
                            type="text"
                            label="Your email"
                            max="100"
                            component={renderInput}
                        ></Field>

                        <Field
                            name="password"
                            type="password"
                            label="Your passowrd"
                            max="100"
                            component={renderInput}
                        ></Field>
                        <div className="login-remember">
                            <Field
                                name="rememberMe"
                                component={renderCheckbox}
                                label="Remember me"
                            />
                        </div>
                        <div className="google-robert">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                            />
                        </div>
                        <div className="login-button-box">
                            <button type="submit" className="login-button">
                                Login
                            </button>
                        </div>
                        <div className="login-forgetpassword">
                            <Link to="/forgetpassword">Forget password</Link>
                        </div>
                    </form>
                    <div className="login-sign-in-title">
                        DON’T HAVE AN ACCOUNT?
                    </div>
                    <div className="login-button-box">
                        <button
                            type="submit"
                            className="login-button"
                            onClick={() => history.push('/signin')}
                        >
                            Sign-in
                        </button>
                    </div>
                </div>
            </Container>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'password']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    return errors
}

export default reduxForm({
    form: 'loginForm',
    validate,
})(Login)
