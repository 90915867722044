import { Container } from '@material-ui/core'
import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field, change } from 'redux-form'
import history from '../history'
import ReCAPTCHA from 'react-google-recaptcha'
import { errorsReturn } from '../store/errorsReturn'
import { controlLoading } from '../store/actions/loadingActions'
import { getErrors } from '../store/actions/errorActions'
import { renderInput, renderTextarea } from '../components/ReduxFormInputs'
import PriceMatchDragDrop from '../components/priceMatch/PriceMatchDragDrop'
import { uploadCloudinary } from '../components/requestDesign/uploadCloudinary'
import request from '../config/request'

const PriceMatch = (props) => {
    const dispatch = useDispatch()
    const clientState = useSelector((state) => state.client)
    const { clientInfo, clientToken } = clientState
    const recaptchaRef = useRef()
    const { handleSubmit } = props
    const [photo1, setPhoto1] = useState(null)
    const [uploadError, setUploadError] = useState(null)

    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        if (photo1) {
            const photo1Link = await uploadCloudinary(photo1, dispatch)
            formValues['picLink'] = photo1Link
        } else {
            setUploadError(' Required')
            return false
        }
        formValues['clientId'] = clientInfo.id
        try {
            await request.post('/client/pricematch/create', formValues, {
                headers: { Authorization: `Bearer ${clientToken}` },
            })
            history.push('/pricematchsuccess')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!clientInfo) {
            history.replace({
                pathname: '/login',
                state: { redirectLocation: '/pricematch' },
            })
        }
    }, [clientInfo])

    useEffect(() => {
        dispatch(change('priceMatchForm', 'email', clientInfo?.email))
        dispatch(change('priceMatchForm', 'firstName', clientInfo?.firstName))
        dispatch(change('priceMatchForm', 'lastName', clientInfo?.lastName))
        dispatch(change('priceMatchForm', 'phone', clientInfo?.phone))
    }, [dispatch, clientInfo])

    return (
        <Container>
            <div className="price-match-box">
                <div className="price-match-item">
                    <div className="price-match-title">
                        <h3>Please fill your information:</h3>
                    </div>
                    <div>
                        <form
                            onSubmit={handleSubmit(submitForm)}
                            noValidate
                            autoComplete="off"
                        >
                            <div className="price-match-firstline">
                                <div className="price-match-input-item">
                                    <Field
                                        name="firstName"
                                        type="text"
                                        label="First Name"
                                        max="100"
                                        component={renderInput}
                                    ></Field>
                                </div>
                                <div className="price-match-input-item">
                                    <Field
                                        name="lastName"
                                        type="text"
                                        label="Last Name"
                                        max="100"
                                        component={renderInput}
                                    ></Field>
                                </div>
                            </div>
                            <div className="price-match-firstline">
                                <div className="price-match-input-item">
                                    <Field
                                        name="email"
                                        type="email"
                                        label="Email"
                                        max="100"
                                        component={renderInput}
                                    ></Field>
                                </div>
                                <div className="price-match-input-item">
                                    <Field
                                        name="phone"
                                        type="text"
                                        label="Phone Number"
                                        max="30"
                                        component={renderInput}
                                    ></Field>
                                </div>
                            </div>
                            <div
                                className="price-match-title"
                                style={{ marginTop: '30px' }}
                            >
                                <h3>Competitor’s Details:</h3>
                            </div>
                            <div className="price-match-firstline">
                                <div className="price-match-input-item">
                                    <Field
                                        name="quote"
                                        type="text"
                                        label="Itemized Detailed Quote"
                                        max="50"
                                        component={renderInput}
                                    ></Field>
                                </div>
                                <div className="price-match-input-item">
                                    <Field
                                        name="brand"
                                        type="text"
                                        label="Brand of Cabinets"
                                        max="100"
                                        component={renderInput}
                                    ></Field>
                                </div>
                            </div>
                            <div>
                                <div className="price-match-upload">
                                    <label className="input-label">
                                        Upload an image/screenshot
                                        {uploadError && (
                                            <span
                                                className="text-danger"
                                                style={{ fontSize: '12px' }}
                                            >
                                                {uploadError}
                                            </span>
                                        )}
                                    </label>
                                    <PriceMatchDragDrop
                                        file={photo1}
                                        setFile={setPhoto1}
                                    />
                                </div>
                            </div>
                            <div
                                className="price-match-input-item"
                                style={{ marginTop: '10px' }}
                            >
                                <Field
                                    name="description"
                                    type="text"
                                    label="Description"
                                    max="1000"
                                    component={renderTextarea}
                                ></Field>
                            </div>
                            <div className="google-robert">
                                <ReCAPTCHA
                                    ref={recaptchaRef}
                                    size="invisible"
                                    sitekey={
                                        process.env.REACT_APP_GOOGLE_SITE_KEY
                                    }
                                />
                            </div>
                            <div className="price-match-button-box">
                                <button
                                    type="submit"
                                    className="price-match-button"
                                >
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'email',
        'firstName',
        'lastName',
        'phone',
        'quote',
        'brand',
        'picLink',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }

    if (values.description && values.description.length > 1000) {
        errors.description = 'Description over input limited'
    }
    return errors
}

export default reduxForm({
    form: 'priceMatchForm',
    validate,
})(PriceMatch)
