import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllPlannerPages } from '../store/actions/plannerPageActions'
import { plannerHelpingList } from '../constant/toolsList'
import HelpingTools from '../components/helpingTools/HelpingTools'
import history from '../history'
import DesignCenter from '../components/DesignCenter'
import { Divider } from '@material-ui/core'
import YouTube from 'react-youtube'
import Meta from '../components/Meta'

const opts = {
    width: '100%',
}

const Planner = () => {
    const dispatch = useDispatch()
    const plannerPageState = useSelector((state) => state.plannerPage)
    const { styles } = plannerPageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!styles) {
            dispatch(getAllPlannerPages())
        }
    }, [dispatch, styles])
    return (
        <>
            <Meta
                title={
                    'Base Kitchen Cabinet Mississauga, Bathroom Cabinets Mississauga'
                }
                description={
                    'We know that cabinets set the tone of the kitchen, and Hspace Kitchen and Bath is dedicated to provide you with premium quality custom kitchen cabinets.'
                }
            />
            {styles && (
                <Container disableGutters={true}>
                    <div className="plan_section_one">
                        <div className="plan_section_one_pic">
                            <img
                                src={styles[0].picLink}
                                alt="pic"
                                width="100%"
                            />
                        </div>
                        <div className="plan_section_one_detail">
                            <div className="plan_section_one_detail_title">
                                {styles[0].name}
                            </div>
                            <div>{styles[0].slogan}</div>
                            <div>
                                <div
                                    className="plan_section_one_button"
                                    onClick={() => history.push('/bookdesign')}
                                >
                                    Book a designer for free
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        // className="home_youtube_container"
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: '30px 0',
                        }}
                    >
                        <div style={{ width: '60%' }}>
                            <YouTube videoId="QYO0-eToPW4" opts={opts} />
                        </div>
                    </div>
                    <DesignCenter />
                </Container>
            )}
            <HelpingTools helpingList={plannerHelpingList} />

            <Container disableGutters={true}>
                <Divider />
                <div className="planner-designer-container">
                    <div className="planner-designer-left">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1642045244/Request_Design/Screen_Shot_2022-01-12_at_10.40.07_PM_jnngdz.png"
                            alt="designer-logo"
                            className="planner-designer-img"
                        />
                    </div>
                    <div className="planner-designer-right">
                        <h2>Want to become a designer?</h2>
                        <p>Join us and reward with projects</p>
                        <a
                            href="https://designer.hs-online.com"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <button>Join us</button>
                        </a>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Planner
