import Container from '@material-ui/core/Container'
import BlockIcon from '@material-ui/icons/Block'

function Default() {
    return (
        <Container>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    minHeight: '50vh',
                }}
            >
                <h1 style={{ fontSize: '100px', fontFamily: 'Oswald' }}>404</h1>
                <div
                    style={{
                        width: '100%',
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        fontFamily: 'Oswald',
                    }}
                >
                    <BlockIcon
                        style={{
                            fontSize: 30,
                            color: 'red',
                            marginRight: '10px',
                        }}
                    />{' '}
                    <h3 style={{ fontFamily: 'Oswald' }}>Page Not Found!</h3>
                </div>
                <p style={{ fontFamily: 'Oswald' }}>
                    The requested could not found in this server
                </p>
            </div>
        </Container>
    )
}

export default Default
