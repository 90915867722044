import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_SELFASSEMBLES } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllSelfAssembles = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/support/selfassemble')
        dispatch({
            type: GET_ALL_SELFASSEMBLES,
            payload: data.data,
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
