import React from 'react'
import PrintCartDetails from './PrintCartDetails'

export const ComponentToPrint = React.forwardRef((props, ref) => {
    return (
        <div ref={ref}>
            <PrintCartDetails />
        </div>
    )
})
