import Container from '@material-ui/core/Container'
import history from '../../history'

const HelpingTools = ({ helpingList }) => {
    return (
        <Container
            disableGutters={true}
            style={{ marginTop: '60px', marginBottom: '40px' }}
        >
            <div className="home_pic_point_title_area">
                <div>
                    <h3>Helping Tools</h3>
                </div>
                <div className="home_pic_point_yellow_line"></div>
            </div>
            <div className="home_help_area">
                <div className="home_help_box">
                    {helpingList.map((list) => (
                        <div key={list.name} className="home_help_inside">
                            <div
                                className="home_help_icon"
                                onClick={() => history.push(list.url)}
                            >
                                {list.icon}
                                <div className="home_help_name_mobile">
                                    {list.name}
                                </div>
                            </div>
                            <div className="home_help_name">{list.name}</div>
                        </div>
                    ))}
                </div>
            </div>
        </Container>
    )
}

export default HelpingTools
