import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Checkbox from '@material-ui/core/Checkbox'
import {
    CHANGE_PRODUCTS_COLOR,
    CHANGE_PRODUCTS_FUNCTION,
    CHANGE_PRODUCTS_ORDERBY,
    CHANGE_PRODUCTS_SIZE,
} from '../../store/types'

const ProductListFilter = () => {
    const dispatch = useDispatch()
    const [openColor, setOpenColor] = useState(false)
    const [openSize, setOpenSize] = useState(false)
    const [openFunction, setOpenFunction] = useState(false)
    const [openSort, setOpenSort] = useState(false)
    const [selectFunc, setSelectFunc] = useState([])
    const [selectSize, setSelectSize] = useState([])

    const productsState = useSelector((state) => state.products)
    const {
        productFunctions,
        categoryInfo,
        styleInfo,
        searchColor,
        searchOrderBy,
    } = productsState

    const closeAll = () => {
        setOpenColor(false)
        setOpenSize(false)
        setOpenFunction(false)
        setOpenSort(false)
    }
    const sortList = [
        { name: 'Most popular', id: 1 },
        { name: 'Price: low to high', id: 2 },
        { name: 'Price: high to low', id: 3 },
    ]

    const handleFuncChange = (e) => {
        const funcId = e.target.value
        let newFunc = []
        if (selectFunc.includes(funcId)) {
            newFunc = selectFunc.filter((func) => func !== funcId)
        } else {
            newFunc = [...selectFunc, e.target.value]
        }
        if (newFunc.length > 0) {
            dispatch({
                type: CHANGE_PRODUCTS_FUNCTION,
                payload: newFunc.join(','),
            })
        } else {
            dispatch({ type: CHANGE_PRODUCTS_FUNCTION, payload: '' })
        }
        setSelectFunc(newFunc)
    }

    const handleSizeChange = (e) => {
        const sizeId = e.target.value
        let newSize = []
        if (selectSize.includes(sizeId)) {
            newSize = selectSize.filter((size) => size !== sizeId)
        } else {
            newSize = [...selectSize, e.target.value]
        }
        if (newSize.length > 0) {
            dispatch({
                type: CHANGE_PRODUCTS_SIZE,
                payload: newSize.join(','),
            })
        } else {
            dispatch({ type: CHANGE_PRODUCTS_SIZE, payload: '' })
        }
        setSelectSize(newSize)
    }

    return (
        <div className="product_filter_container">
            <div className="product_filter_button_grop">
                <div className="product_filter_button_item">
                    <button
                        onClick={() => {
                            closeAll()
                            setOpenColor(!openColor)
                        }}
                        className={`product_filter_button ${
                            openColor ? 'product_filter_button_selected' : null
                        }`}
                    >
                        Color
                    </button>
                    {openColor && (
                        <div className="product_filter_openitem">
                            {styleInfo &&
                                styleInfo?.stylecolors?.map((color) => (
                                    <div
                                        className="product_filter_openitem_map"
                                        key={color.id}
                                    >
                                        <div className="product_filter_openitem_item">
                                            {color.colorName}
                                        </div>
                                        <div className="product_filter_openitem_select">
                                            <Checkbox
                                                style={{
                                                    color: '#EDC400',
                                                }}
                                                checked={
                                                    searchColor === color.id
                                                }
                                                onChange={() =>
                                                    searchColor === color.id
                                                        ? dispatch({
                                                              type: CHANGE_PRODUCTS_COLOR,
                                                              payload: '',
                                                          })
                                                        : dispatch({
                                                              type: CHANGE_PRODUCTS_COLOR,
                                                              payload: color.id,
                                                          })
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'color checkbox',
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
                <div className="product_filter_button_item">
                    <button
                        onClick={() => {
                            closeAll()
                            setOpenSize(!openSize)
                        }}
                        className={`product_filter_button ${
                            openSize ? 'product_filter_button_selected' : ''
                        }`}
                    >
                        Size
                    </button>
                    {openSize && (
                        <div className="product_filter_openitem">
                            {categoryInfo &&
                                categoryInfo?.sizes?.map((size) => (
                                    <div
                                        className="product_filter_openitem_map"
                                        key={size.id}
                                    >
                                        <div className="product_filter_openitem_item">
                                            {size.sizeValue}
                                        </div>
                                        <div className="product_filter_openitem_select">
                                            <Checkbox
                                                style={{
                                                    color: '#EDC400',
                                                }}
                                                checked={selectSize.includes(
                                                    size.id
                                                )}
                                                value={size.id}
                                                onChange={(e) =>
                                                    handleSizeChange(e)
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'size checkbox',
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                    )}
                </div>
                {productFunctions && (
                    <div className="product_filter_button_item">
                        <button
                            onClick={() => {
                                closeAll()
                                setOpenFunction(!openFunction)
                            }}
                            className={`product_filter_button ${
                                openFunction
                                    ? 'product_filter_button_selected'
                                    : ''
                            }`}
                        >
                            Function
                        </button>
                        {openFunction && (
                            <div className="product_filter_openitem_right">
                                {productFunctions?.map((functionList) => (
                                    <div
                                        className="product_filter_openitem_map"
                                        key={functionList.id}
                                    >
                                        <div className="product_filter_openitem_item">
                                            {functionList.functionName}
                                        </div>
                                        <div className="product_filter_openitem_select">
                                            <Checkbox
                                                style={{
                                                    color: '#EDC400',
                                                }}
                                                checked={selectFunc.includes(
                                                    functionList.id
                                                )}
                                                value={functionList.id}
                                                onChange={(e) =>
                                                    handleFuncChange(e)
                                                }
                                                inputProps={{
                                                    'aria-label':
                                                        'func checkbox',
                                                }}
                                            />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}
                <div className="product_filter_button_item">
                    <button
                        onClick={() => {
                            closeAll()
                            setOpenSort(!openSort)
                        }}
                        className={`product_filter_button ${
                            openSort ? 'product_filter_button_selected' : ''
                        }`}
                    >
                        Sort
                    </button>
                    {openSort && (
                        <div className="product_filter_openitem_right">
                            {sortList?.map((sort) => (
                                <div
                                    className="product_filter_openitem_map"
                                    key={sort.id}
                                >
                                    <div className="product_filter_openitem_item">
                                        {sort.name}
                                    </div>
                                    <div className="product_filter_openitem_select">
                                        <Checkbox
                                            style={{
                                                color: '#EDC400',
                                            }}
                                            checked={searchOrderBy === sort.id}
                                            onChange={() =>
                                                searchOrderBy === sort.id
                                                    ? dispatch({
                                                          type: CHANGE_PRODUCTS_ORDERBY,
                                                          payload: '',
                                                      })
                                                    : dispatch({
                                                          type: CHANGE_PRODUCTS_ORDERBY,
                                                          payload: sort.id,
                                                      })
                                            }
                                            inputProps={{
                                                'aria-label':
                                                    'primary checkbox',
                                            }}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default ProductListFilter
