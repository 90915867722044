import { useState, useEffect, useMemo } from 'react'
import { Divider } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import PromoCodeInput from '../cart/PromoCodeInput'
import { promoCodeList } from '../../constant/promoCode'
import { checkPromoCode } from '../../store/actions/cartActions'

const CheckoutTotal = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts, promoCode, shippingType, shippingOption, promoRate } =
        cartState

    const [shippingFee, setShippingFee] = useState(0)

    useEffect(() => {
        if (promoCode) {
            const values = { promoCode }
            dispatch(checkPromoCode(values))
        }
    }, [dispatch, promoCode])

    const subTotal = useMemo(() => {
        return carts.reduce((a, b) => (a += b.price * b.qty), 0)
    }, [carts])

    const subPromoTotal = useMemo(() => {
        let result = 0
        if (promoCode === promoCodeList.BOGO) {
            result =
                carts.reduce(
                    (a, b) => (a += b.price * parseInt(b.promoActive)),
                    0
                ) || 0
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = carts
                .filter((item) => item.promoActive === 1)
                .reduce(
                    (sum, product) =>
                        sum +
                        Number(
                            (
                                product.qty *
                                (product.price * (promoRate / 100))
                            ).toFixed(2)
                        ),
                    0
                )
        }
        return result
    }, [carts, promoCode, promoRate])

    useEffect(() => {
        if (shippingOption?.shippingType === 1) {
            setShippingFee(shippingOption.shippingFee)
        } else {
            setShippingFee(0)
        }
    }, [shippingType, shippingOption])

    return (
        <>
            <div className="checkout-total-box">
                <div className="checkout-total-item">
                    <span className="checkout-total-span-left-strong">
                        Merchandise Subtotal
                    </span>
                    <span className="checkout-total-span-right">
                        <strong>${subTotal.toFixed(2)}</strong>
                    </span>
                </div>
                {shippingOption?.shippingType === 1 && (
                    <div className="checkout-total-item">
                        <span className="checkout-total-span-left-strong">
                            Shipping
                        </span>
                        <span className="checkout-total-span-right">
                            <strong>
                                {shippingFee ? `$${shippingFee}` : 'Free'}
                            </strong>
                        </span>
                    </div>
                )}
                {subPromoTotal > 0 && promoCode && (
                    <div className="checkout-total-item">
                        <span className="checkout-total-span-left">
                            {promoCode} {promoRate ? `-${promoRate}%` : ''}
                        </span>
                        <span className="checkout-total-span-right">
                            - ${subPromoTotal.toFixed(2)}
                        </span>
                    </div>
                )}
                <div className="checkout-total-item">
                    <span className="checkout-total-span-left">GST/HST</span>
                    <span className="checkout-total-span-right">
                        $
                        {(
                            (subTotal - subPromoTotal + shippingFee) *
                            0.13
                        ).toFixed(2)}
                    </span>
                </div>
                <Divider />
                <div
                    className="checkout-total-item"
                    style={{ marginTop: '10px' }}
                >
                    <span className="checkout-total-span-left">
                        <strong>Total</strong>
                    </span>
                    <span className="checkout-total-span-right">
                        <strong>
                            $
                            {(
                                (subTotal - subPromoTotal + shippingFee) *
                                1.13
                            ).toFixed(2)}
                        </strong>
                    </span>
                </div>
            </div>
            <PromoCodeInput />
        </>
    )
}

export default CheckoutTotal
