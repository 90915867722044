import {
    Checkbox,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    Select,
    TextField,
} from '@material-ui/core'

export const renderInput = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="input-box">
        <label className="input-label">
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <input
            className={`form-control ${touched && error && 'is-invalid'}`}
            type={type}
            maxLength={max}
            {...input}
        />
    </div>
)

export const renderTextarea = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="input-box">
        <label className="input-label">
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <textarea
            className={`form-control-textarea ${
                touched && error && 'is-invalid'
            }`}
            type={type}
            maxLength={max}
            {...input}
        />
    </div>
)

export const renderInputSmall = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="input-box-small">
        <label className="input-label">
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <input
            className={`form-control-small ${touched && error && 'is-invalid'}`}
            type={type}
            maxLength={max}
            {...input}
        />
    </div>
)

export const renderInputNoLabel = ({
    input,
    label,
    type,
    max = '255',
    meta: { touched, error },
}) => (
    <div className="input-box-nolabel">
        <input
            className={`form-control-nolabel ${
                touched && error && 'is-invalid'
            }`}
            type={type}
            maxLength={max}
            placeholder={label}
            {...input}
        />
        <label className="input-label">
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
    </div>
)

export const renderTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    ...custom
}) => {
    return (
        <TextField
            label={label}
            placeholder={label}
            error={touched && invalid}
            helperText={touched && error}
            className="inputRounded"
            style={{ border: '1px solid #000' }}
            // size="small"
            {...input}
            {...custom}
        />
    )
}

export const renderSelect = ({ input, meta: { touched, error }, children }) => (
    <div>
        <select
            className={`form-control-nolabel-select ${
                touched && error && 'is-invalid'
            }`}
            {...input}
        >
            {children}
        </select>
        <label className="input-label">
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
    </div>
)

export const renderSelectSmall = ({
    input,
    label,
    meta: { touched, error },
    children,
}) => (
    <div className="input-box-small">
        <label className="input-label">
            {label}{' '}
            {touched && error && (
                <span className="text-danger" style={{ fontSize: '12px' }}>
                    {error}
                </span>
            )}
        </label>
        <select
            className={`form-control-small ${touched && error && 'is-invalid'}`}
            {...input}
        >
            {children}
        </select>
    </div>
)

export const renderSelectField = ({
    input,
    name,
    label,
    meta: { touched, error },
    children,
    ...custom
}) => (
    <FormControl error={touched && error} style={{ width: '100%' }}>
        <InputLabel htmlFor={`${name}-native-simple`}>{label}</InputLabel>
        <Select
            native
            {...input}
            {...custom}
            inputProps={{
                name,
                id: `${name}-native-simple`,
            }}
        >
            {children}
        </Select>
        {renderFromHelper({ touched, error })}
    </FormControl>
)

const renderFromHelper = ({ touched, error }) => {
    if (!(touched && error)) {
        return
    } else {
        return <FormHelperText>{touched && error}</FormHelperText>
    }
}

export const checkboxGroup = ({ label, required, options, input, meta }) =>
    options.map((option, index) => (
        <div key={index}>
            <FormControlLabel
                control={
                    <Checkbox
                        name={`${input.name}[${index}]`}
                        value={option.id}
                        color="primary"
                        checked={input.value.indexOf(option.id) !== -1}
                        onChange={(event) => {
                            const newValue = [...input.value]
                            if (event.target.checked) {
                                newValue.push(option.id)
                            } else {
                                newValue.splice(newValue.indexOf(option.id), 1)
                            }

                            return input.onChange(newValue)
                        }}
                    />
                }
                label={option.name}
            />
        </div>
    ))

export const renderCheckbox = ({ input, label }) => (
    <div>
        <FormControlLabel
            control={
                <Checkbox
                    style={{
                        color: '#707070',
                    }}
                    checked={input.value ? true : false}
                    onChange={input.onChange}
                />
            }
            label={label}
        />
    </div>
)
