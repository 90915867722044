export const GET_ALL_NAV_CATEGORIES = 'GET_ALL_NAV_CATEGORIES'
export const SHOW_LOADING = 'SHOW_LOADING'
export const GET_ERRORS = 'GET_ERRORS'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'
export const USER_LOGOIN_SUCCESS = 'USER_LOGOIN_SUCCESS'
export const USER_LOGOUT = 'USER_LOGOUT'
export const GET_ONE_USER = 'GET_ONE_USER'
export const USER_UPDATE_INFO = 'USER_UPDATE_INFO'

export const GET_ALL_CARTS = 'GET_ALL_CARTS'

export const GET_ALL_NAV_ITEMS = 'GET_ALL_NAV_ITEMS'
export const GET_ALL_HOME_SMALL_PICS = 'GET_ALL_HOME_SMALL_PICS'
export const GET_ALL_HOME_PIC_POINTS = 'GET_ALL_HOME_PIC_POINTS'
export const GET_HOMEPAGE = 'GET_HOMEPAGE'

export const GET_ALL_PRODUCTS_PAGE = 'GET_ALL_PRODUCTS_PAGE'
export const GET_ALL_KITCHEN_PAGE = 'GET_ALL_KITCHEN_PAGE'
export const GET_ALL_VANITIES_PAGE = 'GET_ALL_VANITIES_PAGE'
export const GET_ALL_ACCESSORIES_PAGE = 'GET_ALL_ACCESSORIES_PAGE'
export const GET_ALL_PLANNER_PAGE = 'GET_ALL_PLANNER_PAGE'
export const GET_ALL_SUPPORT_PAGE = 'GET_ALL_SUPPORT_PAGE'

export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ONE_PRODUCT = 'GET_ONE_PRODUCT'
export const CHANGE_PRODUCTS_PAGE = 'CHANGE_PRODUCTS_PAGE'
export const SEARCH_PRODUCTS = 'SEARCH_PRODUCTS'
export const SEARCH_PRODUCTS_KEYWORD = 'SEARCH_PRODUCTS_KEYWORD'
export const CHANGE_PRODUCTS_TYPE = 'CHANGE_PRODUCTS_TYPE'
export const CHANGE_PRODUCTS_COLOR = 'CHANGE_PRODUCTS_COLOR'
export const CHANGE_PRODUCTS_SIZE = 'CHANGE_PRODUCTS_SIZE'
export const CHANGE_PRODUCTS_FUNCTION = 'CHANGE_PRODUCTS_FUNCTION'
export const CHANGE_PRODUCTS_ORDERBY = 'CHANGE_PRODUCTS_ORDERBY'
export const SHOW_PRODUCT_OPTION = 'SHOW_PRODUCT_OPTION'
export const GET_ALL_WARRANTIES = 'GET_ALL_WARRANTIES'
export const GET_ALL_POLICIES = 'GET_ALL_POLICIES'
export const GET_ALL_CABINETCARE = 'GET_ALL_CABINETCARE'
export const GET_ALL_SELFASSEMBLES = 'GET_ALL_SELFASSEMBLES'
export const GET_ALL_FAQS = 'GET_ALL_FAQS'
export const SEARCH_FAQS = 'SEARCH_FAQS'
export const GET_ALL_ABOUTUS = 'GET_ALL_ABOUTUS'
export const GET_ALL_SAMPLES = 'GET_ALL_SAMPLES'
export const SAMPLE_TOP_PAGE = 'SAMPLE_TOP_PAGE'
export const SEARCH_BAR_STATUS = 'SEARCH_BAR_STATUS'

export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_POPUP_CONTROL = 'ADD_TO_CART_POPUP_CONTROL'
export const CART_REMOVE_ITEM = 'CART_REMOVE_ITEM'
export const CART_CHANGE_QTY = 'CART_CHANGE_QTY'
export const CLEANER_CARTS = 'CLEANER_CARTS'
export const GET_ALL_RECOMMENDS = 'GET_ALL_RECOMMENDS'
export const UPDATE_PROMO_CODE = 'UPDATE_PROMO_CODE'
export const UPDATE_PROMO_BMSM2022_CODE = 'UPDATE_PROMO_BMSM2022_CODE'
export const UPDATE_PROMO_CART = 'UPDATE_PROMO_CART'
export const REMOVE_PROMO_CART = 'REMOVE_PROMO_CART'

export const CLIENT_LOGOIN_SUCCESS = 'CLIENT_LOGOIN_SUCCESS'
export const CLIENT_LOGOUT = 'CLIENT_LOGOUT'
export const CLIENT_UPDATE_INFO = 'CLIENT_UPDATE_INFO'
export const CLIENT_NAV_ID = 'CLIENT_NAV_ID'

export const MODIFY_POSTCODE = 'MODIFY_POSTCODE'
export const SHIPPING_OPTION = 'SHIPPING_OPTION'
export const SHIPPING_INFORMATION = 'SHIPPING_INFORMATION'
export const SHIPPING_BILLING = 'SHIPPING_BILLING'
export const SHIPPING_STAGE = 'SHIPPING_STAGE'
export const SHIPPING_TYPE = 'SHIPPING_TYPE'
export const GET_DELIVERY_LOCATIONS = 'GET_DELIVERY_LOCATIONS'
export const GET_PICKUP_LOCATIONS = 'GET_PICKUP_LOCATIONS'

export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ONE_ORDER = 'GET_ONE_ORDER'

export const DISPLAY_ERROR_MESSAGE = 'DISPLAY_ERROR_MESSAGE'
export const DISPLAY_MESSAGE = 'DISPLAY_MESSAGE'

export const GET_ALL_REQUEST_DESIGNS = 'GET_ALL_REQUEST_DESIGNS'
export const GET_ONE_REQUEST_DESIGN = 'GET_ONE_REQUEST_DESIGN'
