import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { shippingStage } from '../../store/actions/cartActions'
import CheckoutBilling from './CheckoutBilling'
import CheckoutDisplayOption from './CheckoutDisplayOption'
import CheckoutInformation from './CheckoutInformation'
import CheckoutPostCode from './CheckoutPostCode'
import CheckoutShippingOption from './CheckoutShippingOption'
// import CheckoutStripe from './CheckoutStripe'
import CheckoutWithoutPay from './CheckoutWithoutPay'
const CheckoutStage = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { stage, postCode, shippingOption } = cartState

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [stage])

    useEffect(() => {
        if (!shippingOption) {
            dispatch(shippingStage(1))
        }
    }, [shippingOption, dispatch])
    return (
        <>
            <div className="checkout-stage-mobile">
                <div className="checkout-icon">
                    {stage !== 1 ? (
                        <span
                            className="checkout-number"
                            style={{
                                background: '#FFF5C4',
                                cursor: 'pointer',
                                color: '#000000',
                            }}
                            onClick={() => dispatch(shippingStage(1))}
                        >
                            1
                        </span>
                    ) : (
                        <span
                            className="checkout-number"
                            style={{ background: '#FDDC3A' }}
                        >
                            1
                        </span>
                    )}
                </div>
                <div className="checkout-dots">.....</div>
                <div className="checkout-icon">
                    {stage > 2 ? (
                        <span
                            className="checkout-number"
                            style={
                                stage === 2
                                    ? { background: '#FDDC3A' }
                                    : {
                                          background: '#FFF5C4',
                                          color: '#000000',
                                      }
                            }
                            onClick={() => dispatch(shippingStage(2))}
                        >
                            2
                        </span>
                    ) : (
                        <span
                            className="checkout-number"
                            style={
                                stage === 2
                                    ? { background: '#FDDC3A' }
                                    : { background: '#D6D6D6' }
                            }
                        >
                            2
                        </span>
                    )}
                </div>
                <div className="checkout-dots">.....</div>
                <div className="checkout-icon">
                    {stage > 3 ? (
                        <span
                            className="checkout-number"
                            style={
                                stage === 3
                                    ? { background: '#FDDC3A' }
                                    : {
                                          background: '#FFF5C4',
                                          color: '#000000',
                                      }
                            }
                            onClick={() => dispatch(shippingStage(3))}
                        >
                            3
                        </span>
                    ) : (
                        <span
                            className="checkout-number"
                            style={
                                stage === 3
                                    ? { background: '#FDDC3A' }
                                    : { background: '#D6D6D6' }
                            }
                        >
                            3
                        </span>
                    )}
                </div>
                <div className="checkout-dots">.....</div>
                <div className="checkout-icon">
                    <span
                        className="checkout-number"
                        style={
                            stage === 4
                                ? { background: '#FDDC3A' }
                                : { background: '#D6D6D6' }
                        }
                    >
                        4
                    </span>
                </div>
            </div>
            <div className="checkout-all-box">
                <div className="checkout-all-info-box">
                    <div className="checkout-icon checkout-icon-mobile">
                        {stage !== 1 ? (
                            <span
                                className="checkout-number"
                                style={{
                                    background: '#FFF5C4',
                                    cursor: 'pointer',
                                    color: '#000000',
                                }}
                                onClick={() => dispatch(shippingStage(1))}
                            >
                                1
                            </span>
                        ) : (
                            <span
                                className="checkout-number"
                                style={{ background: '#edc400' }}
                            >
                                1
                            </span>
                        )}
                    </div>
                    <div className="checkout-info">
                        <div
                            className="checkout-title"
                            style={
                                stage === 1
                                    ? { color: '#000000' }
                                    : { color: '#b4b2b2' }
                            }
                        >
                            <span className="checkout-mobile-only">1. </span>
                            Shipping Options:
                        </div>
                        <div className="checkout-detail">
                            {stage === 1 && <CheckoutPostCode />}
                            {stage === 1 && postCode && (
                                <CheckoutShippingOption />
                            )}

                            {stage === 1 && shippingOption && (
                                <div className="checkout-continue-button-area">
                                    <button
                                        className="checkout-continue-button"
                                        onClick={() =>
                                            dispatch(shippingStage(2))
                                        }
                                    >
                                        Continue
                                    </button>
                                </div>
                            )}
                            {stage !== 1 && shippingOption && (
                                <CheckoutDisplayOption />
                            )}
                        </div>
                    </div>
                </div>
                <div className="checkout-all-info-box">
                    <div className="checkout-icon checkout-icon-mobile">
                        {stage > 2 ? (
                            <span
                                className="checkout-number"
                                style={{
                                    background: '#FFF5C4',
                                    cursor: 'pointer',
                                    color: '#000000',
                                }}
                                onClick={() => dispatch(shippingStage(2))}
                            >
                                2
                            </span>
                        ) : (
                            <span
                                className="checkout-number"
                                style={
                                    stage === 2
                                        ? { background: '#edc400' }
                                        : null
                                }
                            >
                                2
                            </span>
                        )}
                    </div>
                    <div className="checkout-info">
                        <div
                            className="checkout-title"
                            style={
                                stage === 2
                                    ? { color: '#000000' }
                                    : { color: '#b4b2b2' }
                            }
                        >
                            <span className="checkout-mobile-only">2. </span>
                            Please fill your information:
                        </div>
                        {stage === 2 && <CheckoutInformation />}
                    </div>
                </div>
                <div className="checkout-all-info-box">
                    <div className="checkout-icon checkout-icon-mobile">
                        {stage > 3 ? (
                            <span
                                className="checkout-number"
                                style={{
                                    background: '#FFF5C4',
                                    cursor: 'pointer',
                                    color: '#000000',
                                }}
                                onClick={() => dispatch(shippingStage(3))}
                            >
                                3
                            </span>
                        ) : (
                            <span
                                className="checkout-number"
                                style={
                                    stage === 3
                                        ? { background: '#edc400' }
                                        : null
                                }
                            >
                                3
                            </span>
                        )}
                    </div>
                    <div className="checkout-info">
                        <div
                            className="checkout-title"
                            style={
                                stage === 3
                                    ? { color: '#000000' }
                                    : { color: '#b4b2b2' }
                            }
                        >
                            <span className="checkout-mobile-only">3. </span>
                            Billing address:
                        </div>
                        {stage === 3 && <CheckoutBilling />}
                    </div>
                </div>
                <div className="checkout-all-info-box-last">
                    <div className="checkout-icon checkout-icon-mobile">
                        <span
                            className="checkout-number"
                            style={
                                stage === 4 ? { background: '#edc400' } : null
                            }
                        >
                            4
                        </span>
                    </div>
                    <div className="checkout-info">
                        <div
                            className="checkout-title"
                            style={
                                stage === 4
                                    ? { color: '#000000' }
                                    : { color: '#b4b2b2' }
                            }
                        >
                            <span className="checkout-mobile-only">4. </span>
                            {/* How would you like to pay? */}
                            Please confirm your order
                        </div>
                        {/* {stage === 4 && <CheckoutStripe />} */}
                        {stage === 4 && <CheckoutWithoutPay />}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CheckoutStage
