import { GET_ALL_POLICIES } from '../types'

const initialState = {
    style: null,
    policies: null,
}

export const policyReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_POLICIES:
            return {
                ...state,
                style: payload.style,
                policies: payload.policies,
            }

        default:
            return state
    }
}

export default policyReducer
