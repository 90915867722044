import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { distantCal } from '../../constant/getDistance'
import { getAllPickUpLocations } from '../../store/actions/cartActions'
import Loader from '../Loader'
import CheckoutPickUpList from './CheckoutPickUpList'

const CheckoutPickUp = () => {
    const dispatch = useDispatch()
    const [pickUpAddresses, setPickUpAddresses] = useState([])
    const cartState = useSelector((state) => state.cart)
    const { postCode, pickupLocations } = cartState

    useEffect(() => {
        dispatch(getAllPickUpLocations())
    }, [dispatch])

    useEffect(() => {
        if (pickupLocations?.length > 0) {
            const getNewAddress = async () => {
                const newAddress = []
                for (let i = 0; i < pickupLocations.length; i++) {
                    const location = pickupLocations[i]
                    const address =
                        location.address1.trim() +
                        ', ' +
                        location.city.trim() +
                        ', ' +
                        location.province.trim() +
                        ', ' +
                        location.country.trim()
                    const distance = await distantCal(postCode, address)
                    const values = { ...location, address, distance }
                    newAddress.push(values)
                }
                const addAddress = newAddress
                    .sort((a, b) => a.distance - b.distance)
                    .slice(0, 2)
                setPickUpAddresses(addAddress)
            }
            getNewAddress()
        }
    }, [postCode, pickupLocations])

    return (
        <div className="pickup-box">
            <div className="pickup-title">Choose a pick up location</div>
            <div className="pickup-detail">
                We will send you an conformation email when the order is ready,
                it normally take 2-3 days
            </div>
            <div className="pickup-address-box">
                {pickUpAddresses?.length > 0 ? (
                    pickUpAddresses.map((location) => (
                        <CheckoutPickUpList
                            key={location.id}
                            location={location}
                        />
                    ))
                ) : (
                    <Loader />
                )}
            </div>
        </div>
    )
}

export default CheckoutPickUp
