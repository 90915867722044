import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllSamples, sampleTopPage } from '../store/actions/sampleActions'
import history from '../history'
import { SAMPLE_CABINET } from '../constant/default'
import Cabinet from '../components/sample/Cabinet'
import Countertop from '../components/sample/Countertop'

const Samples = ({ match }) => {
    const slug = match.params.slug
    const dispatch = useDispatch()
    const sampleState = useSelector((state) => state.sample)
    const { samples, topPage } = sampleState

    useEffect(() => {
        if (topPage) {
            window.scrollTo(0, 0)
        }
        dispatch(getAllSamples(slug))
    }, [dispatch, slug, topPage])

    return (
        samples && (
            <>
                <Container disableGutters={true}>
                    <div
                        className="warranty_style_title"
                        style={{
                            background: `url(${samples.category.bigPicLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="warranty_style_slogan">
                            <h1 className="warranty_style_slogan_word">
                                {samples.category.slogan}
                            </h1>
                        </div>
                    </div>
                </Container>
                <Container disableGutters={true}>
                    <div className="sample_title">{samples.slogan}</div>
                    <div className="sample_description">
                        {samples.description}
                    </div>
                </Container>

                <Container disableGutters={true}>
                    <div className="sample_style">
                        {samples.category.styles.map((style) => (
                            <div
                                className="sample_style_item"
                                key={style.id}
                                style={
                                    style.slug === samples.slug
                                        ? {
                                              background: '#FDDC3A',
                                              border: 'none',
                                          }
                                        : null
                                }
                                onClick={() => {
                                    dispatch(sampleTopPage(false))
                                    history.push(`/sample/${style.slug}`)
                                }}
                            >
                                {style.name}
                            </div>
                        ))}
                    </div>
                </Container>

                <Container disableGutters={true}>
                    {samples.slug === SAMPLE_CABINET ? (
                        <Cabinet samples={samples} />
                    ) : (
                        <Countertop samples={samples} />
                    )}
                </Container>
            </>
        )
    )
}

export default Samples
