import {
    CHANGE_PRODUCTS_COLOR,
    CHANGE_PRODUCTS_FUNCTION,
    CHANGE_PRODUCTS_ORDERBY,
    CHANGE_PRODUCTS_PAGE,
    CHANGE_PRODUCTS_SIZE,
    CHANGE_PRODUCTS_TYPE,
    GET_ALL_PRODUCTS,
    GET_ONE_PRODUCT,
    SEARCH_PRODUCTS,
    SHOW_PRODUCT_OPTION,
    ADD_TO_CART_POPUP_CONTROL,
    // SEARCH_PRODUCTS_KEYWORD,
} from '../types'

const initialState = {
    products: null,
    product: null,
    similerProducts: null,
    alsoBought: null,
    categoryInfo: null,
    styleInfo: null,
    productTypes: null,
    productFunctions: null,
    picturePointDesktop: null,
    picturePointMobile: null,
    searchColor: '',
    searchSize: '',
    searchFunc: '',
    searchType: '',
    searchOrderBy: '',
    showProductOption: false,
    showAddToCartPopUp: false,
    successAdd: false,
    addItem: '',
    optionProduct: null,
    pages: null,
    page: null,
    count: 0,
    topPage: 1,
}

const productsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: payload.products,
                categoryInfo: payload.category,
                styleInfo: payload.style,
                productTypes: payload.productTypes,
                productFunctions: payload.productFunctions,
                picturePointDesktop: payload.picturePointDesktop,
                picturePointMobile: payload.picturePointMobile,
                searchColor: payload.searchColor,
                searchSize: payload.searchSize,
                searchFunc: payload.searchFunc,
                searchType: payload.searchType,
                searchOrderBy: payload.searchOrderBy,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                topPage: payload.topPage,
            }
        case GET_ONE_PRODUCT:
            return {
                ...state,
                product: payload.product,
                similerProducts: payload.similerProducts,
                alsoBought: payload.alsoBought,
            }
        case SEARCH_PRODUCTS:
            return {
                ...state,
                searchColor: payload.searchColor,
                searchSize: payload.searchSize,
                searchFunc: payload.searchFunc,
                searchType: payload.searchType,
                searchOrderBy: payload.searchOrderBy,
                page: 1,
            }
        case CHANGE_PRODUCTS_PAGE:
            return {
                ...state,
                page: payload,
                topPage: 1,
            }
        case CHANGE_PRODUCTS_TYPE:
            return {
                ...state,
                searchType: payload,
                page: 1,
                topPage: 2,
            }
        case CHANGE_PRODUCTS_COLOR:
            return {
                ...state,
                searchColor: payload,
                page: 1,
                topPage: 2,
            }
        case CHANGE_PRODUCTS_SIZE:
            return {
                ...state,
                searchSize: payload,
                page: 1,
                topPage: 2,
            }
        case CHANGE_PRODUCTS_FUNCTION:
            return {
                ...state,
                searchFunc: payload,
                page: 1,
                topPage: 2,
            }
        case CHANGE_PRODUCTS_ORDERBY:
            return {
                ...state,
                searchOrderBy: payload,
                page: 1,
                topPage: 2,
            }
        case SHOW_PRODUCT_OPTION:
            return {
                ...state,
                showProductOption: payload.show,
                optionProduct: payload.oneProduct,
            }
        case ADD_TO_CART_POPUP_CONTROL:
            return {
                ...state,
                showAddToCartPopUp: payload.show,
                successAdd: payload.successAdd,
                addItem: payload.itemAdd,
            }
        default:
            return state
    }
}

export default productsReducer
