import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_ABOUTUS } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllAboutUs = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/aboutus/aboutus')
        dispatch({
            type: GET_ALL_ABOUTUS,
            payload: {
                header: data.data.aboutUsHeader,
                headOffices: data.data.headOffices,
                teamMembers: data.data.teamMembers,
            },
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
