import { SEARCH_BAR_STATUS } from '../types'

const initialState = {
    searchBar: false,
}

export const searchBarReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case SEARCH_BAR_STATUS:
            return {
                ...state,
                searchBar: payload,
            }

        default:
            return state
    }
}

export default searchBarReducer
