import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { distantCal } from '../../constant/getDistance'
import { changeShippingOption } from '../../store/actions/cartActions'
import Loader from '../Loader'

const CheckoutDeliveryPrice = ({
    carts,
    postCode,
    deliveryLocations,
    shippingOption,
}) => {
    const dispatch = useDispatch()
    const [minDistance, setMinDistance] = useState(0)
    const [deliveryFee, setDeliveryFee] = useState(0)
    const [method, setMethod] = useState(0)
    const [deliveryAvailable, setDeliveryAvailable] = useState(true)

    const subTotal = carts.reduce((a, b) => (a += b.price * b.qty), 0)

    const deliveryAddresses = deliveryLocations.map(
        (location) =>
            location.address1.trim() +
            ', ' +
            location.city.trim() +
            ', ' +
            location.province.trim() +
            ', ' +
            location.country.trim()
    )

    useEffect(() => {
        const distanceFunc = async () => {
            const distance = []
            for (let i = 0; i < deliveryAddresses.length; i++) {
                distance.push(await distantCal(postCode, deliveryAddresses[i]))
            }
            setMinDistance(Math.min(...distance))
        }
        distanceFunc()
    }, [postCode, deliveryAddresses])

    useEffect(() => {
        if (minDistance > 0) {
            setDeliveryAvailable(true)
            if (carts.filter((cart) => cart.shippingMethod === 1).length > 0) {
                if (minDistance <= 50) {
                    if (subTotal >= 4000) {
                        setDeliveryFee(0)
                        setMethod(1)
                    } else {
                        setDeliveryFee(100)
                        setMethod(1)
                    }
                } else if (minDistance > 50 && minDistance <= 100) {
                    setDeliveryFee(200)
                    setMethod(1)
                } else {
                    setDeliveryAvailable(false)
                    setMethod(0)
                }
            } else if (
                carts.filter((cart) => cart.shippingMethod === 2).length > 0
            ) {
                if (subTotal <= 300) {
                    setDeliveryFee(20)
                    setMethod(2)
                } else {
                    setDeliveryFee(0)
                    setMethod(2)
                }
            } else {
                setDeliveryFee(0)
                setMethod(2)
            }
        }
    }, [minDistance, carts, subTotal])

    const handleOption = () => {
        const values = {
            shippingType: 1,
            shippingFee: deliveryFee,
            shippingDistance: minDistance,
            shippingMethod: method,
        }
        dispatch(changeShippingOption(values))
    }

    return minDistance ? (
        deliveryAvailable ? (
            <div className="delivery-box">
                <div className="delivery-radio">
                    <input
                        type="radio"
                        name="deliveryId"
                        className="delivery-radio-input"
                        value={0}
                        defaultChecked={shippingOption?.shippingType === 1}
                        onClick={() => handleOption()}
                    />
                </div>
                <div className="delivery-detail">
                    <div className="delivery-title">
                        Standard Shipping Fee:{' '}
                        {deliveryFee ? `$${deliveryFee}` : 'Free'}
                    </div>
                    <div className="delivery-condition">
                        We will send you an conformation email when the order is
                        ready, it normally take 2-3 days
                    </div>
                </div>
            </div>
        ) : (
            <div>
                Sorry! We can't deliver to this address, please choice Pick Up.
                Thanks
            </div>
        )
    ) : (
        <Loader />
    )
}

export default CheckoutDeliveryPrice
