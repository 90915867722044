import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DrawerToggle from '../sideDrawer/DrawerToggle'
import { Link } from 'react-router-dom'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import SearchIcon from '@material-ui/icons/Search'
import Container from '@material-ui/core/Container'
import PersonIcon from '@material-ui/icons/Person'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { getAllNavItems } from '../../store/actions/homePageActions'
import { useLocation } from 'react-router-dom'
import { useCurrentWidth } from '../screenWidth'
import SearchBar from '../search/SearchBar'
import { setSearchBarStatus } from '../../store/actions/searchBarActions'
import { logout, setClientNavId } from '../../store/actions/clientActions'
import history from '../../history'
import AddToCartPopUp from '../cart/AddToCartPopUp'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '30px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

export const Toolbar = (props) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    let location = useLocation().pathname.replace('/', '')
    const homePageState = useSelector((state) => state.homePage)
    const { navItems } = homePageState

    const searchBarState = useSelector((state) => state.searchBar)
    const { searchBar } = searchBarState

    const cartState = useSelector((state) => state.cart)
    const { carts } = cartState

    const productState = useSelector((state) => state.products)
    const { showAddToCartPopUp } = productState

    const allItems = carts.reduce((a, b) => (a += parseInt(b.qty)), 0)

    useEffect(() => {
        if (!navItems) {
            dispatch(getAllNavItems())
        }
    }, [dispatch, navItems])

    const screenWidth = useCurrentWidth()

    return (
        <header className="toolbar">
            <Container disableGutters={true}>
                <nav className="toolbar__navigation">
                    <div className="toolbar__new-header">
                        <div className="toolbar__toggle-button">
                            <DrawerToggle click={props.drawerClickHandler} />
                        </div>
                        <div className="toolbar__logo">
                            <Link to="/">
                                {screenWidth < 769 ? (
                                    location === '' ? (
                                        <img
                                            src="https://res.cloudinary.com/homespace/image/upload/v1622233552/logo/WHITE_YELLOW_LOGO_ohgati.png"
                                            alt="logo"
                                            height="30px"
                                            style={{ objectFix: 'contain' }}
                                        />
                                    ) : (
                                        <img
                                            src="https://res.cloudinary.com/home-space/image/upload/v1631537972/Logo/HomeSpace_Logo_ceragm.svg"
                                            alt="logo"
                                            height="30px"
                                            style={{ objectFix: 'contain' }}
                                        />
                                    )
                                ) : (
                                    <img
                                        src="https://res.cloudinary.com/home-space/image/upload/v1631537972/Logo/HomeSpace_Logo_ceragm.svg"
                                        alt="logo"
                                        height="50px"
                                        style={{
                                            objectFix: 'contain',
                                            marginBottom: '20px',
                                        }}
                                    />
                                )}
                            </Link>
                        </div>
                    </div>
                    <div className="toolbar_new-link">
                        <div className="toolbar_navigation-lists">
                            <ul>
                                <li></li>
                                {navItems &&
                                    navItems.map((item) => (
                                        <li key={item.id}>
                                            <Link to={item.url}>
                                                <span
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    className={
                                                        item.name.toLowerCase() ===
                                                        location
                                                            ? 'toolbar_nav_item_yellow'
                                                            : 'toolbar_nav_item_normal'
                                                    }
                                                >
                                                    {item.displayName}
                                                </span>
                                            </Link>
                                            {/* {item.name.toLowerCase() ===
                                                location && (
                                                <div className="toolbar_nav_item_selected"></div>
                                            )} */}
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        <div className="toolbar_navigation-items">
                            <div className="toolbar_person_area">
                                <PersonIcon className="toolbar_cart" />{' '}
                                {clientInfo ? (
                                    <>
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3E3D38',
                                            }}
                                            onClick={() => {
                                                dispatch(setClientNavId(0))
                                                history.push('/clientmain')
                                            }}
                                        >
                                            {clientInfo.firstName}
                                        </span>{' '}
                                        |{' '}
                                        <span
                                            style={{
                                                cursor: 'pointer',
                                                color: '#3E3D38',
                                            }}
                                            onClick={() => dispatch(logout())}
                                        >
                                            Logout
                                        </span>
                                    </>
                                ) : (
                                    <>
                                        <Link
                                            to="/login"
                                            style={{ color: '#3E3D38' }}
                                        >
                                            Login
                                        </Link>{' '}
                                        |{' '}
                                        <Link
                                            to="/signin"
                                            style={{ color: '#3E3D38' }}
                                        >
                                            Sign In
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>

                        {clientInfo ? (
                            <div
                                className="toolbar_cart_area"
                                onClick={() => {
                                    dispatch(setClientNavId(3))
                                    history.push('/clientcart')
                                }}
                            >
                                <ShoppingCartIcon className="toolbar_cart" />
                                {allItems > 0 && (
                                    <span className="toolbar_cart_item_count">
                                        {allItems}
                                    </span>
                                )}
                            </div>
                        ) : (
                            <div
                                className="toolbar_cart_area"
                                onClick={() => {
                                    history.push('/cart')
                                }}
                            >
                                <ShoppingCartIcon className="toolbar_cart" />
                                {allItems > 0 && (
                                    <span className="toolbar_cart_item_count">
                                        {allItems}
                                    </span>
                                )}
                            </div>
                        )}
                        <div className="toolbar_navigation-items toolbar_search_box">
                            <SearchIcon
                                className="toolbar_cart"
                                onClick={() =>
                                    dispatch(setSearchBarStatus(!searchBar))
                                }
                            />
                        </div>
                    </div>
                </nav>
                <div className="toolbar_divider_full">
                    <Divider className={classes.divider} />
                </div>
                {searchBar && <SearchBar />}
                {showAddToCartPopUp && <AddToCartPopUp />}
            </Container>
        </header>
    )
}

export default Toolbar
