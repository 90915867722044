import { Container } from '@material-ui/core'
import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import ClientEditForm from './ClientEditForm'

const ClientEdit = () => {
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    let values = null
    if (clientInfo) {
        values = {
            id: clientInfo.id,
            firstName: clientInfo.firstName,
            lastName: clientInfo.lastName,
            email: clientInfo.email,
            address: clientInfo.address,
            city: clientInfo.city,
            province: clientInfo.province,
            postCode: clientInfo.postCode,
            phone: clientInfo.phone,
        }
    }

    return (
        <Layout>
            {clientInfo ? (
                <Container>
                    <ClientEditForm initialValues={values} />
                </Container>
            ) : null}
        </Layout>
    )
}

export default ClientEdit
