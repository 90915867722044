import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllWarranties } from '../store/actions/warrantyActions'

const Warranty = () => {
    const dispatch = useDispatch()
    const warrantyState = useSelector((state) => state.warranty)
    const { style, warranties } = warrantyState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!style || !warranties) {
            dispatch(getAllWarranties())
        }
    }, [dispatch, style, warranties])
    return (
        <>
            {style && (
                <Container disableGutters={true}>
                    <div
                        className="warranty_style_title"
                        style={{
                            background: `url(${style.picLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="warranty_style_slogan">
                            <h1 className="warranty_style_slogan_word">
                                {style.slogan}
                            </h1>
                        </div>
                    </div>
                </Container>
            )}
            {warranties && (
                <Container disableGutters={true}>
                    <div className="warranty_info_box">
                        {warranties.map((warranty) => (
                            <div
                                className="warranty_info_info"
                                key={warranty.id}
                            >
                                <div className="warranty_info_title">
                                    {warranty.title}
                                </div>
                                <div className="warranty_info_detail">
                                    {warranty.detail}
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            )}
        </>
    )
}

export default Warranty
