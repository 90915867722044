import { useDispatch, useSelector } from 'react-redux'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'
import { useCurrentWidth } from '../screenWidth'
import history from '../../history'
import { promoGroup } from '../../common/promoService'

const ShoppingListItem = ({ shoppingList }) => {
    const dispatch = useDispatch()

    const screenWidth = useCurrentWidth()

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    const handleAddToCard = () => {
        const promoGroupId = promoGroup(shoppingList.product)
        const values = {
            id: shoppingList.pId,
            productColorId: shoppingList.id,
            name: shoppingList.product.name,
            skuNo: shoppingList.skuNo,
            stock: shoppingList.stock,
            basePrice: shoppingList.price,
            price: shoppingList.price,
            picLink: shoppingList.productpictures.sort(
                (a, b) => b.mainPage - a.mainPage
            )[0]?.picLink
                ? shoppingList.productpictures.sort(
                      (a, b) => b.mainPage - a.mainPage
                  )[0]?.picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
            productWidth: shoppingList.product.productWidth,
            productHeight: shoppingList.product.productHeight,
            productDepth: shoppingList.product.productDepth,
            color: shoppingList.stylecolor.colorNumber,
            shippingMethod: shoppingList.product.style.category.shippingMethod,
            doorDirection: shoppingList.doorDirection,
            glassOption: '',
            depthOption: '',
            qty: parseInt(shoppingList.qty),
            promoGroupId,
            productCategory: shoppingList.product.style.category.slug,
        }
        dispatch(addToCart(values))
        if (shippingOption?.shippingType === 1) {
            dispatch(shippingStage(1))
            dispatch(changeShippingOption(null))
        }
    }

    return (
        <div className="shopping-list-box">
            <div className="shppping-list-pic">
                <img
                    src={
                        shoppingList.productpictures.sort(
                            (a, b) => b.mainPage - a.mainPage
                        )[0]?.picLink
                            ? shoppingList.productpictures.sort(
                                  (a, b) => b.mainPage - a.mainPage
                              )[0]?.picLink
                            : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                    }
                    width="100px"
                    alt="pic"
                />
            </div>
            <div className="shopping-list-name-box">
                <div className="shopping-list-name-name">
                    {screenWidth < 769
                        ? shoppingList?.product?.name.length > 27
                            ? shoppingList?.product?.name.substr(0, 27) + '...'
                            : shoppingList?.product?.name
                        : shoppingList?.product?.name.length > 50
                        ? shoppingList?.product?.name.substr(0, 50) + '...'
                        : shoppingList?.product?.name}
                </div>
                <div className="shopping-list-name-size">
                    {shoppingList?.product?.productWidth}" W x{' '}
                    {shoppingList?.product?.productHeight}" H x{' '}
                    {shoppingList?.product?.productDepth}" D
                </div>
                <div className="shopping-list-name-skuno">
                    Article Number: {shoppingList?.skuNo}
                </div>
                {shoppingList.doorDirection && (
                    <div className="shopping-list-option-title">
                        Door Hinge Location:{' '}
                        <span className="shopping-list-option-detail">
                            {shoppingList.doorDirection}
                        </span>
                    </div>
                )}
            </div>
            <div className="shopping-list-right">
                <div className="shopping-list-right-up">
                    <div className="shopping-list-qty">
                        <input
                            type="text"
                            className="shopping-list-qty-input"
                            defaultValue={shoppingList.qty}
                            readOnly
                        />
                    </div>
                    <div className="shopping-list-price">
                        ${(shoppingList?.price * shoppingList.qty).toFixed(2)}
                    </div>
                </div>
                <div className="shopping-list-right-bottom">
                    <div className="shopping-list-button">
                        {shoppingList?.stock <= shoppingList.qty ? (
                            <button
                                onClick={() =>
                                    history.push(
                                        `product/${shoppingList.product.slug}`
                                    )
                                }
                            >
                                Detail
                            </button>
                        ) : (
                            <button onClick={() => handleAddToCard()}>
                                Add to cart
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShoppingListItem
