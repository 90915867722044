import { Container } from '@material-ui/core'
import { useState, useEffect } from 'react'
import request from '../config/request'
import ShoppingListItem from '../components/shoppingList/ShoppingListItem'
import { addToCartPopUpAction } from '../store/actions/productsActions'
import { useDispatch, useSelector } from 'react-redux'
import {
    addAllToCartAction,
    changeShippingOption,
    shippingStage,
} from '../store/actions/cartActions'
import { promoGroup } from '../common/promoService'

const ShoppingList = (props) => {
    // console.log(props.location.search)
    const dispatch = useDispatch()
    const [result, setResult] = useState([])
    const [list, setList] = useState([])
    const url = props.location.search.split('&')
    const cartState = useSelector((state) => state.cart)
    const { shippingOption, carts } = cartState

    useEffect(() => {
        const aa = []
        const bb = []
        const getUrlParams = (name, str, index) => {
            const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
            let r = ''
            if (index === 0) r = str.substr(1).match(reg)
            else r = str.match(reg)
            if (r !== null) return decodeURIComponent(r[2])
            return null
        }

        url.forEach((a, index) => {
            const p = getUrlParams('p', a, index)

            if (p && p.includes(':')) {
                const skuNew = p.split(':')[0]
                const quantity = p.split(':')[1]
                const listAll = skuNew.split('-')
                let value = ''
                if (listAll[listAll.length - 1] === 'L') {
                    listAll.pop()
                    const skuNoClear = listAll.join('-')
                    bb.push(skuNoClear)
                    value = {
                        skuNo: skuNoClear,
                        doorDirection: 'Left',
                        qty: quantity,
                    }
                } else if (listAll[listAll.length - 1] === 'R') {
                    listAll.pop()
                    const skuNoClear = listAll.join('-')
                    bb.push(skuNoClear)
                    value = {
                        skuNo: skuNoClear,
                        doorDirection: 'Right',
                        qty: quantity,
                    }
                } else {
                    bb.push(skuNew)
                    value = {
                        skuNo: skuNew,
                        doorDirection: null,
                        qty: quantity,
                    }
                }
                aa.push(value)
            }
        })
        const getShoppingList = async () => {
            try {
                if (bb.length > 0) {
                    const value = { list: bb }
                    const { data } = await request.post(
                        '/client/products/shoppinglist',
                        value
                    )
                    const resData = data.data
                    const displayShoppingLists = []
                    if (aa.length > 0) {
                        for (let i = 0; i < aa.length; i++) {
                            const aSkuNo = aa[i].skuNo
                            const shoppingItem = aa[i]
                            const filterData = resData.filter(
                                (list) => list.skuNo === aSkuNo
                            )

                            if (filterData.length > 0) {
                                let pId = ''
                                if (shoppingItem.doorDirection === 'Left') {
                                    pId = `${filterData[0].id}-1-0-0`
                                } else if (
                                    shoppingItem.doorDirection === 'Right'
                                ) {
                                    pId = `${filterData[0].id}-2-0-0`
                                } else {
                                    pId = `${filterData[0].id}-0-0-0`
                                }
                                const filterValue = {
                                    ...filterData[0],
                                    qty: shoppingItem.qty,
                                    doorDirection: shoppingItem.doorDirection,
                                    pId,
                                }
                                // console.log('filete value===>>', filterValue)
                                displayShoppingLists.push(filterValue)
                            }
                        }
                    }
                    setList(displayShoppingLists)
                }
            } catch (e) {
                console.log(e)
            }
        }

        setResult(aa)
        getShoppingList()
    }, [])

    const totalItem = result.reduce((a, b) => (a += parseInt(b.qty)), 0)
    const availableItem = list.reduce((a, b) => (a += parseInt(b.qty)), 0)

    const addAllToCart = () => {
        if (list.length > 0) {
            list.forEach((shoppingList) => {
                if (
                    parseInt(shoppingList.stock) >
                    parseInt(
                        carts
                            .filter((x) => x.productColorId === shoppingList.id)
                            .reduce((a, b) => (a += b.qty), 0) +
                            parseInt(shoppingList.qty)
                    )
                ) {
                    const promoGroupId = promoGroup(shoppingList.product)
                    const values = {
                        id: shoppingList.pId,
                        productColorId: shoppingList.id,
                        name: shoppingList.product.name,
                        skuNo: shoppingList.skuNo,
                        stock: shoppingList.stock,
                        basePrice: shoppingList.price,
                        price: shoppingList.price,
                        picLink: shoppingList.productpictures.sort(
                            (a, b) => b.mainPage - a.mainPage
                        )[0]?.picLink
                            ? shoppingList.productpictures.sort(
                                  (a, b) => b.mainPage - a.mainPage
                              )[0]?.picLink
                            : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
                        productWidth: shoppingList.product.productWidth,
                        productHeight: shoppingList.product.productHeight,
                        productDepth: shoppingList.product.productDepth,
                        color: shoppingList.stylecolor.colorNumber,
                        shippingMethod:
                            shoppingList.product.style.category.shippingMethod,
                        doorDirection: shoppingList.doorDirection,
                        glassOption: '',
                        depthOption: '',
                        qty: parseInt(shoppingList.qty),
                        promoGroupId,
                        productCategory:
                            shoppingList.product.style.category.slug,
                    }

                    dispatch(addAllToCartAction(values))
                }
            })
            if (shippingOption?.shippingType === 1) {
                dispatch(shippingStage(1))
                dispatch(changeShippingOption(null))
            }

            dispatch(addToCartPopUpAction(true, true, 'all available items'))
        }
    }
    return (
        <Container disableGutters={true}>
            <div className="mycart-title">Shopping List</div>
            <div className="shopping-list-add-all">
                {totalItem} Items available {availableItem}
                <button
                    onClick={() => addAllToCart()}
                    className="shopping-list-button-cart"
                >
                    Add All to Cart
                </button>
            </div>
            <div className="shopping-lists">
                {list.length > 0 &&
                    list.map((shoppingList) => (
                        <ShoppingListItem
                            shoppingList={shoppingList}
                            key={shoppingList.pId}
                        />
                    ))}
            </div>
        </Container>
    )
}

export default ShoppingList
