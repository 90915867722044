import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getHomePage } from '../store/actions/homePageActions'
import PicturePointDisplay from '../components/picturePoint/PicturePointDisplay'
// import HelpingTools from '../components/helpingTools/HelpingTools'
// import { helpingList } from '../constant/toolsList'
import history from '../history'
import Carousel from '../components/main/Carousel'
import { homePageItems } from '../constant/default'
import DesignCenter from '../components/DesignCenter'
import BestPriceGuarantee from '../components/BestPriceGuarantee'
import YouTube from 'react-youtube'
import { productCategories } from '../config/productCategories'
import { SEARCH_PRODUCTS } from '../store/types'
import Meta from '../components/Meta'

const opts = {
    width: '100%',
}

const Main = () => {
    const dispatch = useDispatch()
    const homePageState = useSelector((state) => state.homePage)
    const { homeSmallPics, homePicturePoints, homePage } = homePageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!homeSmallPics || !homePicturePoints || !homePage) {
            dispatch(getHomePage())
        }
    }, [dispatch, homeSmallPics, homePicturePoints, homePage])

    // console.log('homepage from main ===>>', homePage)
    return (
        <>
            <Meta />
            {homePage && <Carousel homePage={homePage} />}
            <div className="home_big_pic_back"></div>
            {/* <div className="home_page_ad">
                <Container disableGutters={true}>
                    <div className="home_page_ad_text">
                        <div>
                            Best Price Guarantee, beat their prices by 8% on all
                            premium products!{' '}
                            <span
                                style={{
                                    cursor: 'pointer',
                                    textDecoration: 'underline',
                                    marginLeft: '5px',
                                }}
                                onClick={() => history.push('/bestprice')}
                            >
                                Learn More
                            </span>
                        </div>
                    </div>
                </Container>
            </div> */}

            <div className="home_product_category_bg">
                <Container disableGutters={true}>
                    <div className="home_item_box_title">
                        Product Categories
                    </div>
                    <div className="home_item_box_grid">
                        {productCategories.map((category) => (
                            <div
                                key={category.id}
                                className="home_item_box_pic_bg"
                                style={{
                                    background: `url(${category.picLink})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                }}
                            >
                                <div className="home_item_box_pic_text_box">
                                    <div className="home_item_box_pic_header">
                                        {category.styleColor}
                                    </div>
                                    {/* <Link to={category.pageLink}> */}
                                    <div
                                        onClick={() => {
                                            dispatch({
                                                type: SEARCH_PRODUCTS,
                                                payload: {
                                                    searchColor:
                                                        category.colorId
                                                            ? category.colorId
                                                            : '',
                                                    searchSize: '',
                                                    searchFunc: '',
                                                    searchType: '',
                                                    searchOrderBy: '',
                                                },
                                            })

                                            history.push(category.pageLink)
                                        }}
                                        className="home_item_box_pic_more"
                                    >
                                        Shop now
                                    </div>
                                    {/* </Link> */}
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            </div>
            <Container disableGutters={true}>
                <BestPriceGuarantee />
            </Container>
            <Container disableGutters={true}>
                <h3 className="home_items_title">Why Choose Hspace</h3>
                <div className="home_items_container">
                    {homePageItems.map((item) => (
                        <div key={item.id} className="home_items_box">
                            <img src={item.picLink} alt="home page item" />
                            <h5>{item.title}</h5>
                            <p>{item.subTitle}</p>
                        </div>
                    ))}
                </div>
            </Container>
            <Container disableGutters={true}>
                <div className="home_youtube_container">
                    <div className="home_buying_right_box">
                        <h3>Buying All Kitchen Needs A Click!</h3>
                        <p style={{ lineHeight: '30px' }}>
                            Hspace Kitchen and Bath is your one-stop-shop for
                            premium quality RTA kitchen cabinets, home décor,
                            and vanities. With over 40 years of quality service
                            in cabinetry, we are Canada’s leading retailer known
                            for its reliability, stylistic excellence, and
                            unparalleled service. We know that cabinets set the
                            tone of the kitchen, and Hspace Kitchen and Bath is
                            dedicated to helping you put the heart and soul back
                            into your kitchen.
                        </p>
                    </div>
                    <div className="home_youtube_video">
                        <YouTube videoId="V7PGkPbfXQ0" opts={opts} />
                    </div>
                </div>
            </Container>
            {/* <Container disableGutters={true}>
                <div className="home_buying_container">
                    <div className="home_buying_left">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1650685245/Home_page/Buying_All_Kitchen_Needs_A_Click_ydol3w.png"
                            alt="buying_logo"
                            className="home_buying_logo"
                        />
                    </div>
                    <div className="home_buying_right"></div>
                </div>
            </Container> */}

            <DesignCenter />

            <Container disableGutters={true} style={{ marginTop: '60px' }}>
                <div className="home_pic_point_title_area">
                    <div>
                        <h3>Kitchen Inspiration</h3>
                    </div>
                    <div className="home_pic_point_yellow_line"></div>
                </div>
                <div className="home_pic_point_box">
                    {homePicturePoints &&
                        homePicturePoints.map((picturePoint) => (
                            <div
                                className="home_pic_point_item"
                                key={picturePoint.id}
                            >
                                <PicturePointDisplay
                                    picturePoint={picturePoint}
                                    pointColor=""
                                />
                            </div>
                        ))}
                </div>
            </Container>
            {/* <HelpingTools helpingList={helpingList} /> */}
        </>
    )
}

export default Main
