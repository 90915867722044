import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { ComponentToPrint } from '../components/cartPrint/ComponentToPrint'

const CartPrint = () => {
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <div>
            <div className="printcart-button-box">
                <button className="printcart-button" onClick={handlePrint}>
                    Print
                </button>
            </div>
            <ComponentToPrint ref={componentRef} />
        </div>
    )
}

export default CartPrint
