import React from 'react'
import { Helmet } from 'react-helmet'

const Meta = ({ title, description }) => {
    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
        </Helmet>
    )
}

Meta.defaultProps = {
    title: 'Kitchen And Bathroom Cabinets Mississauga, Kitchen Cabinets Makers',
    description:
        'Choose from an extensive array of diverse styles of kitchen cabinets, bathroom cabinets, upper kitchen cabinets, bath cabinets and more. Contact us today 1-833-770-7077.',
}

export default Meta
