import { useDispatch } from 'react-redux'
import Container from '@material-ui/core/Container'
import CheckCircle from '@material-ui/icons/CheckCircle'
import { resendConfirmEmail } from '../store/actions/clientActions'

function SigninSuccess({ match }) {
    const dispatch = useDispatch()
    const id = match.params.id
    return (
        <Container>
            <div
                style={{
                    width: '100%',
                    height: '50vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CheckCircle
                    style={{
                        fontSize: 100,
                        color: 'green',
                        marginRight: '10px',
                    }}
                />{' '}
                <h3 style={{ marginTop: '30px', marginBottom: '30px' }}>
                    Please check your email to active your account! Thanks
                </h3>
                <p>
                    If your didn't receive the email, you can click below button
                    resend email
                </p>
                <button
                    className="resend-email-button"
                    onClick={() => dispatch(resendConfirmEmail(id))}
                >
                    Resend Email
                </button>
            </div>
        </Container>
    )
}

export default SigninSuccess
