import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { Container, Divider } from '@material-ui/core'
import {
    shippingBilling,
    shippingInformation,
    shippingStage,
} from '../../store/actions/cartActions'
import {
    renderInputNoLabel,
    renderInputSmall,
    renderSelect,
    renderSelectSmall,
} from '../ReduxFormInputs'
import { useCurrentWidth } from '../screenWidth'
import { getErrors } from '../../store/actions/errorActions'

const provinceList = [
    'ON',
    'QC',
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'PE',
    'SK',
    'YT',
]

const CheckoutInformationForm = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit, formType } = props
    const cartState = useSelector((state) => state.cart)
    const { postCode } = cartState
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const submitForm = (formValues) => {
        if (formType === 'info') {
            if (
                formValues['postCode'].replace(' ', '').toLowerCase() !==
                postCode.replace(' ', '').toLowerCase()
            ) {
                dispatch(getErrors('Post code not match shipping option'))
                return false
            }
            dispatch(shippingInformation(formValues))
            dispatch(shippingStage(3))
        } else {
            dispatch(shippingBilling(formValues))
            dispatch(shippingStage(4))
        }
    }
    const screenWidth = useCurrentWidth()
    return (
        <Container disableGutters={true}>
            <div className="divider_mobile">
                <Divider />
            </div>

            <div className="signin-box">
                <form
                    onSubmit={handleSubmit(submitForm)}
                    noValidate
                    autoComplete="off"
                >
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="firstName"
                                type="text"
                                label="First Name"
                                max="50"
                                component={
                                    screenWidth < 769
                                        ? renderInputNoLabel
                                        : renderInputSmall
                                }
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="lastName"
                                type="text"
                                label="Last Name"
                                max="50"
                                component={
                                    screenWidth < 769
                                        ? renderInputNoLabel
                                        : renderInputSmall
                                }
                            />
                        </div>
                    </div>
                    {formType !== 'billing' && (
                        <div className="input-item-full">
                            <Field
                                name="email"
                                type="text"
                                label="Email"
                                max="100"
                                component={
                                    screenWidth < 769
                                        ? renderInputNoLabel
                                        : renderInputSmall
                                }
                            />
                        </div>
                    )}
                    <div className="input-item-full">
                        <Field
                            name="address"
                            type="text"
                            label="Address"
                            max="200"
                            component={
                                screenWidth < 769
                                    ? renderInputNoLabel
                                    : renderInputSmall
                            }
                        />
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="city"
                                type="text"
                                label="City"
                                max="50"
                                component={
                                    screenWidth < 769
                                        ? renderInputNoLabel
                                        : renderInputSmall
                                }
                            />
                        </div>
                        <div
                            className={
                                screenWidth < 769
                                    ? 'input-item-half-select'
                                    : 'input-item-half'
                            }
                        >
                            <Field
                                name="province"
                                label="Province"
                                component={
                                    screenWidth < 769
                                        ? renderSelect
                                        : renderSelectSmall
                                }
                            >
                                <option value="">
                                    {screenWidth < 769 ? 'Province' : ''}
                                </option>
                                {provinceList.map((province) => (
                                    <option value={province} key={province}>
                                        {province}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="postCode"
                            type="text"
                            label="Post Code"
                            max="10"
                            component={
                                screenWidth < 769
                                    ? renderInputNoLabel
                                    : renderInputSmall
                            }
                        />
                    </div>
                    {formType !== 'billing' && (
                        <div className="input-item-full">
                            <Field
                                name="phone"
                                type="text"
                                label="Telephone"
                                max="30"
                                component={
                                    screenWidth < 769
                                        ? renderInputNoLabel
                                        : renderInputSmall
                                }
                            />
                        </div>
                    )}
                    <div className="checkout-information-button-box">
                        <button
                            type="submit"
                            className="checkout-information-button"
                        >
                            Continue
                        </button>
                    </div>
                </form>
            </div>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'firstName',
        'lastName',
        'address',
        'city',
        'province',
        'postCode',
        'phone',
        'email',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.postCode) {
        if (!/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(values.postCode)) {
            errors.postCode = 'Invalid Post Code'
        }
    }

    if (values.phone) {
        if (
            !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                values.phone
            )
        ) {
            errors.phone = 'Invalid Phone number'
        }
    }

    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    return errors
}

export default reduxForm({
    form: 'checkoutInformationForm',
    validate,
})(CheckoutInformationForm)
