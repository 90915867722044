import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { renderInput } from '../components/ReduxFormInputs'
import { Container, Divider } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'
import { forgetPassword } from '../store/actions/clientActions'
import { Link } from 'react-router-dom'

const ForgetPassword = (props) => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const { handleSubmit } = props
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        formValues['clientEmail'] = formValues.email
        dispatch(forgetPassword(formValues))
    }

    return (
        <>
            <div className="divider_mobile">
                <Divider />
            </div>
            <Container disableGutters={true} maxWidth="xs">
                <div className="login-box">
                    <div className="login-title">RESET PASSWORD</div>
                    <form
                        onSubmit={handleSubmit(submitForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Field
                            name="email"
                            type="text"
                            label="Your email"
                            max="100"
                            component={renderInput}
                        ></Field>
                        <div className="google-robert">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                            />
                        </div>
                        <div className="login-button-box">
                            <button type="submit" className="login-button">
                                Send
                            </button>
                        </div>
                        <div className="login-forgetpassword">
                            <Link to="/login">Back to Login</Link>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    return errors
}

export default reduxForm({
    form: 'forgetPasswordForm',
    validate,
})(ForgetPassword)
