import { Container } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { useCurrentWidth } from '../components/screenWidth'
import countertopList from '../config/HSCountertop.json'
import history from '../history'

const INITIAL_QTY = 18

const CountertopPage = () => {
    const [list, setList] = useState([])
    const screenWidth = useCurrentWidth()
    useEffect(() => {
        window.scrollTo(0, 0)
        setList(countertopList.slice(0, INITIAL_QTY))
    }, [])

    const loadMore = () => {
        setList(countertopList)
    }

    return (
        <>
            <div className="countertop-header">
                <div className="countertop-bgpic">
                    <div className="countertop_text_box">
                        <div className="countertop_text_area">
                            <h3>Countertop</h3>
                            <p>
                                Quartz Countertop surfaces resist stains such as
                                wine, coffee, juice and food colouring.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Container>
                <div className="countertop-details">
                    <div className="countertop-details-pic">
                        <div className="countertop-details-pic-bg"></div>
                    </div>
                    <div className="countertop-details-info">
                        <div className="countertop-details-info-header">
                            Countertop
                        </div>
                        <div className="countertop-details-info-body">
                            Quartz Countertop surfaces resist stains such as
                            wine, coffee, juice and food colouring.
                        </div>
                        <div className="countertop-details-info-body">
                            Quartz Countertop surfaces is heat resistance, and
                            the use of heat pads is recommended. Avoiding direct
                            contact with extreme heat source such as hot pots/
                            pans from stove tops or oven will contribute to the
                            longevity of the countertop.
                        </div>
                        <div className="countertop-details-info-body">
                            Quartz Countertop are made from quartz crystals - an
                            extremely hard minerals that is ideal to resisting
                            scratches.
                        </div>
                        <div className="countertop-details-info-body">
                            Made of mainly natural quartzite, minerals and
                            resin. It is a less porous material. Therefore, it
                            is easy to clean and maintain. Unlike natural
                            stones, Quartz Countertop does not require sealing.
                        </div>
                    </div>
                </div>
                <div className="countertop-products-header">
                    Only avaliable in our showroom not for online purchase
                </div>
                <div className="countertop-products">
                    {list.map((product) => (
                        <div
                            className="countertop-product"
                            style={{
                                background: `url(${product.picLink})`,
                                backgroundPosition: 'center',
                                backgroundSize: 'contain',
                            }}
                            key={product.id}
                        >
                            <div className="countertop-product-text-box">
                                <div className="countertop-product-text-header">
                                    {product.skuNo}
                                </div>
                                <div className="countertop-product-text-body">
                                    {product.name}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {list.length !== countertopList.length ? (
                    <div className="countertop-product-more">
                        <div className="countertop-product-more-qty">
                            ({list.length} of {countertopList.length})
                        </div>
                        <div
                            className="countertop-product-more-link"
                            onClick={loadMore}
                        >
                            Load more
                        </div>
                    </div>
                ) : (
                    <div style={{ height: '50px' }}></div>
                )}
                <div style={{ height: '30px' }}></div>
                <div className="boxing_container">
                    <div
                        className="boxing_bgpic"
                        style={{
                            backgroundImage: `url(${
                                screenWidth < 769
                                    ? 'https://res.cloudinary.com/home-space/image/upload/c_scale,w_900/v1668031705/Products/Hspace%20Special%20Deals/2022_Boxing_Month_Promotion/%E7%BB%84_1074_laccmo.webp'
                                    : 'https://res.cloudinary.com/home-space/image/upload/v1668031694/Products/Hspace%20Special%20Deals/2022_Boxing_Month_Promotion/%E6%B4%BB%E5%8A%A8%E9%A1%B5_pg2g6r.webp'
                            }
                        )`,
                        }}
                    >
                        <div className="boxing_text_box">
                            <div className="boxing_text_area">
                                <h3>Boxing Month Promotion</h3>
                                <p>
                                    Save Up to 40% on All In-Stock Custom
                                    Cabinet
                                </p>
                                <div
                                    style={{
                                        textAlign: 'center',
                                        marginTop: '30px',
                                    }}
                                >
                                    <button
                                        className="boxing_text_button"
                                        onClick={() =>
                                            history.push('/promo/boxing')
                                        }
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default CountertopPage
