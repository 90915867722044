import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import StyleHeader from '../components/styleHeader/StyleHeader'
import history from '../history'
import PicturePointDisplay from '../components/picturePoint/PicturePointDisplay'
import { getAllVanitiesPages } from '../store/actions/vanitiesPageActions'
import { PICTURE_POINT_YELLOW } from '../constant/default'
import { clearProducts } from '../store/actions/productsActions'
import Meta from '../components/Meta'

const Vanities = () => {
    const dispatch = useDispatch()
    const vanitiesPageState = useSelector((state) => state.vanitiesPage)
    const { styles, picturePoint } = vanitiesPageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!styles || !picturePoint) {
            dispatch(getAllVanitiesPages())
        }
    }, [dispatch, styles, picturePoint])

    return (
        <>
            <Meta
                title={
                    'Kitchen Cabinet Makers Mississauga, Custom Kitchen Cabinet Makers'
                }
                description={
                    'Our high-end kitchen series include, upper &amp; lower kitchen cabinets, custom kitchen cabinets, base kitchen cabinet, etc. that will add value to your home decor.'
                }
            />
            {styles && <StyleHeader styles={styles} />}
            <Container disableGutters={true}>
                <Grid container spacing={3}>
                    {styles &&
                        styles.map((style) => (
                            <Grid item xs={12} md={6} key={style.id}>
                                <div
                                    className="vanities_small_pic"
                                    style={{
                                        background: `url(${style.picLink})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                    }}
                                ></div>
                                <div className="vanities_small_bottom">
                                    <div
                                        className="vanities_small_title_box"
                                        style={{
                                            backgroundColor:
                                                style.backgroundColor,
                                        }}
                                    >
                                        <div style={{ width: '70%' }}>
                                            <h2>Shop {style.name}</h2>
                                            <p>{style.slogan}</p>
                                        </div>
                                        <div>
                                            <img
                                                src="https://res.cloudinary.com/homespace/image/upload/v1655522377/Icons/Arrow_1_i5vuhl.png"
                                                alt="arrow_logo"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => {
                                                    dispatch(clearProducts())
                                                    style.comingSoon
                                                        ? history.push(
                                                              '/comingsoon'
                                                          )
                                                        : history.push(
                                                              `/products/${style.category.slug}/${style.slug}`
                                                          )
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </Container>
            <Container disableGutters={true} style={{ marginTop: '60px' }}>
                <div className="section_title_area">
                    <div className="section_title_name">
                        <h3>Explore our high-end kitchen series</h3>
                    </div>
                    <div className="section_yellow_line"></div>
                </div>
                {picturePoint && (
                    <PicturePointDisplay
                        picturePoint={picturePoint}
                        pointColor={PICTURE_POINT_YELLOW}
                    />
                )}
            </Container>
        </>
    )
}

export default Vanities
