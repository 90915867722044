import Layout from '../components/Layout'
import PaymentSuccess from '../components/payment/PaymentSuccess'

const ClientPaymentSuccess = ({ match }) => {
    const orderId = match.params.orderId
    const typeId = match.params.typeId
    return (
        <Layout>
            <PaymentSuccess orderId={orderId} typeId={typeId} />
        </Layout>
    )
}

export default ClientPaymentSuccess
