import { GET_ALL_ACCESSORIES_PAGE } from '../types'

const initialState = {
    styles: null,
    picturePoint: null,
}

export const accessoriesPageReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ACCESSORIES_PAGE:
            return {
                ...state,
                styles: payload.styles,
                picturePoint: payload.picturePoint,
            }

        default:
            return state
    }
}

export default accessoriesPageReducer
