import {
    GET_ALL_HOME_PIC_POINTS,
    GET_ALL_HOME_SMALL_PICS,
    GET_ALL_NAV_CATEGORIES,
    GET_HOMEPAGE,
} from '../types'

const initialState = {
    navItems: null,
    homeSmallPics: null,
    homePicturePoints: null,
    homePage: null,
}

export const homePageReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_NAV_CATEGORIES:
            return {
                ...state,
                navItems: payload,
            }
        case GET_ALL_HOME_PIC_POINTS:
            return {
                ...state,
                homePicturePoints: payload,
            }
        case GET_ALL_HOME_SMALL_PICS:
            return {
                ...state,
                homeSmallPics: payload,
            }
        case GET_HOMEPAGE:
            return {
                ...state,
                homeSmallPics: payload.smallPics,
                homePicturePoints: payload.picturePoints,
                homePage: payload.homePage,
            }
        default:
            return state
    }
}

export default homePageReducer
