export const cartType = (card) => {
    if (card === 'VI') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006019/cardLogo/visa_crfadw_u1blg4.svg"
                    alt="visa"
                    width="30px"
                />
            </span>
        )
    } else if (card === 'MC') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006022/cardLogo/mastercard_xh3vxl_oqkca9.svg"
                    alt="mastercard"
                    width="30px"
                />
            </span>
        )
    } else if (card === 'AM') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672006026/cardLogo/am_amex_06_wfskku_xkwtvm.png"
                    alt="axcard"
                    width="30px"
                />
            </span>
        )
    } else if (card === 'NN') {
        return (
            <span>
                <img
                    src="https://res.cloudinary.com/home-space/image/upload/v1672005983/cardLogo/181206_zfpotk.svg"
                    alt="discover"
                    width="30px"
                />
            </span>
        )
    }
}
