import { useState, useEffect, useMemo } from 'react'
import { FormControl } from '@material-ui/core'
import InputBase from '@material-ui/core/InputBase'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
    changeCartQty,
    changeShippingOption,
    removeFromCart,
    shippingStage,
} from '../../store/actions/cartActions'
import { useCurrentWidth } from '../screenWidth'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { promoCodeList } from '../../constant/promoCode'

const BootstrapInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        width: '30px',
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '5px 10px 5px 10px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.

        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase)
const useStyles = makeStyles((theme) => ({
    margin: {
        margin: theme.spacing(1),
    },
    menuPaper: {
        maxHeight: 200,
    },
}))

const CartList = ({ cart }) => {
    const classes = useStyles()
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts, shippingOption, promoCode, promoRate } = cartState

    const [newStock, setNewStock] = useState(0)

    useEffect(() => {
        if (
            carts.filter((x) => x.productColorId === cart.productColorId)
                .length > 1
        ) {
            const existItemQty = carts
                .filter((x) => x.productColorId === cart.productColorId)
                .reduce((a, b) => (a += b.qty), 0)
            const leftQty = cart.stock - existItemQty + cart.qty
            setNewStock(leftQty)
        } else {
            setNewStock(cart.stock)
        }
    }, [cart, carts])

    const handleChange = (e) => {
        dispatch(
            changeCartQty(
                cart.id,
                e.target.value,
                cart.promoGroupId,
                cart.promoActive
            )
        )
        if (shippingOption?.shippingType === 1) {
            dispatch(changeShippingOption(null))
            dispatch(shippingStage(1))
        }
    }

    const handleRemove = (id, promoId, promoBm) => {
        dispatch(removeFromCart(id, promoId, promoBm))
        if (shippingOption?.shippingType === 1) {
            dispatch(changeShippingOption(null))
            dispatch(shippingStage(1))
        }
    }

    const promoDisplay = useMemo(() => {
        let result = ''
        if (promoCode === promoCodeList.BOGO) {
            result = (
                <>
                    <div>
                        $
                        {(cart.price * (cart.qty - cart.promoActive)).toFixed(
                            2
                        )}
                    </div>
                    <div className="cart-list-promo-delete">
                        ({promoCode} -$
                        {(cart.price * cart.promoActive).toFixed(2)})
                    </div>
                </>
            )
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = (
                <>
                    <div>
                        $
                        {(
                            cart.qty *
                            ((cart.price * (100 - promoRate)) / 100)
                        ).toFixed(2)}
                    </div>
                    <div className="cart-list-promo-delete">
                        ({promoCode} -$
                        {(cart.qty * ((cart.price * promoRate) / 100)).toFixed(
                            2
                        )}
                        )
                    </div>
                </>
            )
        }

        return result
    }, [promoCode, cart, promoRate])

    const screenWidth = useCurrentWidth()
    return (
        <div className="cart-list-box">
            <div className="cart-list-pic">
                <img src={cart.picLink} alt="Product" />
            </div>
            <div className="cart-list-name-box">
                <div className="cart-list-name-name">
                    {screenWidth < 769
                        ? cart.name.length > 27
                            ? cart.name.substr(0, 27) + '...'
                            : cart.name
                        : cart.name.length > 50
                        ? cart.name.substr(0, 50) + '...'
                        : cart.name}
                </div>
                <div className="cart-list-name-size">
                    {' '}
                    {cart.productWidth}" W x {cart.productHeight}" H x{' '}
                    {cart.productDepth}" D
                </div>
                <div className="addToCart-unit-price">
                    Unit Price: ${cart.price}
                </div>
                <div className="cart-list-name-skuno">
                    Article Number: {cart.skuNo}
                </div>
                {cart.doorDirection && (
                    <div className="cart-list-option-title">
                        Door Hinge Location:{' '}
                        <span className="cart-list-option-detail">
                            {cart.doorDirection}
                        </span>
                    </div>
                )}

                {cart.glassOption && (
                    <>
                        <div className="cart-list-option-title">
                            Glass Doors:{' '}
                            <span className="cart-list-option-detail">
                                {cart.glassOption.split('&')[2]}
                            </span>
                        </div>
                    </>
                )}

                {cart.depthOption && (
                    <>
                        <div className="cart-list-option-title">
                            Change Cabinet Depth:{' '}
                            <span className="cart-list-option-detail">
                                {cart.depthOption.split('&')[2]}
                            </span>
                        </div>
                    </>
                )}

                <div className="cart-list-name-remove">
                    <span
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            handleRemove(
                                cart.id,
                                cart.promoGroupId,
                                cart.promoActive
                            )
                        }
                    >
                        Remove
                    </span>
                </div>
            </div>
            <div className="cart-list-right">
                <div className="cart-list-qty">
                    {newStock && (
                        <FormControl className={classes.margin}>
                            <Select
                                id="demo-customized-select-native"
                                value={cart.qty}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                                MenuProps={{
                                    classes: { paper: classes.menuPaper },
                                }}
                            >
                                {Array.from(Array(newStock), (e, i) => (
                                    <MenuItem key={i} value={i + 1}>
                                        {i + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </div>
                <div className="cart-list-price">
                    {parseFloat(cart?.promoActive) > 0 ? (
                        promoDisplay
                    ) : (
                        <div>${(cart.price * cart.qty).toFixed(2)}</div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default CartList
