import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chip from '@material-ui/core/Chip'
import { TextField } from '@material-ui/core'
import {
    checkPromoCode,
    deletePromoCode,
} from '../../store/actions/cartActions'

const PromoCodeInput = () => {
    const dispatch = useDispatch()
    const [promoCodeValue, setPromoCodeValue] = useState('')
    const cartState = useSelector((state) => state.cart)
    const { promoCode } = cartState
    const handleSubmit = (e) => {
        e.preventDefault()
        if (!promoCodeValue) return false
        const values = { promoCode: promoCodeValue }
        dispatch(checkPromoCode(values))
        setPromoCodeValue('')
    }

    const handleDelete = () => {
        dispatch(deletePromoCode())
    }
    return (
        <div className="carts-promo-code">
            <div className="carts-promo-code-left">Promo Code:</div>
            <div className="carts-promo-code-right">
                {promoCode ? (
                    <Chip
                        label={promoCode}
                        onDelete={handleDelete}
                        // color="primary"
                        variant="outlined"
                    />
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="promo-code-box">
                            <TextField
                                placeholder="Promo Code"
                                fullWidth
                                className="search_bar_text"
                                value={promoCodeValue}
                                onChange={(e) =>
                                    setPromoCodeValue(e.target.value)
                                }
                            />
                            <button className="promo-code-button">{'>'}</button>
                        </div>
                    </form>
                )}
            </div>
        </div>
    )
}

export default PromoCodeInput
