import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    ADD_TO_CART_POPUP_CONTROL,
    GET_ALL_PRODUCTS,
    GET_ONE_PRODUCT,
    SHOW_PRODUCT_OPTION,
} from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllProducts =
    (
        searchCategory = '',
        searchStyle = '',
        searchColor = '',
        searchSize = '',
        searchFunc = '',
        searchType = '',
        searchOrderBy = '',
        pageNumber = '',
        searchKeyword = '',
        topPage = ''
    ) =>
    async (dispatch) => {
        try {
            dispatch(controlLoading(true))
            const { data } = await request.get(
                `/client/products/all?category=${searchCategory}&style=${searchStyle}&color=${searchColor}&size=${searchSize}&func=${searchFunc}&type=${searchType}&orderBy=${searchOrderBy}&pageNumber=${pageNumber}&keyword=${searchKeyword}`
            )
            dispatch({
                type: GET_ALL_PRODUCTS,
                payload: {
                    products: data.data.products,
                    category: data.data.category,
                    style: data.data.style,
                    productTypes: data.data.productTypes,
                    productFunctions: data.data.productFunctions,
                    picturePointDesktop: data.data.picturePointDesktop,
                    picturePointMobile: data.data.picturePointMobile,
                    pages: data.pages,
                    page: data.page,
                    count: data.count,
                    searchCategory,
                    searchStyle,
                    searchColor,
                    searchSize,
                    searchFunc,
                    searchType,
                    searchOrderBy,
                    searchKeyword,
                    topPage,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors)
        }
    }

export const clearProducts = () => async (dispatch) => {
    dispatch({
        type: GET_ALL_PRODUCTS,
        payload: {
            products: null,
            category: null,
            style: null,
            productTypes: null,
            productFunctions: null,
            picturePointDesktop: null,
            picturePointMobile: null,
            pages: null,
            page: null,
            count: 0,
            searchColor: '',
            searchSize: '',
            searchFunc: '',
            searchType: '',
            searchOrderBy: '',
            searchKeyword: '',
            topPage: 1,
        },
    })
}

export const getOneProduct = (id) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        const { data } = await request.get(`/client/products/listone/${id}`)
        dispatch({
            type: GET_ONE_PRODUCT,
            payload: {
                product: data.data.product,
                similerProducts: data.data.similerProducts,
                alsoBought: data.data.alsoBought,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const productOption = (status, product) => async (dispatch) => {
    dispatch({
        type: SHOW_PRODUCT_OPTION,
        payload: {
            show: status,
            oneProduct: product,
        },
    })
}

export const addToCartPopUpAction =
    (status, success, item) => async (dispatch) => {
        dispatch({
            type: ADD_TO_CART_POPUP_CONTROL,
            payload: {
                show: status,
                successAdd: success,
                itemAdd: item,
            },
        })
    }
