import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { getAllProducts } from '../store/actions/productsActions'
import ProductList from '../components/products/ProductList'
import { CHANGE_PRODUCTS_PAGE } from '../store/types'
import ReduxPagnation from '../components/ReduxPagnation'
import ProductListHeader from '../components/products/ProductListHeader'
import ProductOption from '../components/products/ProductOption'

const Products = ({ match }) => {
    const dispatch = useDispatch()
    const keyword = match.params.keyword || ''
    const category = match.params.category || ''
    const style = match.params.style || ''

    const productsState = useSelector((state) => state.products)
    const {
        products,
        searchColor,
        searchSize,
        searchFunc,
        searchType,
        searchOrderBy,
        page,
        pages,
        topPage,
        showProductOption,
        optionProduct,
    } = productsState
    const loadingState = useSelector((state) => state.loading)
    const { showLoading } = loadingState

    const pageNumber = page || 1
    const scrollToTop = topPage || 1
    useEffect(() => {
        if (scrollToTop === 1) {
            window.scrollTo(0, 0)
        }
        dispatch(
            getAllProducts(
                category,
                style,
                searchColor,
                searchSize,
                searchFunc,
                searchType,
                searchOrderBy,
                pageNumber,
                keyword,
                scrollToTop
            )
        )
    }, [
        dispatch,
        category,
        style,
        searchColor,
        searchSize,
        searchFunc,
        searchType,
        searchOrderBy,
        pageNumber,
        keyword,
        scrollToTop,
    ])

    const changePage = (page) =>
        dispatch({ type: CHANGE_PRODUCTS_PAGE, payload: page })

    return (
        <>
            <ProductListHeader />

            <Container disableGutters={true}>
                {showProductOption && <ProductOption product={optionProduct} />}
                <div className="line_mobile"></div>
                <Grid container spacing={0}>
                    {products && products.length > 0 ? (
                        products.map((product) => (
                            <Grid item xs={6} md={4} key={product.id}>
                                <ProductList
                                    product={product}
                                    searchColor={searchColor}
                                />
                            </Grid>
                        ))
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                textAlign: 'center',
                                padding: '30px 0',
                                fontSize: '22px',
                                fontWeight: '600',
                            }}
                        >
                            {showLoading ? 'Loading' : 'Not Found!'}
                        </div>
                    )}
                </Grid>
                <ReduxPagnation
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </>
    )
}

export default Products
