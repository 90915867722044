import {
    GET_ONE_USER,
    USER_LOGOIN_SUCCESS,
    USER_LOGOUT,
    USER_UPDATE_INFO,
} from '../types'

const initialState = {
    userInfo: {},
    userToken: '',
    user: '',
}

export const userReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case USER_LOGOIN_SUCCESS:
            return {
                ...state,
                userInfo: payload.userInfo,
                userToken: payload.userToken,
            }
        case USER_UPDATE_INFO:
            return { ...state, userInfo: payload }

        case USER_LOGOUT:
            return {}

        case GET_ONE_USER:
            return {
                ...state,
                user: payload,
            }
        default:
            return state
    }
}
