import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import Carts from '../components/cart/Carts'
import { getAllRecommends } from '../store/actions/recommendActions'
import { Divider } from '@material-ui/core'
import Recommend from '../components/products/Recommend'
import ProductOption from '../components/products/ProductOption'

const MyCart = () => {
    const dispatch = useDispatch()
    const recommendState = useSelector((state) => state.recommend)
    const { recommends } = recommendState

    const productsState = useSelector((state) => state.products)
    const { showProductOption, optionProduct } = productsState

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getAllRecommends())
    }, [dispatch])

    return (
        <div>
            <Container disableGutters={true}>
                <h1 className="mycart-title">My Cart</h1>
                <Carts />
            </Container>
            <Container disableGutters={true}>
                {showProductOption && <ProductOption product={optionProduct} />}
                <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
                <div className="cart_recommend_title">We Recommend</div>
                {recommends && <Recommend recommendLists={recommends} />}
            </Container>
        </div>
    )
}

export default MyCart
