import { useMemo } from 'react'
import { promoCodeList } from '../../constant/promoCode'

const PaymentOrderItem = ({ item, promoCodeName, promoRate }) => {
    const priceDisplay = useMemo(() => {
        let result = (
            <div className="item-price-price">
                ${(item.price * item.quantity).toFixed(2)}
            </div>
        )

        if (promoCodeName === promoCodeList.BOGO) {
            result = (
                <div className="item-price-price">
                    <div>
                        $
                        {(
                            item.price *
                            (item.quantity - item.promoActive)
                        ).toFixed(2)}
                    </div>
                    {item.promoActive > 0 && (
                        <div
                            style={{
                                fontSize: '14px',
                                color: 'grey',
                                fontWeight: 400,
                            }}
                        >
                            - ${(item.price * item.promoActive).toFixed(2)}
                        </div>
                    )}
                </div>
            )
        }

        if (promoCodeName === promoCodeList.BMSM2022) {
            result = (
                <div className="item-price-price">
                    <div>
                        $
                        {(
                            item.quantity *
                            ((item.price * (100 - promoRate)) / 100)
                        ).toFixed(2)}
                    </div>
                    {item.promoActive > 0 && (
                        <div
                            style={{
                                fontSize: '14px',
                                color: 'grey',
                                fontWeight: 400,
                            }}
                        >
                            -$
                            {(
                                item.quantity *
                                ((item.price * promoRate) / 100)
                            ).toFixed(2)}
                        </div>
                    )}
                </div>
            )
        }
        return result
    }, [item, promoCodeName, promoRate])
    return (
        <div className="order-info-item-box">
            <div className="item-pic">
                <img
                    src={item.picLink}
                    height="50px"
                    style={{ objectFit: 'contain' }}
                    alt="pic"
                />
            </div>
            <div className="payment-order-name-area">
                <div className="item-name-left">
                    <div className="item-name-name">{item.name}</div>
                    <div className="item-name-sku">
                        Sku Number: {item.skuNo}
                    </div>
                    <div className="item-name-sku">
                        Unit Price: ${item.price}
                    </div>
                    {item.doorDirection && (
                        <div className="cart-list-option-title">
                            Door Hinge Location:{' '}
                            <span className="cart-list-option-detail">
                                {item.doorDirection}
                            </span>
                        </div>
                    )}

                    {item.glassOption && (
                        <>
                            <div className="cart-list-option-title">
                                Glass Doors:{' '}
                                <span className="cart-list-option-detail">
                                    {item.glassOption.split('&')[2]}
                                </span>
                            </div>
                        </>
                    )}

                    {item.depthOption && (
                        <>
                            <div className="cart-list-option-title">
                                Change Cabinet Depth:{' '}
                                <span className="cart-list-option-detail">
                                    {item.depthOption.split('&')[2]}
                                </span>
                            </div>
                        </>
                    )}
                </div>
                <div className="item-name-right">Qty: {item.quantity}</div>
            </div>
            <div className="payment-order-price-area">{priceDisplay}</div>
        </div>
    )
}

export default PaymentOrderItem
