import axios from 'axios'

export const distantCal = async (from, to) => {
    // try {
    //     const res = await axios.get(
    //         `https://www.mapquestapi.com/directions/v2/route?key=${process.env.REACT_APP_MAPQUEST_KEY}&from=${from}&to=${to}`
    //     )
    //     return await res.data.route.distance
    // } catch (error) {
    //     console.log(error)
    //     return false
    // }
    try {
        const fromGeocode = await addressGeocode(from)
        const toGeocode = await addressGeocode(to)
        if (!fromGeocode || !toGeocode) return false
        const res = await axios.get(
            `https://router.hereapi.com/v8/routes?transportMode=car&origin=${fromGeocode}&destination=${toGeocode}&return=summary&apikey=${process.env.REACT_APP_HERE_API}`
        )
        const distant = res.data.routes[0].sections[0].summary.length / 1000
        return distant
    } catch (error) {
        console.log(error)
        return false
    }
}

const addressGeocode = async (address) => {
    try {
        const res = await axios.get(
            `https://geocode.search.hereapi.com/v1/geocode?q=${address}&apiKey=${process.env.REACT_APP_HERE_API}`
        )
        const { lat, lng } = res.data.items[0].position
        return lat + ',' + lng
    } catch (error) {
        console.log(error)
        return false
    }
}
