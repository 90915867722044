import request from '../../config/request'
import history from '../../history'
import { DISPLAY_ERROR_MESSAGE } from '../types'
import { getErrors } from './errorActions'

import { controlLoading } from './loadingActions'

export const paymentChargeCC = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        const paymentRes = await request.post('payment/validcc', values)
        if (paymentRes?.data?.data?.id) {
            const paymentId = paymentRes.data.data.id
            const receiptValues = {
                fullName: values.paymentName,
            }
            await request.post(
                `payment/sendinvoicereceipt/${paymentId}`,
                receiptValues
            )
            history.push(`/paymentreceipt/${paymentId}`)
        }
        dispatch(controlLoading(false))
    } catch (e) {
        const errors = {
            msg: e?.response?.data?.data,
        }
        dispatch(controlLoading(false))
        dispatch({ type: DISPLAY_ERROR_MESSAGE, payload: errors?.msg })
        dispatch(getErrors(errors?.msg))
    }
}
