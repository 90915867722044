import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllSelfAssembles } from '../store/actions/slefAssembleActions'
import YouTube from 'react-youtube'
import StarIcon from '@material-ui/icons/Star'
import { Divider } from '@material-ui/core'

const SelfAssemble = () => {
    const dispatch = useDispatch()
    const selfAssembleState = useSelector((state) => state.selfAssemble)
    const { selfAssembles } = selfAssembleState

    const [assemble, setAssemble] = useState(null)

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!selfAssembles) {
            dispatch(getAllSelfAssembles())
        }
    }, [dispatch, selfAssembles])
    const opts = {
        width: '100%',
    }
    return (
        <>
            {selfAssembles && (
                <Container disableGutters={true}>
                    <div className="divider_mobile_title">
                        <Divider />
                    </div>
                    <div className="self_title">
                        Manual Catalogue Choose Your Products:
                    </div>
                    <div className="self_top">
                        <div className="self_icons">
                            {selfAssembles.map((selfAssemble1) => {
                                const selectedCss =
                                    selfAssemble1.id === assemble?.id
                                        ? 'self_icon_item_select'
                                        : null
                                return (
                                    <div
                                        key={selfAssemble1.id}
                                        className={`self_icon_item ${selectedCss}`}
                                        onClick={() =>
                                            setAssemble(selfAssemble1)
                                        }
                                    >
                                        <img
                                            src={selfAssemble1.picLink}
                                            alt="pic link"
                                            style={{
                                                border: '1px solid #000',
                                            }}
                                        />

                                        <div className="self_icon_name">
                                            Series {selfAssemble1.seriesNo}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {assemble && assemble.videoLink && (
                        <div className="self_youtube">
                            <div className="self_youtube_title">
                                Self Installation Video
                            </div>
                            <div className="self_youtube_video">
                                <YouTube
                                    videoId={assemble.videoLink}
                                    opts={opts}
                                />
                            </div>
                        </div>
                    )}
                    {assemble && (
                        <>
                            <div className="self_bottom_title">
                                Self Installation Process
                            </div>
                            <div className="self_bottom">
                                {assemble?.selfassembleprocesses?.length > 0 &&
                                    assemble?.selfassembleprocesses
                                        .sort((a, b) => a.step - b.step)
                                        .map((selfAssembleProcess) => (
                                            <div key={selfAssembleProcess.id}>
                                                <div className="divider_mobile">
                                                    <Divider />
                                                </div>
                                                <div className="self_bottom_step">
                                                    Step{' '}
                                                    {selfAssembleProcess.step}:{' '}
                                                    {
                                                        selfAssembleProcess.stepName
                                                    }
                                                </div>
                                                <div className="self_bottom_area">
                                                    <div className="self_bottom_pic">
                                                        <img
                                                            src={
                                                                selfAssembleProcess.picLink
                                                            }
                                                            alt="pic"
                                                            width="100%"
                                                        />
                                                    </div>
                                                    <div className="self_bottom_word">
                                                        <div className="self_bottom_tip">
                                                            <StarIcon
                                                                style={{
                                                                    color: '#ceae16',
                                                                }}
                                                            />{' '}
                                                            Tips:
                                                        </div>
                                                        <p
                                                            style={{
                                                                whiteSpace:
                                                                    'pre-line',
                                                            }}
                                                        >
                                                            {
                                                                selfAssembleProcess.tips
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                            </div>
                        </>
                    )}
                </Container>
            )}
        </>
    )
}

export default SelfAssemble
