import Layout from '../components/Layout'

const ClientMain = () => {
    return (
        <Layout>
            <div>Welcome to HS-Online</div>
        </Layout>
    )
}

export default ClientMain
