import { useSelector } from 'react-redux'
import Layout from '../components/Layout'
import PersonIcon from '@material-ui/icons/Person'
import { Link } from 'react-router-dom'
import moment from 'moment'

const ClientAccount = () => {
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState
    return (
        <Layout>
            {clientInfo && (
                <div className="account-box">
                    <div className="account-edit">
                        <PersonIcon
                            style={{ color: '#EDC400', marginRight: '10px' }}
                        />{' '}
                        <Link to={`/clientedit/`}>Edit</Link>
                    </div>
                    <div className="account-info-box">
                        <div className="account-info-title">Name:</div>
                        <div className="account-info-value">
                            {clientInfo.firstName} {clientInfo.lastName}
                        </div>
                    </div>
                    <div className="account-info-box">
                        <div className="account-info-title">Join in date:</div>
                        <div className="account-info-value">
                            {moment(clientInfo.registerDate).format('lll')}
                        </div>
                    </div>
                    <div className="account-info-box">
                        <div className="account-info-title">Email:</div>
                        <div className="account-info-value">
                            {clientInfo.email}
                        </div>
                    </div>
                    <div className="account-info-box">
                        <div className="account-info-title">Phone:</div>
                        <div className="account-info-value">
                            {clientInfo.phone}
                        </div>
                    </div>
                    <div className="account-info-box">
                        <div className="account-info-title">Address:</div>
                        <div className="account-info-value">
                            {clientInfo.address} <br />
                            {clientInfo.city}, {clientInfo.province}{' '}
                            {clientInfo.postCode}
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    )
}

export default ClientAccount
