import { Divider } from '@material-ui/core'
import { useState, useEffect } from 'react'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'
import { useDispatch, useSelector } from 'react-redux'
import { promoGroup } from '../../common/promoService'

const CabinetLIst = ({ product, shippingMethod, productCategory }) => {
    const dispatch = useDispatch()
    const [selectColor, setSelectColor] = useState('')
    const [selectPic, setSelectPic] = useState('')
    const [selectPrice, setSelectPrice] = useState('')

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    useEffect(() => {
        setSelectPic(product.productcolors[0].productpictures[0].picLink)
        setSelectPrice(product.productcolors[0].price)
    }, [product])

    const handleChanged = (id) => {
        const sColor = product.productcolors.filter(
            (color) => color.stylecolor.id === id
        )[0]
        setSelectPic(sColor.productpictures[0].picLink)
        setSelectPrice(sColor.price)
        setSelectColor(id)
    }
    const handleAddToCard = () => {
        const promoGroupId = promoGroup(product)
        const addProduct = product.productcolors.filter(
            (color) => color.stylecolor.id === selectColor
        )[0]
        const values = {
            id: `${addProduct.id}-0-0-0`,
            productColorId: addProduct.id,
            name: product.name,
            skuNo: addProduct.skuNo,
            stock: addProduct.stock,
            basePrice: addProduct.price,
            price: addProduct.price,
            picLink: addProduct.productpictures.sort(
                (a, b) => b.mainPage - a.mainPage
            )[0]?.picLink
                ? addProduct.productpictures.sort(
                      (a, b) => b.mainPage - a.mainPage
                  )[0]?.picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
            productWidth: product.productWidth,
            productHeight: product.productHeight,
            productDepth: product.productDepth,
            color: addProduct.stylecolor.colorNumber,
            shippingMethod: shippingMethod,
            doorDirection: '',
            glassOption: '',
            depthOption: '',
            qty: 1,
            promoGroupId,
            productCategory,
        }

        dispatch(addToCart(values))
        if (shippingOption?.shippingType === 1) {
            dispatch(changeShippingOption(null))
            dispatch(shippingStage(1))
        }
    }
    return (
        <div className="sample_cabinet_box" key={product.id}>
            <div className="divider_mobile">
                <Divider />
            </div>
            <div className="sample_cabinet_left">
                <div className="sample_cabinet_left_img">
                    <img src={selectPic} alt="sample cabinet" />
                </div>
                <div className="sample_cabinet_left_name">{product.name}</div>
            </div>
            <div className="sample_cabinet_middle">
                <div className="sample_cabinet_middle_title">
                    Choose a stained finish:
                </div>
                <div className="sample_cabinet_middle_finish_box">
                    {product.productcolors.map((color) => (
                        <div
                            key={color.id}
                            className="sample_cabinet_middle_finish_item"
                            style={
                                selectColor === color.stylecolor.id
                                    ? {
                                          background:
                                              color.stylecolor.colorNumber,
                                          boxShadow:
                                              '5px 5px 10px rgba(0, 0, 0, 0.5)',
                                      }
                                    : {
                                          background:
                                              color.stylecolor.colorNumber,
                                      }
                            }
                            onClick={() => {
                                handleChanged(color.stylecolor.id)
                            }}
                        ></div>
                    ))}
                </div>
            </div>
            <div className="sample_cabinet_right">
                <div className="sample_cabinet_right_price">
                    <span style={{ fontWeight: '600' }}>${selectPrice}</span>
                    <span style={{ fontSize: '12px' }}>/each</span>
                </div>
                <div className="sample_cabinet_right_shipping">
                    Free shipping
                </div>
                <div className="sample_cabinet_right_refund">
                    100% Refundable
                </div>
                <div className="sample_cabinet_right_button">
                    <button
                        onClick={() => handleAddToCard()}
                        disabled={!selectColor}
                        style={
                            !selectColor
                                ? { cursor: 'not-allowed' }
                                : { cursor: 'pointer' }
                        }
                    >
                        Order this sample
                    </button>
                </div>
            </div>
        </div>
    )
}

export default CabinetLIst
