import {
    Container,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
} from '@material-ui/core'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { requestPic } from '../constant/default'
import {
    deleteOneRequestDesign,
    getOneRequestDesign,
    selectRequestDesign,
} from '../store/actions/requestDesignActions'
import history from '../history'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import { green } from '@material-ui/core/colors'

const ClientDesignInfo = ({ match }) => {
    const dispatch = useDispatch()
    const id = match.params.id
    const requestDesignState = useSelector((state) => state.requestDesign)
    const { requestDesign } = requestDesignState

    const [pic, setPic] = useState(null)
    const [designList, setDesignList] = useState([])

    useEffect(() => {
        dispatch(getOneRequestDesign(id))
    }, [dispatch, id])

    useEffect(() => {
        if (
            requestDesign?.designersubmits?.filter((submit) => submit.selected)
                ?.length > 0
        ) {
            setPic(
                requestDesign?.designersubmits?.filter(
                    (submit) => submit.selected
                )[0].picLink
            )
        }
        if (
            requestDesign?.designersubmits?.filter((submit) => submit.confirm)
                .length > 0
        ) {
            setDesignList(
                requestDesign?.designersubmits?.filter(
                    (submit) => submit.confirm
                )
            )
        }
    }, [requestDesign])

    const sizeMeasure = requestDesign?.sizeMeasure === 1 ? 'ft' : 'm'

    return (
        <Layout>
            {requestDesign && (
                <Container disableGutters={true}>
                    <div className="design_info_button_box">
                        <button
                            className="info-back-button"
                            onClick={() => history.go(-1)}
                        >
                            Back
                        </button>
                        {requestDesign.status === 1 && (
                            <button
                                className="info-delete-button"
                                onClick={() => {
                                    if (window.confirm('are you sure?')) {
                                        dispatch(
                                            deleteOneRequestDesign(
                                                requestDesign.id
                                            )
                                        )
                                    }
                                }}
                            >
                                Delete
                            </button>
                        )}
                    </div>
                    <div className="design_info_top_container">
                        <div className="design_info_top_left">
                            <img
                                src={
                                    pic
                                        ? pic
                                        : requestPic(requestDesign.buildType)
                                              .buildPic
                                }
                                alt="pic"
                                onError={(event) => {
                                    event.target.src =
                                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                    event.onerror = null
                                }}
                                className="design_info_pic"
                            />
                        </div>
                        <div className="design_info_top_right">
                            {designList && designList.length > 0 && (
                                <>
                                    <div>Design Lists</div>
                                    <hr className="design_info_hr" />
                                    <div style={{ padding: '20px 0' }}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Pic</TableCell>
                                                    <TableCell>
                                                        2020 Link
                                                    </TableCell>

                                                    <TableCell>
                                                        Customer Selected
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {designList.map((submit) => (
                                                    <TableRow
                                                        key={submit.id}
                                                        sx={{
                                                            '&:last-child td, &:last-child th':
                                                                {
                                                                    border: 0,
                                                                },
                                                        }}
                                                    >
                                                        <TableCell>
                                                            <img
                                                                src={
                                                                    submit.picLink
                                                                }
                                                                style={{
                                                                    width: '100px',
                                                                    height: '100px',
                                                                    objectFit:
                                                                        'contain',
                                                                }}
                                                                alt="piclink"
                                                                onError={(
                                                                    event
                                                                ) => {
                                                                    event.target.src =
                                                                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                                                    event.onerror =
                                                                        null
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <a
                                                                href={
                                                                    submit.link
                                                                }
                                                                target="_black"
                                                            >
                                                                Link
                                                            </a>
                                                        </TableCell>

                                                        <TableCell>
                                                            {submit.selected ? (
                                                                <CheckCircleOutlineIcon
                                                                    style={{
                                                                        color: green[500],
                                                                    }}
                                                                />
                                                            ) : (
                                                                <button
                                                                    className="design_info_select_button"
                                                                    onClick={() =>
                                                                        dispatch(
                                                                            selectRequestDesign(
                                                                                submit.id,
                                                                                id
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Select
                                                                </button>
                                                            )}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </div>
                                </>
                            )}

                            <h4>Room Details</h4>
                            <hr className="design_info_hr" />
                            <div className="design_info_room_details_container">
                                <div className="design_info_room_details_title">
                                    Width:
                                </div>
                                <div>
                                    {requestDesign.width} {sizeMeasure}
                                </div>
                            </div>
                            <div className="design_info_room_details_container">
                                <div className="design_info_room_details_title">
                                    Length:
                                </div>
                                <div>
                                    {requestDesign.length} {sizeMeasure}
                                </div>
                            </div>
                            <div className="design_info_room_details_container">
                                <div className="design_info_room_details_title">
                                    Height:
                                </div>
                                <div>
                                    {requestDesign.height} {sizeMeasure}
                                </div>
                            </div>
                            <div className="design_info_pic_container">
                                <div className="design_info_pic_item">
                                    <img
                                        src="https://res.cloudinary.com/home-space/image/upload/v1640031570/Request_Design/Screen_Shot_2021-12-20_at_3.19.25_PM_juufqf.png"
                                        className="request-design-kitchen-size-pic"
                                        alt="size pic"
                                    />
                                </div>
                                <div className="design_info_pic_item">
                                    <p className="design_info_pic_item_title">
                                        Side 1
                                    </p>
                                    <img
                                        src={requestDesign.photo1}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                        onError={(event) => {
                                            event.target.src =
                                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                            event.onerror = null
                                        }}
                                    />
                                </div>
                                <div className="design_info_pic_item">
                                    <p className="design_info_pic_item_title">
                                        Side 2
                                    </p>
                                    <img
                                        src={requestDesign.photo2}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                        onError={(event) => {
                                            event.target.src =
                                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                            event.onerror = null
                                        }}
                                    />
                                </div>
                                <div className="design_info_pic_item">
                                    <p className="design_info_pic_item_title">
                                        Side 3
                                    </p>
                                    <img
                                        src={requestDesign.photo3}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                        onError={(event) => {
                                            event.target.src =
                                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                            event.onerror = null
                                        }}
                                    />
                                </div>
                                <div className="design_info_pic_item">
                                    <p className="design_info_pic_item_title">
                                        Side 4
                                    </p>
                                    <img
                                        src={requestDesign.photo4}
                                        alt="kitchen pic"
                                        className="request-design-kitchen-size-pic"
                                        onError={(event) => {
                                            event.target.src =
                                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                            event.onerror = null
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="design_info_button_box">
                        <h4>Other Details</h4>
                        <hr className="design_info_hr" />
                        <div className="design_info_other_details_container">
                            <div className="design_info_other_details_item">
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Project Address:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                {requestDesign.street}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Existing Construction:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                {' '}
                                                <div>
                                                    Ceiling Height:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.ceilingHeight
                                                    }
                                                </div>
                                                <div>
                                                    Bulkhead Height:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.bulkhead
                                                    }
                                                </div>
                                                {requestDesign
                                                    ?.requestdesigndetail
                                                    ?.bulkhead === 'Yes' && (
                                                    <>
                                                        <div>
                                                            Bulkhead Size Depth:{' '}
                                                            {
                                                                requestDesign
                                                                    ?.requestdesigndetail
                                                                    ?.bulkheadDepth
                                                            }
                                                        </div>
                                                        <div>
                                                            Bulkhead Size
                                                            Height:{' '}
                                                            {
                                                                requestDesign
                                                                    ?.requestdesigndetail
                                                                    ?.bulkheadHeight
                                                            }
                                                        </div>
                                                    </>
                                                )}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Range / Oven:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.range
                                                    }
                                                </div>
                                                <div>
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.rangeSize === 'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.rangeSizeOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.rangeSize}
                                                </div>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.rangeType
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Range Hood / Ventilation:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.rangeHood === 'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.rangeHoodOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.rangeHood}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Refrigerator:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    Width:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.refrigeratorWidth
                                                    }
                                                </div>
                                                <div>
                                                    Depth:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.refrigeratorDepth
                                                    }
                                                </div>
                                                <div>
                                                    Height:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.refrigeratorHeight
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Microwave:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.microwave
                                                    }
                                                </div>
                                                <div>
                                                    Width:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.microwaveWidth
                                                    }
                                                </div>
                                                <div>
                                                    Depth:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.microwaveDepth
                                                    }
                                                </div>
                                                <div>
                                                    Height:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.microwaveHeight
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Dishwasher:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.dishwasherSize ===
                                                    'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.dishwasherSizeOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.dishwasherSize}
                                                </div>
                                                <div>
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.dishwasherLocation ===
                                                    'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.dishwasherLocationOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.dishwasherLocation}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Sink:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    Width:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.sinkWidth
                                                    }
                                                </div>
                                                <div>
                                                    Depth:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.sinkDepth
                                                    }
                                                </div>
                                                <div>
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.sink === 'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.sinkOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.sink}
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className="design_info_other_details_item">
                                <Table>
                                    <tbody>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Handles / Knobs:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.handles
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Cabinet Style & Height:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.cabinetStyle
                                                    }
                                                </div>
                                                <div>
                                                    Upper Wall Cabinet Height:{' '}
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.upperWallCabinetHeight ===
                                                    'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.upperWallCabinetHeightOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.upperWallCabinetHeight}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Cabinets & Storage Options:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.cabinetsStorage
                                                    }
                                                </div>
                                                <div>
                                                    Preference for base storage:{' '}
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.baseStorage
                                                    }
                                                </div>

                                                <div>
                                                    Preference for wall cabinet:{' '}
                                                    {requestDesign
                                                        ?.requestdesigndetail
                                                        ?.wallCabinet ===
                                                    'Other'
                                                        ? requestDesign
                                                              ?.requestdesigndetail
                                                              ?.wallCabinetOther
                                                        : requestDesign
                                                              ?.requestdesigndetail
                                                              ?.wallCabinet}
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Trim & Moldings:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div>
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.trim
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style={{ padding: '10px 0' }}>
                                                Extra Notes:
                                            </td>
                                            <td style={{ padding: '10px 0' }}>
                                                <div
                                                    style={{
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {
                                                        requestDesign
                                                            ?.requestdesigndetail
                                                            ?.note
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </Container>
            )}
        </Layout>
    )
}

export default ClientDesignInfo
