import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import request from '../../config/request'
import { getErrors } from '../../store/actions/errorActions'
import history from '../../history'
import { getAllPolicies } from '../../store/actions/policyActions'
import { checkPromoCode } from '../../store/actions/cartActions'

const CheckoutWithoutPay = () => {
    const dispatch = useDispatch()
    const [policy, setPolicy] = useState(false)
    const [processing, setProcessing] = useState(false)

    const cartState = useSelector((state) => state.cart)
    const {
        carts,
        shippingOption,
        information,
        billing,
        promoCode,
        promoRate,
    } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const policyState = useSelector((state) => state.policy)
    const { policies } = policyState

    useEffect(() => {
        if (promoCode) {
            const values = { promoCode }
            dispatch(checkPromoCode(values))
        }
    }, [dispatch, promoCode])

    useEffect(() => {
        if (!policies) {
            dispatch(getAllPolicies())
        }
    }, [dispatch, policies])

    const handleSubmit = async (e) => {
        // e.preventDefault()
        if (!policy) {
            dispatch(getErrors('Need agree our policy'))
            return false
        }
        setProcessing(true)

        const clientId = clientInfo ?? ''
        try {
            const { data } = await request.post('/payment/withoutpay', {
                carts,
                shippingOption,
                information,
                billing,
                clientId,
                promoCode,
                promoRate,
            })

            if (data.data.status === 'succeed') {
                if (clientInfo) {
                    history.push(`/clientpaymentsuccess/${data.data.orderId}/2`)
                } else {
                    history.push(`/guestpaymentsuccess/${data.data.orderId}/2`)
                }
                setProcessing(false)
            } else {
                console.log('Bad request')
                dispatch(getErrors('Bad request'))
            }

            setProcessing(false)
        } catch (error) {
            console.log(error)
            dispatch(getErrors(error?.response?.data?.data))
            setProcessing(false)
        }
    }
    return (
        <div className="payment-method">
            <div style={{ padding: '20px 10px' }}>
                Thank you for your order request! We take pride in our
                personalized service: One of our consultants will contact you
                within 24 hours to discuss stock availability, delivery
                preferences, and payment options.
            </div>
            <div className="payment-policy-container">
                <div className="payment-policy">
                    {policies?.map((policy) => (
                        <div className="payment-policy_info" key={policy.id}>
                            <div className="payment-policy_info_title">
                                {policy.title}
                            </div>
                            <div className="payment-policy_info_detail">
                                {policy.detail}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div style={{ padding: '20px 0', display: 'flex' }}>
                <div style={{ width: '50px' }}>
                    <input
                        type="checkbox"
                        name="agreepolicy"
                        defaultChecked={policy}
                        onClick={() => setPolicy(!policy)}
                    />
                </div>
                <div>
                    Check here to indicate that you have read and agree to
                    hs-online.com policy
                </div>
            </div>
            <div className="checkout-information-button-box">
                <button
                    className="checkout-information-button"
                    disabled={processing}
                    onClick={() => handleSubmit()}
                    style={
                        processing
                            ? { cursor: 'not-allowed' }
                            : { cursor: 'pointer' }
                    }
                >
                    {processing ? 'PROCESSING' : 'Submit Order'}
                </button>
            </div>
        </div>
    )
}

export default CheckoutWithoutPay
