import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllDeliveryLocations } from '../../store/actions/cartActions'
import CheckoutDeliveryPrice from './CheckoutDeliveryPrice'

const CheckoutDelivery = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts, postCode, shippingOption, deliveryLocations } = cartState

    useEffect(() => {
        dispatch(getAllDeliveryLocations())
    }, [dispatch])
    return (
        <div>
            {deliveryLocations && (
                <CheckoutDeliveryPrice
                    carts={carts}
                    postCode={postCode}
                    deliveryLocations={deliveryLocations}
                    shippingOption={shippingOption}
                />
            )}
        </div>
    )
}

export default CheckoutDelivery
