export const generateNum = () => {
    const now = new Date()
    let month = '' + (now.getMonth() + 1)
    let day = '' + now.getDate()
    let year = now.getFullYear()
    let hour = '' + now.getHours()
    let minute = '' + now.getMinutes()
    let second = '' + now.getSeconds()

    if (month.length < 2) month = '0' + month
    if (day.length < 2) day = '0' + day
    if (hour.length < 2) hour = '0' + hour
    if (minute.length < 2) minute = '0' + minute
    if (second.length < 2) second = '0' + second
    const first = [year, month, day].join('')
    const last = [hour, minute, second].join('')
    return [first, last].join('')
}
