import { Container } from '@material-ui/core'
import moment from 'moment'
import React, { useMemo, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { cartType } from '../common/cardType'
import Loader from '../components/Loader'
import request from '../config/request'
import useAPI from '../hooks/useAPI'
import { getErrors } from '../store/actions/errorActions'

const PaymentReceipt = ({ match }) => {
    const dispatch = useDispatch()
    const id = match.params.id
    const api = useMemo(() => request.get(`/payment/listone/${id}`), [id])
    const [result, loading, errors] = useAPI(api)
    useEffect(() => {
        if (errors) {
            dispatch(getErrors(errors))
        }
    }, [dispatch, errors])

    if (loading) return <Loader />
    return (
        <Container>
            <div className="payment-receipt-container">
                <div className="payment-receipt-logo">
                    <img
                        src="https://6m023.img.af.d.sendibt2.com/im/3876063/cd79429f55842e3729b1cd1deb3f7facbc3bd16537cd14770b738e320eb83160.png?e=hbg06CTvs8eHlyBsCHgSq464wIwgfdoxk00r89DkhVbty4202zqfAi2BRXG0mOwSufhTRpsW2AlxHqTu4ggtcpoixN1RA-j8IR6gnjQ6AtYGhM0UMIZX5gmJfhzBF0z0N6KSbbWhW0NhyJaB7dqDwwAGt2VfC9jP8Yl0M8OqmekhjB-DEZTN8DmRWfAnWvr5Hd5bWa-4KMGzjc84hVS3lT9PdndemrERtFkkiJOUq334kkXxT4gVZf80TJ1xM6nIbJCwyHK5P_S2-umtmbZTdzUGrrZEZAPx3PKur3uDL8IpziCRmLjqBK-MCQr6nyTbKD7PHMREPvfbZ3xeT1vPLjhlnQaNSaEhfSQxP8S5rS-tGKM"
                        alt="logo"
                    />
                    <h1 style={{ marginTop: '20px' }}>Payment receipt</h1>
                    <h3>Invoice No. {result.orderinvoice.invoiceNo}</h3>
                    <div className="payment-receipt-paid-text">
                        Paid on {moment(result.paymentDate).format('ll')}
                    </div>
                    <p className="payment-receipt-small-text">HS-Online.com</p>
                    <p className="payment-receipt-small-text">
                        Unit 1, 854 Westport Cres.
                    </p>
                    <p className="payment-receipt-small-text">
                        Mississauga, ON L5T 1N5 Canada
                    </p>
                    <p
                        className="payment-receipt-small-text"
                        style={{ marginTop: '10px' }}
                    >
                        1-833-770-7077
                    </p>
                </div>
                <div style={{ marginTop: '20px' }}>
                    <div className="receipt-checkmark">
                        <div style={{ width: '45%' }}>
                            <hr className="hr-color" />
                        </div>
                        <div style={{ width: '5%', textAlign: 'center' }}>
                            <img
                                src="https://res.cloudinary.com/mengchenghui-com/image/upload/v1618846739/MCH_contract/checkmark-reminder-email_hpczes.jpg"
                                alt="right"
                            />
                        </div>
                        <div style={{ width: '45%' }}>
                            <hr className="hr-color" />
                        </div>
                    </div>
                </div>
                <div>
                    <p style={{ padding: '20px 10px' }}>
                        Payment for Invoice #{result.orderinvoice.invoiceNo}
                    </p>
                    <hr className="hr-color" />
                    <h5
                        style={{
                            textAlign: 'center',
                            padding: '20px 0',
                            fontSize: '20px',
                        }}
                    >
                        Payment Amount: ${result.amount} CAD
                    </h5>
                    <hr className="hr-color" />
                    <p style={{ textAlign: 'center', padding: '20px 0' }}>
                        PAYMENT METHOD:{' '}
                        {result.paymentType === 'CC' ? (
                            <span>
                                {cartType(result.cardType)} ****{result.cardNo}
                            </span>
                        ) : (
                            <span>{result.paymentType}</span>
                        )}
                    </p>
                    <div style={{ textAlign: 'center', padding: '30px 0' }}>
                        {result.paid && (
                            <img
                                src="https://res.cloudinary.com/mengchenghui-com/image/upload/v1618847212/MCH_contract/paid-stamp-white-background_cbbknr.png"
                                alt="paid"
                            />
                        )}
                    </div>
                </div>
                <div
                    style={{
                        background: '#ECF0F1',
                        padding: '20px 10px',
                        textAlign: 'center',
                        borderTop: '1px solid #ccc',
                        color: '#959599',
                    }}
                >
                    <div>
                        Thanks for your business. If this invoice was sent in
                        error, please contact{' '}
                        <a
                            href="mailto:info@hs-online.com"
                            target="_blank"
                            rel="noreferrer"
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            info@hs-online.com
                        </a>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PaymentReceipt
