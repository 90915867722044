import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import { useCurrentWidth } from '../screenWidth'
import { getErrors } from '../../store/actions/errorActions'
import {
    changePostCode,
    changeShippingOption,
} from '../../store/actions/cartActions'

const CheckoutPostCode = () => {
    const dispatch = useDispatch()
    const screenWidth = useCurrentWidth()
    const [inputCode, setInputCode] = useState('')
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const cartState = useSelector((state) => state.cart)
    const { postCode, shippingOption } = cartState

    useEffect(() => {
        if (postCode) {
            setInputCode(postCode)
        } else {
            if (clientInfo) {
                setInputCode(clientInfo.postCode)
            }
        }
    }, [clientInfo, postCode])

    const handleSubmit = (e) => {
        e.preventDefault()

        if (!/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(inputCode)) {
            dispatch(getErrors('Please input valid Post Code'))
            return false
        }
        dispatch(changePostCode(inputCode))
        if (shippingOption?.shippingType === 1) {
            dispatch(changeShippingOption(null))
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="checkout-postcode-box">
                <div>What's your shipping postal code:</div>
                <div className="checkout-postcode-input">
                    {screenWidth < 769 ? (
                        <input
                            className="form-control-nolabel"
                            style={{
                                border: '1px solid #61696D',
                                width: '250px',
                            }}
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                        />
                    ) : (
                        <TextField
                            value={inputCode}
                            onChange={(e) => setInputCode(e.target.value)}
                        />
                    )}
                </div>
                <div>
                    <button className="checkout-postcode-button">
                        {screenWidth < 769 ? 'Continue' : '>'}
                    </button>
                </div>
            </div>
        </form>
    )
}

export default CheckoutPostCode
