import { useDispatch, useSelector } from 'react-redux'
import { Divider } from '@material-ui/core'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'
import { promoGroup } from '../../common/promoService'

const CountertopList = ({ product, shippingMethod, productCategory }) => {
    const dispatch = useDispatch()

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    const handleAddToCard = () => {
        const promoGroupId = promoGroup(product)
        const addProduct = product.productcolors[0]
        const values = {
            id: `${addProduct.id}-0-0-0`,
            productColorId: addProduct.id,
            name: product.name,
            skuNo: addProduct.skuNo,
            stock: addProduct.stock,
            basePrice: addProduct.price,
            price: addProduct.price,
            picLink: addProduct.productpictures.sort(
                (a, b) => b.mainPage - a.mainPage
            )[0]?.picLink
                ? addProduct.productpictures.sort(
                      (a, b) => b.mainPage - a.mainPage
                  )[0]?.picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
            productWidth: product.productWidth,
            productHeight: product.productHeight,
            productDepth: product.productDepth,
            color: addProduct.stylecolor.colorNumber,
            shippingMethod: shippingMethod,
            doorDirection: '',
            glassOption: '',
            depthOption: '',
            qty: 1,
            promoGroupId,
            productCategory,
        }
        dispatch(addToCart(values))
        if (shippingOption?.shippingType === 1) {
            dispatch(changeShippingOption(null))
            dispatch(shippingStage(1))
        }
    }
    return (
        <>
            <div className="sample_countertop_box">
                <div className="divider_mobile">
                    <Divider />
                </div>
                <div className="sample_countertop_item">
                    <div className="sample_countertop_item_left">
                        <img
                            src={
                                product.productcolors[0].productpictures[0]
                                    .picLink
                            }
                            alt="pic"
                        />
                        <p>{product.name}</p>
                    </div>
                    <div className="sample_countertop_item_right">
                        <div className="sample_countertop_right_price">
                            <span style={{ fontWeight: '600' }}>
                                ${product.productcolors[0].price}
                            </span>
                            <span style={{ fontSize: '12px' }}>/each</span>
                        </div>
                        <div className="sample_countertop_right_shipping">
                            Free shipping
                        </div>
                        <div className="sample_countertop_right_refund">
                            100% Refundable
                        </div>
                        <div className="sample_countertop_right_button">
                            <button onClick={() => handleAddToCard()}>
                                Order this sample
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CountertopList
