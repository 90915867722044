import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { promoGroup } from '../../common/promoService'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'
import { productOption } from '../../store/actions/productsActions'
import { useCurrentWidth } from '../screenWidth'

const ProductList = ({ product, searchColor }) => {
    const dispatch = useDispatch()
    const [selectColor, setSelectColor] = useState(() => searchColor)
    const [selectPic, setSelectPic] = useState('')
    const [selectPrice, setSelectPrice] = useState('')
    const [selectStock, setSelectStock] = useState(0)
    const [chooseColor, setChooseColor] = useState(false)

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    useEffect(() => {
        let displayImg = ''
        if (searchColor) {
            displayImg = product.productcolors
                .filter((color) => color.stylecolor.id === searchColor)[0]
                ?.productpictures?.sort((a, b) => b.mainPage - a.mainPage)[0]
                ?.picLink
                ? product.productcolors
                      .filter((color) => color.stylecolor.id === searchColor)[0]
                      ?.productpictures?.sort(
                          (a, b) => b.mainPage - a.mainPage
                      )[0]?.picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
            setSelectPic(displayImg)
            setSelectColor(searchColor)
            setSelectPrice(
                product.productcolors.filter(
                    (color) => color.stylecolor.id === searchColor
                )[0]?.price
            )
            setSelectStock(
                product.productcolors.filter(
                    (color) => color.stylecolor.id === searchColor
                )[0]?.stock
            )
        } else {
            displayImg = product.productcolors[0]?.productpictures?.sort(
                (a, b) => b.mainPage - a.mainPage
            )[0]?.picLink
                ? product.productcolors[0]?.productpictures.sort(
                      (a, b) => b.mainPage - a.mainPage
                  )[0].picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
            setSelectPic(displayImg)

            if (product.productcolors.length === 1) {
                setSelectColor(product.productcolors[0].stylecolor.id)
                setSelectPrice(product.productcolors[0].price)
                setSelectStock(product.productcolors[0].stock)
            }
        }
    }, [searchColor, product.productcolors])

    const displayPrice = Math.min(
        ...product.productcolors.map((color) => color.price)
    )

    const handleColorChanged = (id) => {
        let displayImg = ''
        displayImg = product.productcolors
            .filter((color) => color.stylecolor.id === id)[0]
            .productpictures?.sort((a, b) => b.mainPage - a.mainPage)[0]
            ?.picLink
            ? product.productcolors
                  .filter((color) => color.stylecolor.id === id)[0]
                  .productpictures?.sort((a, b) => b.mainPage - a.mainPage)[0]
                  ?.picLink
            : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'

        const selectProductColor = product.productcolors.filter(
            (color) => color.stylecolor.id === id
        )[0]

        setSelectPic(displayImg)
        setSelectColor(id)
        setSelectPrice(selectProductColor?.price)
        setSelectStock(selectProductColor?.stock)
    }

    const fromPrice = (
        <div className="product_list_price_from">
            <span className="product_list_price_from_from">From</span>{' '}
            <span className="product_list_price_from_price">
                ${displayPrice}
            </span>
        </div>
    )

    const handleAddToCard = () => {
        const addProduct = product.productcolors.filter(
            (color) => color.stylecolor.id === selectColor
        )[0]
        const promoGroupId = promoGroup(product)
        const productCategory = product.style.category.slug
        if (
            product.style.category.productOption &&
            (product.doorDirection ||
                product.glassdoors.length > 0 ||
                product.cabinetdepths.length > 0)
        ) {
            const productSelect = {
                addProduct,
                name: product.name,
                promoGroupId,
                productCategory,
                doorDirection: product.doorDirection,
                glassdoors: product.glassdoors,
                cabinetdepths: product.cabinetdepths,
                productWidth: product.productWidth,
                productHeight: product.productHeight,
                productDepth: product.productDepth,
                shippingMethod: product.style.category.shippingMethod,
            }
            dispatch(productOption(true, productSelect))
        } else {
            const values = {
                id: `${addProduct.id}-0-0-0`,
                productColorId: addProduct.id,
                name: product.name,
                skuNo: addProduct.skuNo,
                stock: addProduct.stock,
                basePrice: addProduct.price,
                price: addProduct.price,
                picLink: addProduct.productpictures.sort(
                    (a, b) => b.mainPage - a.mainPage
                )[0]?.picLink
                    ? addProduct.productpictures.sort(
                          (a, b) => b.mainPage - a.mainPage
                      )[0]?.picLink
                    : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
                productWidth: product.productWidth,
                productHeight: product.productHeight,
                productDepth: product.productDepth,
                color: addProduct.stylecolor.colorNumber,
                shippingMethod: product.style.category.shippingMethod,
                productCategory,
                doorDirection: '',
                glassOption: '',
                depthOption: '',
                qty: 1,
                promoGroupId,
            }
            // console.log(values)
            dispatch(addToCart(values))
            if (shippingOption?.shippingType === 1) {
                dispatch(shippingStage(1))
                dispatch(changeShippingOption(null))
            }
        }
    }

    const screenWidth = useCurrentWidth()

    return (
        <>
            <div className="product_list_container">
                <Link to={`/product/${product.slug}`}>
                    <div className="product_list_pic">
                        {selectPic && (
                            <img
                                src={selectPic}
                                alt="Product Pic"
                                onError={(event) => {
                                    event.target.src =
                                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                    event.onerror = null
                                }}
                            />
                        )}
                    </div>
                </Link>
                <div className="product_list_reversed">
                    <div className="product_list_word_area">
                        <Link to={`/product/${product.slug}`}>
                            <div
                                className="product_list_name"
                                style={{ cursor: 'pointer' }}
                            >
                                {screenWidth < 769
                                    ? product.name.length > 30
                                        ? product.name.substr(0, 30) + '...'
                                        : product.name
                                    : product.name.length > 70
                                    ? product.name.substr(0, 70) + '...'
                                    : product.name}
                            </div>
                        </Link>
                        <div className="product_list_measure">
                            {' '}
                            {product.productWidth}" W x {product.productHeight}"
                            H x {product.productDepth}" D
                        </div>
                    </div>
                    <div className="product_list_price">
                        {selectPrice ? `$${selectPrice}` : fromPrice}
                    </div>
                </div>
                <div className="product_list_bottom">
                    <div className="product_list_colors">
                        <div className="product_list_colors_title">
                            More variants
                        </div>
                        <div className="product_list_colors_list">
                            {product.productcolors.slice(0, 4).map((color) => (
                                <div key={color.id}>
                                    <div
                                        onClick={() =>
                                            handleColorChanged(
                                                color.stylecolor.id
                                            )
                                        }
                                        className="product_list_color"
                                        style={
                                            selectColor === color.stylecolor.id
                                                ? {
                                                      border: '1px solid #000',
                                                      background:
                                                          color.stylecolor
                                                              .colorNumber,
                                                  }
                                                : {
                                                      background:
                                                          color.stylecolor
                                                              .colorNumber,
                                                  }
                                        }
                                    ></div>
                                </div>
                            ))}
                            {product.productcolors.length > 4 ? (
                                <div className="product_list_color_more">
                                    +
                                    {parseInt(product.productcolors.length) - 4}
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                        {chooseColor && (
                            <div className="product_list_color_select">
                                Please choose a color
                            </div>
                        )}
                    </div>
                    <div className="product_list_add_button">
                        <button
                            onClick={() =>
                                selectColor
                                    ? selectStock <= 0
                                        ? null
                                        : handleAddToCard()
                                    : null
                            }
                            onMouseOver={() =>
                                screenWidth > 768
                                    ? selectColor
                                        ? null
                                        : setChooseColor(true)
                                    : null
                            }
                            onMouseLeave={() =>
                                screenWidth > 768
                                    ? selectColor
                                        ? null
                                        : setChooseColor(false)
                                    : null
                            }
                            // disabled={selectStock <= 0}
                            style={
                                selectStock <= 0
                                    ? { cursor: 'not-allowed' }
                                    : { cursor: 'pointer' }
                            }
                        >
                            {selectColor
                                ? selectStock <= 0
                                    ? 'Out of stock'
                                    : 'Add to cart'
                                : 'Select color'}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductList
