import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CheckoutInformationForm from './CheckoutInformationForm'

const CheckoutBilling = () => {
    const [values, setValues] = useState(null)
    const cartState = useSelector((state) => state.cart)
    const { information, billing } = cartState

    useEffect(() => {
        setValues(billing ? billing : information)
    }, [billing, information])

    return (
        <div>
            <CheckoutInformationForm
                initialValues={values}
                formType="billing"
            />
        </div>
    )
}

export default CheckoutBilling
