import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import Divider from '@material-ui/core/Divider'
import { Link } from 'react-router-dom'

const NavHeader = ({ product }) => {
    const rightArrow = (
        <KeyboardArrowRightIcon style={{ fontSize: 18, margin: '0 10px' }} />
    )
    return (
        <>
            <div className="nav_header_hr">
                <Divider />
            </div>
            <div className="nav_header_container">
                <span className="nav_header_category">
                    <Link to="/productspage">Products</Link> {rightArrow}{' '}
                    <Link to={product.style.category.url}>
                        {product.style.category.name}
                    </Link>{' '}
                    {rightArrow}{' '}
                    <Link
                        to={`/products/${product.style.category.slug}/${product.style.slug}`}
                    >
                        {product.style.name}
                    </Link>{' '}
                    {rightArrow}
                </span>{' '}
                <span className="nav_header_name">{product.name}</span>
            </div>
        </>
    )
}

export default NavHeader
