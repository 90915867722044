import uploadImg from '../../config/uploadImg'
import { controlLoading } from '../../store/actions/loadingActions'

export const uploadCloudinary = async (files, dispatch) => {
    const data = new FormData()
    data.append('file', files)
    data.append('upload_preset', 'Client_request_design')
    try {
        dispatch(controlLoading(true))
        const res = await uploadImg.post('', data)
        dispatch(controlLoading(false))
        return res.data.secure_url
    } catch (e) {
        console.log(e)
        dispatch(controlLoading(false))
        return null
    }
}
