import {
    CLIENT_LOGOIN_SUCCESS,
    CLIENT_LOGOUT,
    CLIENT_NAV_ID,
    CLIENT_UPDATE_INFO,
} from '../types'

const initialState = {
    clientInfo: null,
    clientToken: null,
    clientNavId: null,
}

const clientReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case CLIENT_LOGOIN_SUCCESS:
            return {
                ...state,
                clientInfo: payload.clientInfo,
                clientToken: payload.clientToken,
            }
        case CLIENT_UPDATE_INFO:
            return { ...state, clientInfo: payload }

        case CLIENT_LOGOUT:
            return {
                clientInfo: null,
                clientToken: null,
            }
        case CLIENT_NAV_ID:
            return {
                ...state,
                clientNavId: payload,
            }
        default:
            return state
    }
}
export default clientReducer
