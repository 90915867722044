import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_WARRANTIES } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllWarranties = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/support/warranty')
        dispatch({
            type: GET_ALL_WARRANTIES,
            payload: {
                style: data.data.style,
                warranties: data.data.warranties,
            },
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
