import CabinetLIst from './CabinetLIst'

const Cabinet = ({ samples }) => {
    return samples.products.map((product) => (
        <CabinetLIst
            product={product}
            key={product.id}
            shippingMethod={samples.category.shippingMethod}
            productCategory={samples.category.slug}
        />
    ))
}

export default Cabinet
