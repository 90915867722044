import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import request from '../config/request'
import { getErrors } from '../store/actions/errorActions'

const useInvoice = (id) => {
    const dispatch = useDispatch()
    const [invoice, setInvoice] = useState('')
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        ;(async () => {
            try {
                const { data } = await request.get(
                    `/orderinvoice/listone/${id}`
                )
                setInvoice(data.data)
            } catch (error) {
                console.log(error?.response?.data?.msg)
                dispatch(getErrors(error?.response?.data?.msg))
            }
            setLoading(false)
        })()
    }, [id, dispatch])
    return [invoice, loading]
}

export default useInvoice
