import { styleArr } from '../constant/default'

export const promoGroup = (product) => {
    let promoGroup = 0
    if (
        product?.producttype?.id === process.env.REACT_APP_WALL_ID &&
        styleArr.includes(product?.style?.slug)
    ) {
        promoGroup = 1
    }
    if (
        product?.producttype?.id === process.env.REACT_APP_BASE_ID &&
        styleArr.includes(product?.style?.slug)
    ) {
        promoGroup = 2
    }
    return promoGroup
}
