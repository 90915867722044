import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { renderInputNoLabel, renderSelect } from '../components/ReduxFormInputs'
import { Container, Divider } from '@material-ui/core'
import { signin } from '../store/actions/clientActions'
import ReCAPTCHA from 'react-google-recaptcha'

const provinceList = [
    'ON',
    'QC',
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'PE',
    'SK',
    'YT',
]

const Signin = (props) => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const { handleSubmit } = props
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        dispatch(signin(formValues))
    }
    return (
        <Container disableGutters={true} maxWidth="xs">
            <div className="divider_mobile">
                <Divider />
            </div>
            <div className="signin-title">CREATE MY ACCOUNT</div>
            <div className="signin-box">
                <form
                    onSubmit={handleSubmit(submitForm)}
                    noValidate
                    autoComplete="off"
                >
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="firstName"
                                type="text"
                                label="First Name"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="lastName"
                                type="text"
                                label="Last Name"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="email"
                            type="text"
                            label="Email"
                            max="100"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="address"
                            type="text"
                            label="Address"
                            max="200"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="city"
                                type="text"
                                label="City"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                        <div className="input-item-half-select">
                            <Field name="province" component={renderSelect}>
                                <option value="">Province</option>
                                {provinceList.map((province) => (
                                    <option value={province} key={province}>
                                        {province}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="postCode"
                            type="text"
                            label="Post Code"
                            max="10"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="phone"
                            type="text"
                            label="Telephone"
                            max="30"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="password"
                            type="password"
                            label="Password"
                            max="50"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="input-item-full">
                        <Field
                            name="rPassword"
                            type="password"
                            label="Confirm Password"
                            max="50"
                            component={renderInputNoLabel}
                        />
                    </div>
                    <div className="google-robert">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                        />
                    </div>
                    <div className="signin-button-box">
                        <button type="submit" className="signin-button">
                            Sign-In
                        </button>
                    </div>
                    <div className="signin-info">
                        By clicking “Sign-In” you acknowledge that you are a
                        Canada resident
                    </div>
                </form>
            </div>
        </Container>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'firstName',
        'lastName',
        'address',
        'city',
        'province',
        'postCode',
        'phone',
        'email',
        'password',
        'rPassword',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.password) {
        if (
            !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(
                values.password
            )
        ) {
            errors.password =
                'Password must consist of letters and numbers at least 8'
        }
    }

    if (values.postCode) {
        if (!/^(?:[A-Z]\d[A-Z][ -]?\d[A-Z]\d)$/i.test(values.postCode)) {
            errors.postCode = 'Invalid Post Code'
        }
    }

    if (values.phone) {
        if (
            !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                values.phone
            )
        ) {
            errors.phone = 'Invalid Phone number'
        }
    }
    if (values.rPassword) {
        if (values.password !== values.rPassword) {
            errors.rPassword = 'Not match'
            errors.password = 'Not match'
        }
    }

    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    return errors
}

export default reduxForm({
    form: 'signinForm',
    validate,
})(Signin)
