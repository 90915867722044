import StyleIcon from '@material-ui/icons/Style'
import EditIcon from '@material-ui/icons/Edit'
import BuildIcon from '@material-ui/icons/Build'

const sample = {
    icon: <StyleIcon className="help_icon_style" style={{ fontSize: 50 }} />,
    name: 'Order Wood Samples',
    url: '/sample/cabinet-sample',
}

const planner = {
    icon: <EditIcon className="help_icon_style" style={{ fontSize: 50 }} />,
    name: 'Home Planner Tool',
    url: '/planner',
}

const process = {
    icon: <BuildIcon className="help_icon_style" style={{ fontSize: 50 }} />,
    name: 'Self Installation Process',
    url: '/selfassemble',
}

export const helpingList = [sample, planner, process]
export const plannerHelpingList = [sample, process]
