import { useDispatch, useSelector } from 'react-redux'
import PicturePointDisplay from '../picturePoint/PicturePointDisplay'
import { PICTURE_POINT_YELLOW } from '../../constant/default'
import Container from '@material-ui/core/Container'
import ProductListFilter from './ProductListFilter'
import Divider from '@material-ui/core/Divider'
import { CHANGE_PRODUCTS_TYPE } from '../../store/types'

const ProductTypeList = ({ pType, searchType, dispatch }) => {
    const selectedCss =
        searchType === pType.id ? 'product-type-box-selected' : null
    return (
        <div
            className={`product_list_types_item ${selectedCss}`}
            onClick={() =>
                searchType === pType.id
                    ? dispatch({
                          type: CHANGE_PRODUCTS_TYPE,
                          payload: '',
                      })
                    : dispatch({
                          type: CHANGE_PRODUCTS_TYPE,
                          payload: pType.id,
                      })
            }
        >
            <div className="product_list_types_item_pic">
                <img src={pType.picLink} alt="Product List" />
            </div>
            <div className="product_list_types_item_name">{pType.name}</div>
        </div>
    )
}

const ProductListHeader = () => {
    const dispatch = useDispatch()
    const productsState = useSelector((state) => state.products)
    const {
        categoryInfo,
        styleInfo,
        productTypes,
        picturePointDesktop,
        picturePointMobile,
        searchType,
    } = productsState

    return categoryInfo && categoryInfo.picturePoint ? (
        <Container disableGutters={true}>
            <div className="product_list_picture_point_container">
                <div
                    className="product_list_picture_point_style"
                    style={{ backgroundColor: styleInfo.backgroundColor }}
                >
                    <h3>{styleInfo.name}</h3>
                    <p>{styleInfo.slogan}</p>
                </div>
                <div className="product_list_picture_point_desktop">
                    <PicturePointDisplay
                        picturePoint={picturePointDesktop}
                        pointColor={PICTURE_POINT_YELLOW}
                        noName={true}
                        samePage={true}
                    />
                </div>
                <div className="product_list_picture_point_mobile">
                    <PicturePointDisplay
                        picturePoint={picturePointMobile}
                        pointColor={PICTURE_POINT_YELLOW}
                        noName={true}
                        samePage={true}
                    />
                </div>
            </div>
        </Container>
    ) : (
        <>
            <div className="product_list_cabinet_container">
                <div className="product_list_display_mobile">
                    <Divider />
                </div>
                {styleInfo && (
                    <Container disableGutters={true}>
                        <div className="product_list_style_area">
                            <img src={styleInfo.iconLink} alt="icon" />

                            <div className="product_list_style_title">
                                <h3>{styleInfo.name}</h3>
                                <p>{styleInfo.slogan}</p>
                            </div>
                        </div>
                        {!productTypes && (
                            <Divider style={{ marginTop: '20px' }} />
                        )}
                    </Container>
                )}
                {productTypes && (
                    <div className="product_list_types_container">
                        <div className="product_list_types_title">
                            Choose Cabinet Type
                        </div>
                        <div className="product_list_types_box">
                            {productTypes.map((pType) => (
                                <ProductTypeList
                                    key={pType.id}
                                    pType={pType}
                                    searchType={searchType}
                                    dispatch={dispatch}
                                />
                            ))}
                        </div>
                    </div>
                )}
                {categoryInfo && (
                    <Container disableGutters={true}>
                        <ProductListFilter />
                    </Container>
                )}
            </div>
        </>
    )
}

export default ProductListHeader
