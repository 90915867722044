import { GET_ALL_RECOMMENDS } from '../types'

const initialState = {
    recommends: null,
}

export const recommendReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_RECOMMENDS:
            return {
                ...state,
                recommends: payload,
            }

        default:
            return state
    }
}

export default recommendReducer
