import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneOrder } from '../store/actions/orderActions'
import Layout from '../components/Layout'
import history from '../history'
import { Button, Container } from '@material-ui/core'
import OrderInfoBase from '../components/order/OrderInfoBase'

const ClientOrderInfo = ({ match }) => {
    const dispatch = useDispatch()
    const id = match.params.id

    const orderState = useSelector((state) => state.order)
    const { order } = orderState

    useEffect(() => {
        dispatch(getOneOrder(id))
    }, [dispatch, id])

    return (
        <Layout>
            {order ? (
                <Container>
                    <Button
                        variant="contained"
                        onClick={() => history.goBack()}
                        style={{ margin: '20px 0' }}
                    >
                        Back
                    </Button>
                    <OrderInfoBase order={order} />
                </Container>
            ) : (
                ''
            )}
        </Layout>
    )
}

export default ClientOrderInfo
