import { Container, Divider } from '@material-ui/core'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import CheckoutCart from '../components/checkout/CheckoutCart'
import CheckoutStage from '../components/checkout/CheckoutStage'
import CheckoutTotal from '../components/checkout/CheckoutTotal'
import history from '../history'

const GuestCheckout = () => {
    const cartState = useSelector((state) => state.cart)
    const { carts } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (carts.length === 0) {
            clientInfo ? history.push('/clientcart') : history.push('/cart')
        }
    }, [carts, clientInfo])
    return (
        <>
            <div className="divider-mobile-page">
                <Divider />
            </div>
            <Container>
                <div className="guest-checkout-box">
                    <div className="guest-checkout-info">
                        <CheckoutStage />
                    </div>
                    <div className="checkout-cart">
                        <div className="checkout-cart-area">
                            <CheckoutCart />
                        </div>
                        <div className="checkout-total-area">
                            <CheckoutTotal />
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default GuestCheckout
