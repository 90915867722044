import { Container, Divider } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { CLEANER_CARTS } from '../../store/types'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const PaymentSuccess = ({ orderId, typeId }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        localStorage.removeItem('HSOnlineCart')
        localStorage.removeItem('HSOnlineStage')
        localStorage.removeItem('HSOnlinePostCode')
        localStorage.removeItem('HSOnlineShippingOption')
        localStorage.removeItem('HSOnlineShippingInformation')
        localStorage.removeItem('HSOnlineShippingBilling')
        localStorage.removeItem('HSOnlineShippingType')
        dispatch({ type: CLEANER_CARTS })
    }, [dispatch])
    return (
        <Container>
            <div className="payment-success-box">
                <div className="payment-success-header">
                    {typeId === '1' ? 'Payment' : 'Order'} successful
                </div>
                <Divider />
                <div className="payment-success-body">
                    <div className="payment-success-left">
                        <CheckCircleIcon style={{ color: '#EDC400' }} />
                    </div>
                    <div className="payment-success-right">
                        <div className="payment-success-body1">
                            Thank you for your{' '}
                            {typeId === '1' ? 'payment' : 'order'}!
                        </div>
                        {typeId === '1' && (
                            <div className="payment-success-body2">
                                You will receive an conformation email
                            </div>
                        )}
                        <div className="payment-success-body3">
                            Your order number: {orderId}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PaymentSuccess
