import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllProductsPages } from '../store/actions/productsPageActions'
import Grid from '@material-ui/core/Grid'
import history from '../history'
import PageEndButton from '../components/PageEndButton'
import StyleIcon from '@material-ui/icons/Style'
import PicturePointDisplay from '../components/picturePoint/PicturePointDisplay'
import { PICTURE_POINT_YELLOW, smallPicBackground } from '../constant/default'
import { clearProducts } from '../store/actions/productsActions'
const ProductsPage = () => {
    const dispatch = useDispatch()
    const productsPageState = useSelector((state) => state.productsPage)
    const { bigPics, smallPics, picturePoint } = productsPageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!bigPics || !smallPics || !picturePoint) {
            dispatch(getAllProductsPages())
        }
    }, [dispatch, bigPics, smallPics, picturePoint])
    return (
        <>
            <Container disableGutters={true}>
                <Grid container spacing={3}>
                    {bigPics &&
                        bigPics.map((bigPic, index) => (
                            <Grid item xs={12} key={bigPic.id}>
                                <div className="products_big_container">
                                    <div
                                        className="products_big_pic"
                                        style={{
                                            background: `url(${bigPic.bigPicLink})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                        }}
                                    ></div>

                                    <div className="products_big_pic_box">
                                        <div
                                            className="products_big_pic_title_box"
                                            style={{
                                                backgroundColor:
                                                    smallPicBackground[index]
                                                        .color,
                                            }}
                                        >
                                            <h2>{bigPic.displayName}</h2>
                                            <p>{bigPic.slogan}</p>
                                            <img
                                                src="https://res.cloudinary.com/homespace/image/upload/v1655522377/Icons/Arrow_1_i5vuhl.png"
                                                alt="arrow_logo"
                                                style={{
                                                    width: '40px',
                                                    height: '40px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() =>
                                                    history.push(bigPic.url)
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </Container>
            <div style={{ marginTop: '10px' }}></div>
            <Container disableGutters={true}>
                <Grid container spacing={3}>
                    {smallPics &&
                        smallPics.map((smallPic) => (
                            <Grid item xs={6} md={3} key={smallPic.id}>
                                <div
                                    className="products_small_pic"
                                    style={{
                                        background: `url(${smallPic.picLink})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                    }}
                                >
                                    <div className="products_small_pic_box">
                                        <div
                                            className="products_small_pic_title_box"
                                            onClick={() => {
                                                dispatch(clearProducts())
                                                history.push(
                                                    `/products/${smallPic.category.slug}/${smallPic.slug}`
                                                )
                                            }}
                                        >
                                            <h2>{smallPic.name}</h2>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                </Grid>
            </Container>
            <Container disableGutters={true}>
                <div className="section_title_area">
                    <div className="section_title_name">
                        <h3>Explore our high-end kitchen series</h3>
                    </div>
                    <div className="section_yellow_line"></div>
                </div>
                {picturePoint && (
                    <PicturePointDisplay
                        picturePoint={picturePoint}
                        pointColor={PICTURE_POINT_YELLOW}
                    />
                )}
            </Container>
            <PageEndButton
                buttonTitle="Want to order some wood sample first?"
                icon={<StyleIcon />}
            />
        </>
    )
}

export default ProductsPage
