import { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const ProductDesc = ({ title, info }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="product_desc_container">
            <div className="product_desc_box" onClick={() => setOpen(!open)}>
                <div className="product_desc_title">{title}:</div>
                <div className="product_desc_icon">
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </div>
            </div>
            {open && <div className="product_desc_info">{info}</div>}
        </div>
    )
}

export default ProductDesc
