import CountertopList from './CountertopList'

const Countertop = ({ samples }) => {
    return (
        <div className="sample_countertop_container">
            {samples.products.map((product) => (
                <CountertopList
                    product={product}
                    key={product.id}
                    shippingMethod={samples.category.shippingMethod}
                    productCategory={samples.category.slug}
                />
            ))}
        </div>
    )
}

export default Countertop
