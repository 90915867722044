import { useDispatch, useSelector } from 'react-redux'
import history from '../../history'
import { setClientNavId } from '../../store/actions/clientActions'

const CheckoutCart = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { carts } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const cartsList = carts.slice(0, 3)

    const cartsMore = carts.slice(3).length

    return (
        <div className="checkout-cart-box">
            <div className="checkout-cart-title">
                <span>My cart</span>
                {clientInfo ? (
                    <span
                        className="checkout-cart-edit"
                        onClick={() => {
                            dispatch(setClientNavId(3))
                            history.push('/clientcart')
                        }}
                    >
                        Edit
                    </span>
                ) : (
                    <span
                        className="checkout-cart-edit"
                        onClick={() => history.push('/cart')}
                    >
                        Edit
                    </span>
                )}
            </div>
            <div className="checkout-cart-item-box">
                {cartsList.map((cart) => (
                    <div key={cart.id} className="checkout-cart-item">
                        <div className="checkout-cart-item-pic">
                            <img src={cart.picLink} alt="Product" />
                        </div>
                    </div>
                ))}
                {cartsMore > 0 ? (
                    <div className="checkout-cart-item-more">
                        <div className="checkout-cart-item-number">
                            +{cartsMore}
                        </div>
                    </div>
                ) : (
                    ''
                )}
            </div>
        </div>
    )
}

export default CheckoutCart
