import React from 'react'
import history from '../history'

const BestPriceGuarantee = () => {
    return (
        <div className="home_price_guarantee_container">
            <div className="home_price_guarantee_bg"></div>
            <div className="home_price_guarantee_text_box">
                <div className="home_price_guarantee_text_left">
                    <h3>Best Price Guarantee</h3>
                    <p>
                        Found a competitor that charges less? Let us know and
                        we’ll beat their prices by 8% on all our premium
                        products!
                    </p>
                </div>
                <div className="home_price_guarantee_text_right">
                    <button
                        className="home_price_guarantee_button"
                        onClick={() => history.push('/bestprice')}
                    >
                        Learn More
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BestPriceGuarantee
