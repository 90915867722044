import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllPolicies } from '../store/actions/policyActions'

const Policy = () => {
    const dispatch = useDispatch()
    const policyState = useSelector((state) => state.policy)
    const { style, policies } = policyState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!style || !policies) {
            dispatch(getAllPolicies())
        }
    }, [dispatch, style, policies])
    return (
        <>
            {style && (
                <Container disableGutters={true}>
                    <div
                        className="warranty_style_title"
                        style={{
                            background: `url(${style.picLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="warranty_style_slogan">
                            <h1 className="warranty_style_slogan_word">
                                {style.slogan}
                            </h1>
                        </div>
                    </div>
                </Container>
            )}
            {policies && (
                <Container disableGutters={true}>
                    <div className="warranty_info_box">
                        {policies.map((policy) => (
                            <div className="warranty_info_info" key={policy.id}>
                                <div className="warranty_info_title">
                                    {policy.title}
                                </div>
                                <div className="warranty_info_detail">
                                    {policy.detail}
                                </div>
                            </div>
                        ))}
                    </div>
                </Container>
            )}
        </>
    )
}

export default Policy
