import { useDispatch, useSelector } from 'react-redux'
import { chnageShippingType } from '../../store/actions/cartActions'
import CheckoutDelivery from './CheckoutDelivery'
import CheckoutPickUp from './CheckoutPickUp'

const CheckoutShippingOption = () => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { shippingType } = cartState

    const optionList = [
        { name: 'Delivery', id: 1 },
        { name: 'Pick Up (Free)', id: 2 },
    ]

    const handleClick = (id) => {
        dispatch(chnageShippingType(id))
    }
    return (
        <div className="shipping-option-box">
            <div className="shipping-option-top">
                {optionList.map((list) => (
                    <div
                        className="shipping-option-item"
                        key={list.id}
                        style={
                            list.id === shippingType
                                ? { background: '#FDDC3A' }
                                : null
                        }
                        onClick={() => handleClick(list.id)}
                    >
                        {list.name}
                    </div>
                ))}
            </div>
            <div className="shipping-option-info">
                {shippingType === 1 ? <CheckoutDelivery /> : <CheckoutPickUp />}
            </div>
        </div>
    )
}

export default CheckoutShippingOption
