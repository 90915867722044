import { useMemo } from 'react'
import { Divider } from '@material-ui/core'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import history from '../../history'
import CartList from './CartList'
import PrintIcon from '@material-ui/icons/Print'
import PromoCodeInput from './PromoCodeInput'
import { promoCodeList } from '../../constant/promoCode'

const Carts = () => {
    const cartState = useSelector((state) => state.cart)
    const { carts, promoCode, promoRate } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const subTotal = useMemo(() => {
        return carts.reduce((a, b) => (a += b.price * b.qty), 0)
    }, [carts])
    const allItems = useMemo(() => {
        return carts.reduce((a, b) => (a += parseInt(b.qty)), 0)
    }, [carts])

    const subPromoTotal = useMemo(() => {
        let result = 0
        if (promoCode === promoCodeList.BOGO) {
            result =
                carts.reduce(
                    (a, b) => (a += b.price * parseInt(b.promoActive)),
                    0
                ) || 0
        }
        if (promoCode === promoCodeList.BMSM2022) {
            result = carts
                .filter((item) => item.promoActive === 1)
                .reduce(
                    (sum, product) =>
                        sum +
                        Number(
                            (
                                product.qty *
                                (product.price * (promoRate / 100))
                            ).toFixed(2)
                        ),
                    0
                )
        }
        return result
    }, [carts, promoCode, promoRate])

    return (
        <>
            <div className="carts-items-count">TIEMS IN CART ({allItems})</div>
            <div className="carts-container">
                <div className="carts-lists">
                    {carts &&
                        carts.map((cart) => (
                            <CartList key={cart.id} cart={cart} />
                        ))}
                </div>
                <div className="carts-total-box">
                    <div className="carts-print">
                        <PrintIcon
                            style={{ color: '#fddc3a', marginRight: '10px' }}
                        />
                        <Link to={`/cartprint`}>Print Cart</Link>
                    </div>

                    <div className="carts-total">
                        <div className="carts-total-item">
                            <span className="carts-total-span-left">
                                <strong>Merchandise Subtotal</strong>
                            </span>
                            <span className="carts-total-span-right">
                                <strong>${subTotal.toFixed(2)}</strong>
                            </span>
                        </div>
                        {subPromoTotal > 0 && promoCode && (
                            <div className="carts-total-item">
                                <span className="carts-total-span-left">
                                    <strong>
                                        {promoCode}{' '}
                                        {promoRate ? `-${promoRate}%` : ''}
                                    </strong>
                                </span>
                                <span className="carts-total-span-right">
                                    <strong>
                                        - ${subPromoTotal.toFixed(2)}
                                    </strong>
                                </span>
                            </div>
                        )}
                        {/* <div className="carts-total-item">
                            <span className="carts-total-span-left">
                                Shipping
                            </span>
                            <span className="carts-total-span-right">
                                Calculate
                            </span>
                        </div> */}
                        <div className="carts-total-item">
                            <span className="carts-total-span-left">
                                GST/HST
                            </span>
                            <span className="carts-total-span-right">
                                $
                                {((subTotal - subPromoTotal) * 0.13).toFixed(2)}
                            </span>
                        </div>
                        <div className="carts-total-item">
                            <span className="carts-total-span-left">Total</span>
                            <span className="carts-total-span-right">
                                $
                                {((subTotal - subPromoTotal) * 1.13).toFixed(2)}
                            </span>
                        </div>
                        {carts.length > 0 && (
                            <>
                                <Divider />
                                <div className="carts-total-button">
                                    <button
                                        onClick={() => {
                                            clientInfo
                                                ? history.push(
                                                      '/clientCheckout'
                                                  )
                                                : history.push('/continue')
                                        }}
                                    >
                                        Continue to checkout
                                    </button>
                                </div>
                            </>
                        )}
                    </div>
                    <PromoCodeInput />
                </div>
            </div>
        </>
    )
}

export default Carts
