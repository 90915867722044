import { GET_ALL_SAMPLES, SAMPLE_TOP_PAGE } from '../types'

const initialState = {
    samples: null,
    topPage: true,
}

export const sampleReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SAMPLES:
            return {
                ...state,
                samples: payload,
            }
        case SAMPLE_TOP_PAGE:
            return {
                ...state,
                topPage: payload,
            }
        default:
            return state
    }
}

export default sampleReducer
