import { Container } from '@material-ui/core'
import history from '../history'
import Login from './Login'

const Continue = () => {
    return (
        <Container>
            <div className="continue-box">
                <div className="continue-login">
                    <Login redirectLocation="/clientCheckout" />
                </div>
                <div className="continue-line"></div>
                <div className="continue-guest">
                    <div className="continue-guest-title">
                        Checkout as Guest
                    </div>
                    <div className="continue-guest-button-box">
                        <button
                            className="continue-guest-button"
                            onClick={() => history.push('/guestcheckout')}
                        >
                            Checkout
                        </button>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Continue
