import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_VANITIES_PAGE } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllVanitiesPages = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/vanities/vanitiespage')
        dispatch({
            type: GET_ALL_VANITIES_PAGE,
            payload: {
                styles: data.data.styles,
                picturePoint: data.data.picturePoint,
            },
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
