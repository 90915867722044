import { useState, useRef } from 'react'
import { reduxForm, Field } from 'redux-form'
import { useDispatch } from 'react-redux'
import { renderInputNoLabel } from '../ReduxFormInputs'
import { buildTypeLists, sizeMeasureLists } from '../../constant/default'
import DragDrop from './DragDrop'
import { uploadCloudinary } from './uploadCloudinary'
import request from '../../config/request'
import { errorsReturn } from '../../store/errorsReturn'
import { controlLoading } from '../../store/actions/loadingActions'
import { getErrors } from '../../store/actions/errorActions'
import history from '../../history'
import ReCAPTCHA from 'react-google-recaptcha'

const RequestDesignForm = (props) => {
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const { handleSubmit, clientId } = props

    const [buildType, setBuildType] = useState(1)
    const [sizeMeasure, setSizeMeasure] = useState(1)
    const [photo1, setPhoto1] = useState(null)
    const [photo2, setPhoto2] = useState(null)
    const [photo3, setPhoto3] = useState(null)
    const [photo4, setPhoto4] = useState(null)

    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        console.log('Recaptcha ==>>>', googleRes)
        if (photo1) {
            const photo1Link = await uploadCloudinary(photo1, dispatch)
            formValues['photo1'] = photo1Link
        }
        if (photo2) {
            const photo2Link = await uploadCloudinary(photo2, dispatch)
            formValues['photo2'] = photo2Link
        }
        if (photo3) {
            const photo3Link = await uploadCloudinary(photo3, dispatch)
            formValues['photo3'] = photo3Link
        }
        if (photo4) {
            const photo4Link = await uploadCloudinary(photo4, dispatch)
            formValues['photo4'] = photo4Link
        }
        formValues['buildType'] = buildType
        formValues['sizeMeasure'] = sizeMeasure
        formValues['clientId'] = clientId
        formValues['googleRes'] = googleRes
        try {
            const { data } = await request.post(
                '/client/requestdesign/create',
                formValues
            )
            history.push(`/requestsuccess/${data.data.projectNo}`)
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors)
        }
    }
    return (
        <div className="request-design-form-container">
            <form
                onSubmit={handleSubmit(submitForm)}
                noValidate
                autoComplete="off"
            >
                <div className="request-design-form-buildtype-box">
                    {buildTypeLists.map((type) => (
                        <div
                            key={type.id}
                            className={`request-design-form-buildtype-list ${
                                type.id === buildType ? 'active' : ''
                            }`}
                            onClick={() => setBuildType(type.id)}
                        >
                            <img
                                src={type.picLink}
                                alt="type Pic"
                                className="request-design-form-buildtype-pic"
                            />
                            <p className="request-design-form-buildtype-name">
                                {type.name}
                            </p>
                        </div>
                    ))}
                </div>
                <div className="request-design-input-box">
                    <h3 className="request-design-input-title">
                        Help us with your information
                    </h3>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="firstName"
                                type="text"
                                label="First Name"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="lastName"
                                type="text"
                                label="Last Name"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="email"
                                type="text"
                                label="Email"
                                max="250"
                                component={renderInputNoLabel}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="phone"
                                type="text"
                                label="Telephone"
                                max="50"
                                component={renderInputNoLabel}
                            />
                        </div>
                    </div>

                    {/* room size */}
                    <div className="request-design-input-size-box">
                        <div className="request-design-input-size-guid">
                            <div className="request-design-input-size-item"></div>
                            <div className="request-design-input-size-item text-center">
                                <h3 className="request-design-input-size-title">
                                    Your room size
                                </h3>
                            </div>
                            <div className="request-design-input-size-item flex-flex">
                                <div className="request-design-size-measure">
                                    {sizeMeasureLists.map((size) => (
                                        <div
                                            key={size.id}
                                            onClick={() =>
                                                setSizeMeasure(size.id)
                                            }
                                            className={`request-design-size-measure-item ${
                                                sizeMeasure === size.id
                                                    ? 'active'
                                                    : ''
                                            }`}
                                        >
                                            {size.name}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="request-design-input-size-guid">
                            <div className="request-design-input-size-item">
                                <Field
                                    name="width"
                                    type="number"
                                    label="Width:"
                                    max="20"
                                    component={renderInputNoLabel}
                                />
                            </div>
                            <div className="request-design-input-size-item">
                                <Field
                                    name="length"
                                    type="number"
                                    label="Length:"
                                    max="20"
                                    component={renderInputNoLabel}
                                />
                            </div>
                            <div className="request-design-input-size-item">
                                <Field
                                    name="height"
                                    type="number"
                                    label="Height:"
                                    max="20"
                                    component={renderInputNoLabel}
                                />
                            </div>
                        </div>
                    </div>
                    {/* helping guide */}
                    <div style={{ textAlign: 'center', fontSize: '14px' }}>
                        Don’t know how to measure?
                        <br /> Check our helping guide
                    </div>
                    {/* upload images */}
                    <div className="request-design-upload-box">
                        <h3 className="request-design-input-size-title">
                            Upload your kitchen images
                        </h3>
                        <p style={{ textAlign: 'center', fontSize: '14px' }}>
                            ( Only accept images )
                        </p>
                        <div className="request-design-upload-container">
                            <div className="request-design-upload-left">
                                <img
                                    src="https://res.cloudinary.com/home-space/image/upload/v1640031570/Request_Design/Screen_Shot_2021-12-20_at_3.19.25_PM_juufqf.png"
                                    alt="position pic"
                                    className="request-design-upload-left-pic"
                                />
                            </div>
                            <div className="request-design-upload-right">
                                <div className="request-design-upload-right-input">
                                    <DragDrop
                                        file={photo1}
                                        setFile={setPhoto1}
                                        id={1}
                                    />
                                </div>
                                <div className="request-design-upload-right-input">
                                    <DragDrop
                                        file={photo2}
                                        setFile={setPhoto2}
                                        id={2}
                                    />
                                </div>
                                <div className="request-design-upload-right-input">
                                    <DragDrop
                                        file={photo3}
                                        setFile={setPhoto3}
                                        id={3}
                                    />
                                </div>
                                <div className="request-design-upload-right-input">
                                    <DragDrop
                                        file={photo4}
                                        setFile={setPhoto4}
                                        id={4}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="google-robert">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                        />
                    </div>
                    <div className="request-design-button-box">
                        <button type="submit" className="request-design-button">
                            Sent to my designer
                        </button>
                    </div>
                </div>
            </form>
        </div>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['firstName', 'lastName', 'phone', 'email']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.phone) {
        if (
            !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                values.phone
            )
        ) {
            errors.phone = 'Invalid Phone number'
        }
    }

    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    return errors
}

export default reduxForm({
    form: 'requestDesignForm',
    validate,
})(RequestDesignForm)
