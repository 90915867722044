import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Backdrop from '../backDrop/Backdrop'
import { productOption } from '../../store/actions/productsActions'
import CloseIcon from '@material-ui/icons/Close'
import { Divider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { getErrors } from '../../store/actions/errorActions'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '95%',
        background: '#ffffff',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}))

const ProductOption = ({ product }) => {
    const classes = useStyles()
    const dispatch = useDispatch()

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    const [glass, setGlass] = useState('0&0')
    const [depth, setDepth] = useState('0&0')
    const [error, setError] = useState(false)
    const [price, setPrice] = useState(() => product.addProduct.price)
    const [door, setDoor] = useState(null)
    const handleClose = () => {
        dispatch(productOption(false, null))
    }

    const handleGlassChanged = (e) => {
        setGlass(e.target.value)
        const depthPrice = parseFloat(depth.split('&')[1])
        const glassPrice = parseFloat(e.target.value.split('&')[1])
        totalPrice(glassPrice, depthPrice)
    }

    const handleDepthChanged = (e) => {
        setDepth(e.target.value)
        const glassPrice = parseFloat(glass.split('&')[1])
        const depthPrice = parseFloat(e.target.value.split('&')[1])
        totalPrice(glassPrice, depthPrice)
    }

    const totalPrice = (glassPrice, depthPrice) => {
        setPrice(parseFloat(product.addProduct.price) + glassPrice + depthPrice)
    }

    const handleSubmit = () => {
        if (product.doorDirection && !door) {
            setError(true)
            dispatch(getErrors('Please select Door location'))
            return false
        }

        let doorId = 0
        let doorDirection = null
        if (door) {
            doorId = door
            door === 1 ? (doorDirection = 'Left') : (doorDirection = 'Right')
        }

        let glassId = 0
        let glassOption = null
        if (glass.split('&')[0] !== '0') {
            glassId = glass.split('&')[0]
            glassOption = glass
        }

        let depthId = 0
        let depthOption = null
        if (depth.split('&')[0] !== '0') {
            depthId = depth.split('&')[0]
            depthOption = depth
        }

        const values = {
            id: `${product.addProduct.id}-${doorId}-${glassId}-${depthId}`,
            productColorId: product.addProduct.id,
            name: product.name,
            skuNo: product.addProduct.skuNo,
            stock: product.addProduct.stock,
            basePrice: product.addProduct.price,
            price: price,
            picLink: product.addProduct.productpictures.sort(
                (a, b) => b.mainPage - a.mainPage
            )[0]?.picLink
                ? product.addProduct.productpictures.sort(
                      (a, b) => b.mainPage - a.mainPage
                  )[0]?.picLink
                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
            productWidth: product.productWidth,
            productHeight: product.productHeight,
            productDepth: product.productDepth,
            color: product.addProduct.stylecolor.colorNumber,
            shippingMethod: product.shippingMethod,
            doorDirection,
            glassOption,
            depthOption,
            qty: 1,
            promoGroupId: product.promoGroupId,
            productCategory: product.productCategory,
        }
        // console.log(values)
        dispatch(addToCart(values))
        if (shippingOption?.shippingType === 1) {
            dispatch(shippingStage(1))
            dispatch(changeShippingOption(null))
        }
        handleClose()
    }

    const doors = [
        {
            id: 1,
            direction: 'Left',
            icon: 'https://res.cloudinary.com/homespace/image/upload/v1631328853/Icons/left_zn971m.svg',
        },
        {
            id: 2,
            direction: 'Right',
            icon: 'https://res.cloudinary.com/homespace/image/upload/v1631328853/Icons/right_cx23vr.svg',
        },
    ]

    const errorCss = error ? 'product-option-door-select-error' : null

    return (
        <>
            <Backdrop click={handleClose} />
            <div className="product-option-container">
                <div className="product-option-box">
                    <div className="product-option-close-icon">
                        <CloseIcon
                            onClick={handleClose}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>
                    <div className="product-option-name">{product.name}</div>
                    <div className="product-option-price">Price: ${price}</div>
                    {product.doorDirection && (
                        <div className="product-option-door-box">
                            <div className="product-option-door-title">
                                Door Hinge Location{' '}
                                <span className="product-option-door-star">
                                    * {error && 'This is a required field.'}
                                </span>
                            </div>
                            <div
                                className={`product-option-door-select ${errorCss}`}
                            >
                                {doors.map((d) => {
                                    const selectedCss =
                                        door === d.id
                                            ? 'product-option-door-selected'
                                            : null
                                    return (
                                        <div
                                            key={d.direction}
                                            className={`product-option-door-item ${selectedCss}`}
                                            onClick={() => setDoor(d.id)}
                                        >
                                            <img
                                                src={d.icon}
                                                className="product-option-door-icon"
                                                alt="icon"
                                            />
                                            <div>{d.direction}</div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                    {(product.cabinetdepths.length > 0 ||
                        product.glassdoors.length > 0) && (
                        <>
                            <div className="product-option-divider">
                                <Divider />
                            </div>

                            <div className="product-custom-option">
                                <div className="product-custom-option-title">
                                    Custom Options
                                </div>
                                {product.glassdoors.length > 0 && (
                                    <div className="product-custom-option-glass">
                                        <div className="product-custom-option-glass-input">
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="glass-door-select-outlined-label">
                                                    Glass Doors
                                                </InputLabel>
                                                <Select
                                                    labelId="glass-door-select-outlined-label"
                                                    id="glass-door-select-outlined"
                                                    value={glass}
                                                    onChange={(e) =>
                                                        handleGlassChanged(e)
                                                    }
                                                    label="Glass Doors"
                                                >
                                                    <MenuItem value="0&0">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {product.glassdoors.map(
                                                        (glassDoor) => (
                                                            <MenuItem
                                                                key={
                                                                    glassDoor.id
                                                                }
                                                                value={
                                                                    glassDoor.id +
                                                                    '&' +
                                                                    glassDoor.addPrice +
                                                                    '&' +
                                                                    glassDoor.name
                                                                }
                                                            >
                                                                {glassDoor.name}{' '}
                                                                + $
                                                                {
                                                                    glassDoor.addPrice
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                                {product.cabinetdepths.length > 0 && (
                                    <div className="product-custom-option-depth">
                                        <div className="product-custom-option-depth-input">
                                            <FormControl
                                                variant="outlined"
                                                className={classes.formControl}
                                            >
                                                <InputLabel id="depth-door-select-outlined-label">
                                                    Change Cabinet Depth
                                                </InputLabel>
                                                <Select
                                                    labelId="depth-door-select-outlined-label"
                                                    id="depth-door-select-outlined"
                                                    value={depth}
                                                    onChange={(e) =>
                                                        handleDepthChanged(e)
                                                    }
                                                    label="Change Cabinet Depth"
                                                >
                                                    <MenuItem value="0&0">
                                                        <em>None</em>
                                                    </MenuItem>
                                                    {product.cabinetdepths.map(
                                                        (cabinetDepth) => (
                                                            <MenuItem
                                                                key={
                                                                    cabinetDepth.id
                                                                }
                                                                value={
                                                                    cabinetDepth.id +
                                                                    '&' +
                                                                    cabinetDepth.addPrice +
                                                                    '&' +
                                                                    cabinetDepth.name
                                                                }
                                                            >
                                                                {
                                                                    cabinetDepth.name
                                                                }{' '}
                                                                + $
                                                                {
                                                                    cabinetDepth.addPrice
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                    <div className="product-option-button">
                        <button
                            className="product-option-button-style"
                            onClick={() => handleSubmit()}
                        >
                            Add to cart
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProductOption
