import { useEffect } from 'react'
import { Container, Divider } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const RequestSuccess = ({ match }) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const projectId = match.params.projectId
    return (
        <Container>
            <div className="payment-success-box">
                <div className="payment-success-header">Request successful</div>
                <Divider />
                <div className="payment-success-body">
                    <div className="payment-success-left">
                        <CheckCircleIcon style={{ color: '#EDC400' }} />
                    </div>
                    <div className="payment-success-right">
                        <div className="payment-success-body1">
                            Thank you for your request!
                        </div>
                        <div className="payment-success-body2">
                            Our stuff will contact your soon!
                        </div>
                        <div className="payment-success-body3">
                            Your project number: {projectId}
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default RequestSuccess
