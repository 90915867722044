import { useState, useEffect } from 'react'

const useAPI = (api) => {
    const [result, setResult] = useState('')
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState(null)
    useEffect(() => {
        ;(async () => {
            try {
                const { data } = await api
                setResult(data.data)
            } catch (error) {
                console.log(error?.response?.data)
                setErrors(error?.response?.data?.msg)
            }
            setLoading(false)
        })()
    }, [api])
    return [result, loading, errors]
}

export default useAPI
