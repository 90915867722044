import { Container } from '@material-ui/core'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { requestPic } from '../constant/default'
import { getAllRequestDesigns } from '../store/actions/requestDesignActions'
import moment from 'moment'
import history from '../history'
import DesignCenter from '../components/DesignCenter'

const ClientDesign = () => {
    const dispatch = useDispatch()
    const requestDesignState = useSelector((state) => state.requestDesign)
    const { requestDesigns } = requestDesignState

    useEffect(() => {
        dispatch(getAllRequestDesigns())
    }, [dispatch])
    return (
        <Layout>
            {requestDesigns && (
                <>
                    <Container disableGutters={true}>
                        <div className="request-design-box">
                            {requestDesigns.map((list) => (
                                <div
                                    key={list.id}
                                    className="request-design-item"
                                    onClick={() =>
                                        history.push(
                                            `/clientdesigninfo/${list.id}`
                                        )
                                    }
                                >
                                    <img
                                        src={
                                            requestPic(list.buildType)?.buildPic
                                        }
                                        alt="pic"
                                        className="request-design-buildtype-pic"
                                    />
                                    <div className="request-design-item-title">
                                        {
                                            requestPic(list.buildType)
                                                ?.buildTypeName
                                        }
                                    </div>
                                    <div className="request-design-item-date">
                                        {moment(list.createdAt).format('ll')}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <DesignCenter />
                    </Container>
                </>
            )}
        </Layout>
    )
}

export default ClientDesign
