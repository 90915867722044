import { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { measureInchToMm } from '../../constant/formula'

const ProductSize = ({ title, pSizeList }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="product_desc_container">
            <div className="product_desc_box" onClick={() => setOpen(!open)}>
                <div className="product_desc_title">{title}:</div>
                <div className="product_desc_icon">
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </div>
            </div>
            {open && (
                <div className="product_desc_info">
                    <div>
                        <table>
                            <tbody>
                                {pSizeList.map((sizeList) => (
                                    <tr key={sizeList.name}>
                                        <td>
                                            <div>
                                                <strong>
                                                    {sizeList.name}:
                                                </strong>
                                            </div>
                                        </td>
                                        <td>
                                            {sizeList.value}" (
                                            {measureInchToMm(sizeList.value)}{' '}
                                            mm)
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProductSize
