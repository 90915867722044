import Carts from '../components/cart/Carts'
import Layout from '../components/Layout'

const ClientCart = () => {
    return (
        <Layout>
            <Carts />
        </Layout>
    )
}

export default ClientCart
