import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import CheckoutInformationForm from './CheckoutInformationForm'

const CheckoutInformation = () => {
    const [values, setValues] = useState(null)
    const cartState = useSelector((state) => state.cart)
    const { information } = cartState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    useEffect(() => {
        setValues(information ? information : clientInfo ? clientInfo : null)
    }, [information, clientInfo])

    return (
        <div>
            <CheckoutInformationForm initialValues={values} formType="info" />
        </div>
    )
}

export default CheckoutInformation
