import { Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PicturePointDisplay from '../components/picturePoint/PicturePointDisplay'
import history from '../history'
import { getHomePage } from '../store/actions/homePageActions'

const ComingSoon = () => {
    const dispatch = useDispatch()
    const homePageState = useSelector((state) => state.homePage)
    const { homePicturePoints } = homePageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!homePicturePoints) {
            dispatch(getHomePage())
        }
    }, [dispatch, homePicturePoints])

    return (
        <>
            <Container disableGutters={true}>
                <div className="comingsoon-header-container">
                    <div className="comingsoon-header-text-box">
                        <h3>Coming Soon</h3>
                        <p>page coming soon</p>
                        <button onClick={() => history.goBack()}>Back</button>
                    </div>
                </div>

                <div className="home_pic_point_title_area">
                    <div>
                        <h3>Kitchen Inspiration</h3>
                    </div>
                    <div className="home_pic_point_yellow_line"></div>
                </div>
                <div className="home_pic_point_box">
                    {homePicturePoints &&
                        homePicturePoints.map((picturePoint) => (
                            <div
                                className="home_pic_point_item"
                                key={picturePoint.id}
                            >
                                <PicturePointDisplay
                                    picturePoint={picturePoint}
                                    pointColor=""
                                />
                            </div>
                        ))}
                </div>
            </Container>
        </>
    )
}

export default ComingSoon
