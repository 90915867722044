import { GET_ALL_ORDERS, GET_ONE_ORDER } from '../types'

const initialState = {
    orders: null,
    order: null,
}

export const orderReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: payload,
                order: null,
            }
        case GET_ONE_ORDER:
            return {
                ...state,
                order: payload,
            }
        default:
            return state
    }
}

export default orderReducer
