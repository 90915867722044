import BorderColorIcon from '@material-ui/icons/BorderColor'
import DeveloperBoardIcon from '@material-ui/icons/DeveloperBoard'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import PersonIcon from '@material-ui/icons/Person'

export const layoutNavList = [
    {
        name: 'My design',
        id: 1,
        url: '/clientdesign',
        icon: <BorderColorIcon />,
    },
    {
        name: 'My orders',
        id: 2,
        url: '/clientorder',
        icon: <DeveloperBoardIcon />,
    },
    { name: 'My cart', id: 3, url: '/clientcart', icon: <ShoppingCartIcon /> },
    { name: 'My Account', id: 4, url: '/clientaccount', icon: <PersonIcon /> },
]
