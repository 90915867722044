import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GET_ONE_PRODUCT } from '../store/types'
import Container from '@material-ui/core/Container'
import NavHeader from '../components/products/NavHeader'
import { getOneProduct, productOption } from '../store/actions/productsActions'
import ProductDesc from '../components/products/ProductDesc'
import ProductPic from '../components/products/ProductPic'
import { measureInchToMm } from '../constant/formula'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import BuildIcon from '@material-ui/icons/Build'
import ProductSize from '../components/products/ProductSize'
import Recommend from '../components/products/Recommend'
import { Divider } from '@material-ui/core'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../store/actions/cartActions'
import ProductOption from '../components/products/ProductOption'
import { promoGroup } from '../common/promoService'
import React from 'react'

const Product = ({ match }) => {
    const id = match.params.id
    const dispatch = useDispatch()

    const [selectColor, setSelectColor] = useState('')
    const [selectPics, setSelectPics] = useState([])
    const [selectPrice, setSelectPrice] = useState('')
    const [selectSkuNo, setSelectSkuNo] = useState('')
    const [selectStock, setSelectStock] = useState(0)

    const productsState = useSelector((state) => state.products)
    const {
        product,
        similerProducts,
        alsoBought,
        showProductOption,
        optionProduct,
    } = productsState

    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    useEffect(() => {
        window.scrollTo(0, 0)
        dispatch(getOneProduct(id))
        return () => {
            dispatch({
                type: GET_ONE_PRODUCT,
                payload: {
                    product: null,
                    similerProducts: null,
                    alsoBought: null,
                },
            })
        }
    }, [dispatch, id])

    const [productSizeList, setProductSizeList] = useState([])
    const [packageSizeList, setPackageSizeList] = useState([])

    useEffect(() => {
        if (product) {
            setSelectPics(product?.productcolors[0]?.productpictures)
            setSelectColor(product?.productcolors[0]?.stylecolor?.id)
            setSelectPrice(product?.productcolors[0]?.price)
            setSelectSkuNo(product?.productcolors[0]?.skuNo)
            setSelectStock(product?.productcolors[0]?.stock)

            let productSizeListM = []
            let packageSizeListM = []
            if (product.productmeasures && product.productmeasures.length > 0) {
                productSizeListM = product.productmeasures.map((size) => {
                    return {
                        id: size.id,
                        name: size.name,
                        value: size.productSize,
                    }
                })
                packageSizeListM = product.productmeasures.map((size) => {
                    return {
                        id: size.id,
                        name: size.name,
                        value: size.packageSize,
                    }
                })
            }

            const productSizeListP = [
                { name: 'Width', value: product.productWidth },
                { name: 'Height', value: product.productHeight },
                { name: 'Depth', value: product.productDepth },
            ]

            const packageSizeListP = [
                { name: 'Width', value: product.packageWidth },
                { name: 'Height', value: product.packageHeight },
                { name: 'Depth', value: product.packageDepth },
            ]

            setProductSizeList([...productSizeListP, ...productSizeListM])
            setPackageSizeList([...packageSizeListP, ...packageSizeListM])
        }
    }, [product])

    const handleColorChanged = (id) => {
        const selectProductColor = product.productcolors.filter(
            (color) => color.stylecolor.id === id
        )[0]

        setSelectPics(selectProductColor?.productpictures)
        setSelectColor(id)
        setSelectPrice(selectProductColor?.price)
        setSelectSkuNo(selectProductColor?.skuNo)
        setSelectStock(selectProductColor?.stock)
    }

    const handleAddToCard = () => {
        const addProduct = product.productcolors.filter(
            (color) => color.stylecolor.id === selectColor
        )[0]
        const promoGroupId = promoGroup(product)
        const productCategory = product.style.category.slug
        if (
            product.style.category.productOption &&
            (product.doorDirection ||
                product.glassdoors.length > 0 ||
                product.cabinetdepths.length > 0)
        ) {
            const productSelect = {
                addProduct,
                name: product.name,
                promoGroupId,
                productCategory,
                doorDirection: product.doorDirection,
                glassdoors: product.glassdoors,
                cabinetdepths: product.cabinetdepths,
                productWidth: product.productWidth,
                productHeight: product.productHeight,
                productDepth: product.productDepth,
                shippingMethod: product.style.category.shippingMethod,
            }
            dispatch(productOption(true, productSelect))
        } else {
            const values = {
                id: `${addProduct.id}-0-0-0`,
                productColorId: addProduct.id,
                name: product.name,
                skuNo: addProduct.skuNo,
                stock: addProduct.stock,
                basePrice: addProduct.price,
                price: addProduct.price,
                picLink: addProduct.productpictures.sort(
                    (a, b) => b.mainPage - a.mainPage
                )[0]?.picLink
                    ? addProduct.productpictures.sort(
                          (a, b) => b.mainPage - a.mainPage
                      )[0]?.picLink
                    : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
                productWidth: product.productWidth,
                productHeight: product.productHeight,
                productDepth: product.productDepth,
                color: addProduct.stylecolor.colorNumber,
                shippingMethod: product.style.category.shippingMethod,
                doorDirection: '',
                glassOption: '',
                depthOption: '',
                qty: 1,
                promoGroupId,
                productCategory,
            }

            dispatch(addToCart(values))
            if (shippingOption?.shippingType === 1) {
                dispatch(changeShippingOption(null))
                dispatch(shippingStage(1))
            }
        }
    }

    return product ? (
        <div className="product_container">
            <Container disableGutters={true}>
                {showProductOption && <ProductOption product={optionProduct} />}
                <NavHeader product={product} />
                <div className="product_top">
                    <div className="product_pic">
                        <ProductPic selectPics={selectPics} />
                    </div>
                    <div className="product_info">
                        <div className="product_info_top">
                            <div className="product_info_name">
                                {product.name}
                            </div>
                            <div className="product_info_price">
                                ${selectPrice}
                            </div>
                        </div>
                        <div className="product_info_size">
                            {product.productWidth}" W x {product.productHeight}"
                            H x {product.productDepth}" D (
                            {measureInchToMm(product.productWidth)}x
                            {measureInchToMm(product.productHeight)}x
                            {measureInchToMm(product.productDepth)} mm)
                        </div>
                        <div className="product_info_skuno">
                            Article Number: {selectSkuNo}
                        </div>
                        <div className="product_info_available">
                            {selectStock > 0 ? (
                                <div className="product_info_available_in">
                                    In stock
                                </div>
                            ) : (
                                <div className="product_info_available_out">
                                    Out of stock
                                </div>
                            )}
                        </div>
                        <div className="product_info_color">
                            <p>
                                Color:{' '}
                                {
                                    product?.productcolors?.filter(
                                        (color) =>
                                            color.stylecolor.id === selectColor
                                    )[0]?.stylecolor?.colorName
                                }
                            </p>
                            <div className="product_info_color_box">
                                {product.productcolors.map((color) => (
                                    <div
                                        key={color.id}
                                        onClick={() =>
                                            handleColorChanged(
                                                color.stylecolor.id
                                            )
                                        }
                                        className="product_info_color_item"
                                        style={
                                            selectColor === color.stylecolor.id
                                                ? {
                                                      border: '1px solid #000',
                                                      background:
                                                          color.stylecolor
                                                              .colorNumber,
                                                  }
                                                : {
                                                      background:
                                                          color.stylecolor
                                                              .colorNumber,
                                                  }
                                        }
                                    ></div>
                                ))}
                            </div>
                        </div>
                        <div className="product_info_add_button">
                            <button
                                onClick={() => handleAddToCard()}
                                disabled={selectStock <= 0}
                                style={
                                    selectStock <= 0
                                        ? { cursor: 'not-allowed' }
                                        : { cursor: 'pointer' }
                                }
                            >
                                {selectStock <= 0
                                    ? 'Out of stock'
                                    : 'Add to cart'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className="product_bottom">
                    <div className="product_bottom_left">
                        <ProductDesc
                            title="Description"
                            info={product.description}
                        />
                        <ProductDesc
                            title="Materials and care"
                            info={product.materialsAndCare}
                        />
                        <ProductSize
                            title="Product size"
                            pSizeList={productSizeList}
                        />
                        <ProductSize
                            title="Package size"
                            pSizeList={packageSizeList}
                        />
                        <ProductDesc title="Warranty" info={product.warranty} />
                        <ProductDesc
                            title="What's included in package"
                            info={product.whatInPackage}
                        />
                    </div>
                    <div className="product_bottom_right">
                        <div className="product_bottom_right_word">
                            <LocalShippingIcon
                                style={{
                                    color: '#EDC400',
                                    marginRight: '10px',
                                }}
                            />{' '}
                            You will see delivery options at checkout
                        </div>
                        <div className="product_bottom_right_word">
                            {' '}
                            <BuildIcon
                                style={{
                                    color: '#EDC400',
                                    marginRight: '10px',
                                }}
                            />
                            You will receive a fully-assembled product
                        </div>
                        {product.pdfLink && (
                            <div className="product_bottom_right_button">
                                <a
                                    href={`${product.pdfLink}`}
                                    target="_black"
                                    style={{ textDecoration: 'none' }}
                                >
                                    <button>
                                        Download PDF Installtion Manual
                                    </button>
                                </a>
                            </div>
                        )}
                    </div>
                </div>
            </Container>
            <Container disableGutters={true}>
                <div
                    className="toolbar_divider_full"
                    style={{ marginTop: '30px', marginBottom: '20px' }}
                >
                    <Divider />
                </div>
                <div className="product_similer_title">Similer products:</div>
                {similerProducts && (
                    <Recommend recommendLists={similerProducts} />
                )}
            </Container>
            <Container disableGutters={true}>
                <Divider style={{ marginTop: '30px', marginBottom: '20px' }} />
                <div className="product_similer_title">
                    Customers also bought:
                </div>
                {alsoBought && <Recommend recommendLists={alsoBought} />}
            </Container>
        </div>
    ) : (
        ''
    )
}

export default Product
