import { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { renderInput } from '../components/ReduxFormInputs'
import { Container, Divider } from '@material-ui/core'
import ReCAPTCHA from 'react-google-recaptcha'
import { resetPassword } from '../store/actions/clientActions'

const ResetPassword = (props) => {
    const token = props.match.params.token
    const dispatch = useDispatch()
    const recaptchaRef = useRef()
    const { handleSubmit } = props
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        formValues['token'] = token
        dispatch(resetPassword(formValues))
    }

    return (
        <>
            <div className="divider_mobile">
                <Divider />
            </div>
            <Container disableGutters={true} maxWidth="xs">
                <div className="login-box">
                    <div className="login-title">NEW PASSWORD</div>
                    <form
                        onSubmit={handleSubmit(submitForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Field
                            name="password"
                            type="password"
                            label="New password"
                            max="20"
                            component={renderInput}
                        ></Field>
                        <Field
                            name="rPassword"
                            type="password"
                            label="Confirm New password"
                            max="20"
                            component={renderInput}
                        ></Field>
                        <div className="google-robert">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                size="invisible"
                                sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                            />
                        </div>
                        <div className="login-button-box">
                            <button type="submit" className="login-button">
                                Reset
                            </button>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['password', 'rPassword']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    if (values.password) {
        if (
            !/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(
                values.password
            )
        ) {
            errors.password =
                'Password must consist of letters and numbers at least 8'
        }
    }

    if (values.rPassword) {
        if (values.password !== values.rPassword) {
            errors.rPassword = 'Not match'
            errors.password = 'Not match'
        }
    }

    return errors
}

export default reduxForm({
    form: 'resetPasswordForm',
    validate,
})(ResetPassword)
