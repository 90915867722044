import CheckoutCart from '../components/checkout/CheckoutCart'
import CheckoutStage from '../components/checkout/CheckoutStage'
import CheckoutTotal from '../components/checkout/CheckoutTotal'
import Layout from '../components/Layout'

const ClientCheckOut = () => {
    return (
        <Layout>
            <div className="client-checkout-box">
                <div className="client-checkout-info">
                    <CheckoutStage />
                </div>
                <div className="checkout-cart">
                    <div className="checkout-cart-area">
                        <CheckoutCart />
                    </div>
                    <div className="checkout-total-area">
                        <CheckoutTotal />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default ClientCheckOut
