import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllAboutUs } from '../store/actions/aboutUsActions'
import { Divider } from '@material-ui/core'
import PhoneIcon from '@material-ui/icons/Phone'
import LocationOnIcon from '@material-ui/icons/LocationOn'
// import MailIcon from '@material-ui/icons/Mail'
import { useCurrentWidth } from '../components/screenWidth'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import SwiperCore, { Pagination, Navigation } from 'swiper/core'

SwiperCore.use([Pagination, Navigation])

const PersonList = ({ person }) => {
    return (
        <div className="aboutus_team_person">
            <div className="aboutus_team_person_avatar">
                <img src={person.avatar} alt="avatar" />
            </div>
            <div className="aboutus_team_person_name">{person.name}</div>
            <div className="aboutus_team_person_title">{person.title}</div>
        </div>
    )
}

const AboutUs = () => {
    const dispatch = useDispatch()
    const aboutUsState = useSelector((state) => state.aboutUs)
    const { header, headOffices, teamMembers } = aboutUsState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!header || !headOffices || !teamMembers) {
            dispatch(getAllAboutUs())
        }
    }, [dispatch, header, headOffices, teamMembers])
    const screenWidth = useCurrentWidth()

    return (
        <>
            {header && (
                <Container disableGutters={true}>
                    <div
                        className="aboutus_header"
                        style={{
                            background: `url(${header.bigPicLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="aboutus_slogan">
                            <div className="aboutus_slogan_word">
                                {header.slogan}
                            </div>
                        </div>
                    </div>
                </Container>
            )}
            <Container disableGutters={true}>
                <div className="aboutus_gettoknow">
                    <div className="aboutus_gettoknow_content">
                        <div className="home_pic_point_title_area">
                            <div>
                                <h1>Welcome to Hspace Kitchen and Bath!</h1>
                            </div>
                            <div className="home_pic_point_yellow_line"></div>
                        </div>

                        <p>
                            We are your premier destination for kitchen design
                            and cabinetry, offering a seamless, personalized
                            experience. With over 40 years of expertise in
                            cabinetry across North America and thousands of
                            satisfied clients, we know how to make your kitchen
                            truly exceptional.
                        </p>
                        <p>
                            Explore our collection of ready-to-install cabinets,
                            expertly crafted for quality and style. Our seasoned
                            designers are dedicated to helping you create the
                            kitchen of your dreams
                        </p>
                        <p>
                            We stand by our best-price guarantee, ensuring you
                            receive unbeatable deals with complete price
                            transparency. Once your order is placed, you can
                            count on fast, reliable shipping of fully assembled
                            cabinets. No long waits—our warehouse is stocked
                            with a wide selection of cabinets, ready for you to
                            choose from.
                        </p>
                        <p>
                            Call us at 1-833-770-7077 or click "Free
                            Consultation" to connect with one of our experts.
                            We're here to make your kitchen renovation simple
                            and enjoyable!
                        </p>
                    </div>
                </div>
            </Container>
            {headOffices && (
                <Container disableGutters={true}>
                    {headOffices.map((headOffice) => (
                        <div key={headOffice.id}>
                            <div className="aboutus_office_box">
                                <div className="aboutus_office_title">
                                    <div className="aboutus_office_title_word">
                                        {headOffice.location}
                                    </div>
                                    <div className="aboutus_office_title_line"></div>
                                </div>
                                <div className="aboutus_info">
                                    <div className="aboutus_info_word">
                                        <PhoneIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.phone}
                                    </div>
                                    <div className="aboutus_info_word">
                                        <LocationOnIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.address}
                                    </div>
                                    {/* <div className="aboutus_info_word">
                                        <MailIcon
                                            style={{ marginRight: '10px' }}
                                        />{' '}
                                        {headOffice.email}
                                    </div> */}
                                    <div className="aboutus_info_word">
                                        <a href={`tel:${headOffice.phone}`}>
                                            <button className="aboutus_info_word_button">
                                                Contact us
                                            </button>
                                        </a>
                                    </div>
                                </div>
                                <div className="aboutus_office_map">
                                    <a
                                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                                            headOffice?.address.includes(
                                                'Mississauga'
                                            )
                                                ? 'hspace kitchen & bath '
                                                : '' + headOffice?.address
                                        )}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <img
                                            // src={`https://www.mapquestapi.com/staticmap/v5/map?key=${process.env.REACT_APP_MAPQUEST_KEY}&center=${headOffice.address}&size=300,200@2x`}
                                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${encodeURIComponent(
                                                headOffice.address
                                            )}&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C${encodeURIComponent(
                                                headOffice.address
                                            )}&key=${
                                                process.env
                                                    .REACT_APP_GOOGLE_MAPS_API_KEY
                                            }`}
                                            alt="map"
                                            height="250px"
                                            style={{ objectFix: 'contain' }}
                                        />
                                    </a>
                                </div>
                            </div>

                            <Divider />
                        </div>
                    ))}
                </Container>
            )}
            {teamMembers && (
                <Container disableGutters={true}>
                    <div className="aboutus_team_box">
                        <div className="aboutus_team_title">Meet our team</div>
                        {screenWidth > 768 ? (
                            <div className="aboutus_team_person_area">
                                {teamMembers.map((member) => (
                                    <PersonList
                                        key={member.id}
                                        person={member}
                                    />
                                ))}
                            </div>
                        ) : (
                            <Swiper
                                slidesPerView={1}
                                spaceBetween={0}
                                slidesPerGroup={1}
                                loop={true}
                                loopFillGroupWithBlank={true}
                                navigation={true}
                                className="mySwiper"
                            >
                                {teamMembers?.map((member) => (
                                    <SwiperSlide key={member.id}>
                                        <PersonList person={member} />
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        )}
                    </div>
                </Container>
            )}
        </>
    )
}

export default AboutUs
