import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllCabinetCare } from '../store/actions/cabinetCareActions'

const CabinetCare = () => {
    const dispatch = useDispatch()
    const cabinetCareState = useSelector((state) => state.cabinetCare)
    const { style } = cabinetCareState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!style) {
            dispatch(getAllCabinetCare())
        }
    }, [dispatch, style])
    return (
        <>
            {style && (
                <Container disableGutters={true}>
                    <div
                        className="warranty_style_title"
                        style={{
                            background: `url(${style.picLink})`,
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div className="warranty_style_slogan">
                            <div className="warranty_style_slogan_word">
                                {style.slogan}
                            </div>
                        </div>
                    </div>
                </Container>
            )}

            <Container disableGutters={true}>
                <div className="cabinetcare_info_container">
                    <div className="cabinetcare_info_box">
                        <div className="cabinetcare_info_title">
                            Maintenance & Care
                        </div>
                        <div className="cabinetcare_info_detail">
                            When our customers are looking to upgrade their home
                            with beautiful new cabinetry, they are not only
                            looking for designs and styles that complements
                            their lifestyle, but they also want their new
                            investment to stand the test of time. The kitchen is
                            a busy place, so there are a few simple things you
                            can do to protect your cabinetry and maintain their
                            appearance.
                        </div>
                        <div className="cabinetcare_info_detail">
                            Dust is unavoidable in any home, therefore it is
                            important to dust and/or vacuum your cabinetry on a
                            regular basis. When dusting, however, we recommend
                            you used a cloth that is moistened with water or
                            polish, and wipe following the natural grain of the
                            wood, not against. Wiping against the grain may
                            cause scratches should the dust contain grit.
                        </div>
                        <div className="cabinetcare_info_detail">
                            In any kitchen, there is going to be residue, such
                            as splattered food, oil, or smoke, from every day
                            use. Residue should be wiped up with a damp cloth or
                            a cloth containing a small amount of furniture
                            polish. Cloths that are great for cleaning your
                            cabinetry include clean dish towels, cheesecloth, or
                            old t-shirts that are free from buttons or snaps
                            that can cause scratches.
                        </div>
                        <div className="cabinetcare_info_title">
                            More Details
                        </div>
                        <div className="cabinetcare_info_split">
                            <div className="cabinetcare_info_split_pic">
                                <img
                                    src="https://res.cloudinary.com/home-space/image/upload/v1645971891/Support/CabinetCare/wiping-cabinet-surface-scaled_apqgv3.jpg"
                                    width="100%"
                                    alt="care"
                                />
                            </div>
                            <div className="cabinetcare_info_split_word">
                                <div className="cabinetcare_info_header">
                                    01 Basic Cleaning:
                                </div>
                                <ul>
                                    <li>
                                        Make sure the cloth that you are using
                                        to dust or wipe up stains or spills is
                                        soft, lint-free, and dampened with mild
                                        detergent, polish, or soap and warm
                                        water.
                                    </li>
                                    <li>
                                        Don’t clean against the grain, and for
                                        best results, use a “blotting” motion
                                        rather than a “wiping” motion.
                                    </li>
                                    <li>
                                        If surfaces get wet, dry immediately
                                        with a soft, lint-free cloth.
                                    </li>
                                    <li>
                                        If possible, avoid using a dish cloth or
                                        sponge, as detergent residue left over
                                        can harm finishes.
                                    </li>
                                    <li>
                                        Avoid using ammonia-based cleaners and
                                        soaps with dye.
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="cabinetcare_info_split_reverse">
                            <div className="cabinetcare_info_split_word">
                                <div className="cabinetcare_info_header">
                                    02 Cleaning of Glass Door Inserts:
                                </div>
                                <ul>
                                    <li>
                                        For more delicate parts of your
                                        cabinets, such as glass door inserts,
                                        use a household glass cleaner with a
                                        soft, clean cloth.
                                    </li>
                                    <li>
                                        Apply the solution to the cloth rather
                                        than on the glass to avoid excess glass
                                        cleaner dripping down into the cabinet
                                        joints.
                                    </li>
                                    <li>
                                        Never use full strength ammonia. Always
                                        dilute.
                                    </li>
                                </ul>
                            </div>
                            <div className="cabinetcare_info_split_pic">
                                <img
                                    src="https://res.cloudinary.com/home-space/image/upload/v1645971890/Support/CabinetCare/wiping-cabinets_trv4oc.jpg"
                                    width="100%"
                                    alt="care"
                                />
                            </div>
                        </div>
                        <div className="cabinetcare_info_split">
                            <div className="cabinetcare_info_split_pic">
                                <img
                                    src="https://res.cloudinary.com/home-space/image/upload/v1645971890/Support/CabinetCare/dusting-cabinets_isi3rq.jpg"
                                    width="100%"
                                    alt="care"
                                />
                            </div>
                            <div className="cabinetcare_info_split_word">
                                <div className="cabinetcare_info_header">
                                    03 Basic Care & Things to Avoid:
                                </div>
                                <p>
                                    To ensure that your cabinetry not only stays
                                    with you during the many phases of life, but
                                    also remains timeless in its appearance,
                                    there are a few daily practises that you can
                                    do to help care for your cabinets long-term.
                                </p>
                                <p>
                                    Caring for your new cabinetry doesn’t have
                                    to be time-consuming. In fact, a little care
                                    can go a long way. That being said, being
                                    consistent and proactive is your best
                                    defense against time and “wear and tear”
                                    when it comes to your cabinets.
                                </p>
                                <h4>Just remember the three NO’s:</h4>
                                <ul>
                                    <li>No Spill Left Uncleaned</li>
                                    <li>No Moisture & Heat</li>
                                    <li>No Abrasives</li>
                                </ul>
                            </div>
                        </div>
                        <div className="cabinetcare_info_title">
                            Basic Care Tips:
                        </div>

                        <div className="cabinetcare_info_detail">
                            <p style={{ textDecoration: 'underline' }}>
                                If You Spill It, Clean It
                            </p>
                            <ul>
                                <li>
                                    If something is spilled, wipe it up
                                    promptly. Food spills, especially grease and
                                    oil, will come off much easier if removed
                                    right away. Water rings, spots, and spills
                                    need to be wiped up with a clean, lint-free
                                    cotton cloth to stop the moisture from
                                    leaking into the cabinetry.
                                </li>
                                <li>
                                    Use trays under potted plants, and check the
                                    areas around the sink, dishwasher, and any
                                    other appliance that uses water to operate
                                    to ensure there are no leaks and water is
                                    not drying on the cabinetry.
                                </li>
                            </ul>
                        </div>
                        <div className="cabinetcare_info_detail">
                            <p style={{ textDecoration: 'underline' }}>
                                Say No to Moisture & Heat
                            </p>
                            <ul>
                                <li>
                                    Avoid keeping damp dish towels or dishcloths
                                    on or over doors of the base of the cabinets
                                    for any length of time.
                                </li>
                                <li>
                                    Avoid using appliances that produces heat or
                                    steam, such as a tea kettle, that is
                                    directed toward any cabinetry surface.
                                </li>
                                <li>
                                    If you are resting something down that is
                                    hot, put a hot pad down first for
                                    protection.
                                </li>
                            </ul>
                        </div>
                        <div className="cabinetcare_info_detail">
                            <p style={{ textDecoration: 'underline' }}>
                                Goodbye Abrasives
                            </p>
                            <ul>
                                <li>
                                    Using scouring pads, steel wool, wire
                                    brushes, and powdered cleaners is the
                                    fastest way to scratch your new cabinetry.
                                </li>
                                <li>
                                    Oven cleaner is also extremely damaging, so
                                    avoid letting it touch any part of your
                                    cabinets.
                                </li>
                                <li>
                                    Do not slide anything across the surface of
                                    your cabinets.
                                </li>
                                <li>
                                    Use a cutting board when using knifes or
                                    other sharp objects.
                                </li>
                                <li>
                                    When in doubt, DON’T! Harsh cleaning
                                    chemicals will not only destroy the finish,
                                    but also penetrate the surface to allow
                                    moisture and food inside.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default CabinetCare
