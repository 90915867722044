import PicturePointItemDisplay from './PicturePointItemDisplay'

const PicturePointDisplay = ({
    picturePoint,
    pointColor,
    noName = '',
    samePage = '',
}) => {
    return (
        <div className="picture-point-area">
            {!noName && (
                <div
                    className="picture-point-name"
                    style={{
                        color: picturePoint.nameColor,
                    }}
                >
                    <h3>{picturePoint.name}</h3>
                </div>
            )}
            {picturePoint.picturepointitems &&
                picturePoint.picturepointitems.length > 0 &&
                picturePoint.picturepointitems.map((picturePointItem) => (
                    <PicturePointItemDisplay
                        key={picturePointItem.id}
                        picturePointItem={picturePointItem}
                        background={pointColor}
                        samePage={samePage}
                    />
                ))}
            <img src={picturePoint.picLink} alt="Point" width="100%" />
        </div>
    )
}

export default PicturePointDisplay
