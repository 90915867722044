import { Container } from '@material-ui/core'
import { useEffect } from 'react'
import DesignCenter from '../components/DesignCenter'
import history from '../history'

const itemList = [
    {
        id: 1,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1652324864/Home_page/Compare_Prices_hwki7k.png',
        title: 'Compare Prices',
        subTitle:
            'Find a lower advertised price on an equivalent quality product.',
    },
    {
        id: 2,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1652324863/Home_page/Sign_in_p2a7nd.png',
        title: 'Sign In',
        subTitle:
            'Sign in to submit your "Best Price Guarantee" questionnaire.',
    },
    {
        id: 3,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1652324863/Home_page/Include_the_Details_lwatt2.png',
        title: 'Include The Details',
        subTitle:
            'Be sure to include the competitor’s details listed below in your email.',
    },
]

const BestPrice = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="bpg-header">
                <div className="bpg-header-title">
                    <h1>Best Price Guarantee</h1>
                    <p>
                        Found a competitor that charges less? Let us know and
                        we’ll beat their prices by 8% on all our premium
                        products!
                    </p>
                </div>
            </div>
            <Container disableGutters={true} style={{ paddingTop: '30px' }}>
                <div className="home_pic_point_title_area">
                    <div>
                        <h3>How It Works</h3>
                    </div>
                    <div className="home_pic_point_yellow_line"></div>
                </div>
                <div className="bpg_items_container">
                    {itemList.map((item) => (
                        <div key={item.id} className="bpg_items_box">
                            <img src={item.picLink} alt="background" />
                            <h4>{item.title}</h4>
                            <p>{item.subTitle}</p>
                        </div>
                    ))}
                </div>
                <div className="bpg-button-container">
                    <button
                        className="bpg-button"
                        onClick={() => history.push('/pricematch')}
                    >
                        Sign In With Us
                    </button>
                </div>
            </Container>
            <DesignCenter />
        </>
    )
}

export default BestPrice
