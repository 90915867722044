import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_PLANNER_PAGE } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllPlannerPages = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/planner/plannerpage')
        dispatch({
            type: GET_ALL_PLANNER_PAGE,
            payload: {
                styles: data.data.styles,
            },
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
