import { useState } from 'react'
import Backdrop from '../backDrop/Backdrop'
import { useDispatch } from 'react-redux'
import { setSearchBarStatus } from '../../store/actions/searchBarActions'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import history from '../../history'
import request from '../../config/request'

const SearchBar = () => {
    const dispatch = useDispatch()
    const [search, setSearch] = useState('')
    const [result, setResult] = useState(null)

    const handleSubmit = (e) => {
        e.preventDefault()
        if (search) {
            dispatch(setSearchBarStatus(false))
            history.push(`/search/${search}`)
        }
    }

    const handleChange = async (e) => {
        const value = e.target.value
        setSearch(value)
        if (value) {
            const values = { keyword: value }
            try {
                const res = await request.post(
                    '/client/products/search',
                    values
                )
                setResult(res.data.data)
            } catch (e) {
                console.log(e)
            }
        } else {
            setResult(null)
        }
    }

    return (
        <>
            <Backdrop click={() => dispatch(setSearchBarStatus(false))} />
            <div className="search_bar_box">
                <div className="search_bar_area">
                    <form onSubmit={handleSubmit}>
                        <TextField
                            id="demo2"
                            placeholder="What are you looking for?"
                            fullWidth
                            className="search_bar_text"
                            value={search}
                            autoFocus
                            onChange={(e) => handleChange(e)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon
                                            style={{ color: '#edc400' }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </form>
                </div>
                <div className="search_bar_result">
                    {result &&
                        result.map((product) => (
                            <div key={product.id} className="search_bar_list">
                                <div className="search_bar_list_img">
                                    <img src={product.picLink} alt="Product" />
                                </div>
                                <div
                                    className="search_bar_list_name"
                                    onClick={() => {
                                        dispatch(setSearchBarStatus(false))
                                        history.push(`/product/${product.slug}`)
                                    }}
                                >
                                    {product.name}
                                </div>
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default SearchBar
