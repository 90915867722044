import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { reduxForm, Field } from 'redux-form'
import { renderInput } from '../components/ReduxFormInputs'
import { Container, Divider } from '@material-ui/core'
import request from '../config/request'
import { getErrors } from '../store/actions/errorActions'
import history from '../history'

const TrackOrder = (props) => {
    const dispatch = useDispatch()
    const { handleSubmit } = props

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    const submitForm = async (formValues) => {
        try {
            let res = await request.post(`client/order/trackorder`, formValues)
            history.push(`/trackorderinfo/${res.data.data}`)
        } catch (e) {
            const errors = {
                msg: e?.response?.data?.msg,
            }
            dispatch(getErrors(errors?.msg))
        }
    }
    return (
        <>
            <div className="divider_mobile">
                <Divider />
            </div>
            <Container disableGutters={true} maxWidth="xs">
                <div className="login-box">
                    <div className="login-title">TRACK MY ORDER</div>

                    <form
                        onSubmit={handleSubmit(submitForm)}
                        noValidate
                        autoComplete="off"
                    >
                        <Field
                            name="orderNo"
                            type="text"
                            label="Order Number"
                            max="100"
                            component={renderInput}
                        ></Field>
                        <Field
                            name="phone"
                            type="text"
                            label="Phone Number"
                            max="30"
                            component={renderInput}
                        ></Field>
                        <Field
                            name="email"
                            type="text"
                            label="Your email"
                            max="100"
                            component={renderInput}
                        ></Field>

                        <div className="login-button-box">
                            <button type="submit" className="login-button">
                                Continoue
                            </button>
                        </div>
                    </form>
                </div>
            </Container>
        </>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = ['email', 'phone', 'orderNo']
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })
    if (values.email) {
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(values.email)) {
            errors.email = 'Invalid email address'
        }
    }
    if (values.phone) {
        if (
            !/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                values.phone
            )
        ) {
            errors.phone = 'Invalid Phone number'
        }
    }
    return errors
}

export default reduxForm({
    form: 'trackOrderForm',
    validate,
})(TrackOrder)
