import { useSelector } from 'react-redux'
import moment from 'moment'
const CheckoutDisplayOption = () => {
    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    const fromDate = new Date()
    fromDate.setDate(fromDate.getDate() + 3)
    const displayFromDate = moment(fromDate).format('YYYY-MM-DD')

    const toDate = new Date()
    toDate.setDate(toDate.getDate() + 10)
    const displayToDate = moment(toDate).format('YYYY-MM-DD')

    return (
        <div>
            Estimated{' '}
            {shippingOption?.shippingType === 1 ? 'Delivery' : 'Pick Up'} on:{' '}
            {displayFromDate} - {displayToDate}
        </div>
    )
}

export default CheckoutDisplayOption
