import Container from '@material-ui/core/Container'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import history from '../history'

const useStyles = makeStyles((theme) => ({
    divider: {
        margin: '30px 0',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
}))

const PageEndButton = ({ buttonTitle, icon = '' }) => {
    const classes = useStyles()
    return (
        <Container disableGutters={true}>
            <Divider className={classes.divider} />
            <div className="page_end_button">
                <div
                    className="page_end_button_box"
                    onClick={() => history.push('/sample/cabinet-sample')}
                >
                    {icon && <div className="page_end_button_icon">{icon}</div>}
                    <div className="page_end_button_title">{buttonTitle}</div>
                </div>
            </div>
            <div className="page_end_button_mobile">
                <Divider className={classes.divider} />
            </div>
        </Container>
    )
}

export default PageEndButton
