import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

import SwiperCore, { Navigation } from 'swiper/core'

SwiperCore.use([Navigation])

const ProductPic = ({ selectPics }) => {
    return (
        <div>
            <Swiper navigation={true} className="mySwiper">
                {selectPics.length > 0 ? (
                    selectPics.map((pic) => (
                        <SwiperSlide key={pic.id}>
                            <img src={pic.picLink} alt="Product" />
                        </SwiperSlide>
                    ))
                ) : (
                    <SwiperSlide>
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png"
                            alt="product not available"
                        />
                    </SwiperSlide>
                )}
            </Swiper>
        </div>
    )
}

export default ProductPic
