import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container } from '@material-ui/core'
import HelpingTools from '../components/helpingTools/HelpingTools'
import RequestDesignForm from '../components/requestDesign/RequestDesignForm'
import { plannerHelpingList } from '../constant/toolsList'

const ClientRequestDesign = () => {
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    const [values, setValues] = useState(null)

    useEffect(() => {
        const initValues = clientInfo
            ? {
                  firstName: clientInfo.firstName,
                  lastName: clientInfo.lastName,
                  email: clientInfo.email,
                  phone: clientInfo.phone,
              }
            : null
        setValues(initValues)
    }, [clientInfo])

    return (
        <>
            <Container disableGutters={true}>
                <h2 className="request-design-title">
                    What do you try to build?
                </h2>
                <RequestDesignForm
                    initialValues={values}
                    clientId={clientInfo?.id}
                />
            </Container>
            <HelpingTools helpingList={plannerHelpingList} />
        </>
    )
}

export default ClientRequestDesign
