import { Container } from '@material-ui/core'
import PaymentSuccess from '../components/payment/PaymentSuccess'

const GuestPaymentSuccess = ({ match }) => {
    const orderId = match.params.orderId
    const typeId = match.params.typeId
    return (
        <Container disableGutters={true}>
            <PaymentSuccess orderId={orderId} typeId={typeId} />
        </Container>
    )
}

export default GuestPaymentSuccess
