import { Container } from '@material-ui/core'
import React, { useMemo } from 'react'
import Loader from '../components/Loader'
import PaymentForm from '../components/paymentCharge/PaymentForm'
import PaymentOrderDetails from '../components/paymentCharge/PaymentOrderDetails'
import useInvoice from '../hooks/useInvoice'

const PaymentCharge = ({ match }) => {
    const id = match.params.id
    const [result, loading] = useInvoice(id)

    const amountDue = useMemo(() => {
        if (result.invoice)
            return (
                result.invoice.amount -
                (result.invoice?.orderpayments?.reduce(
                    (totalPayment, payment) => {
                        if (payment.successful)
                            return totalPayment + payment.amount
                        return totalPayment
                    },
                    0
                ) || 0)
            ).toFixed(2)
        return 0
    }, [result.invoice])

    const initialValues = useMemo(() => {
        if (result.invoice) {
            const billingInfo = result.invoice.order.orderaddresses.find(
                (address) => address.addressType === 2
            )
            const values = {
                paymentName: `${billingInfo.firstName} ${billingInfo.lastName}`,
                address: billingInfo.address,
                city: billingInfo.city,
                province: billingInfo.province,
                postCode: billingInfo.postCode,
                amount: amountDue,
                country: 'CA',
                expiryMonth: new Date().getMonth() + 1,
                expiryYear: parseInt(
                    new Date().getFullYear().toString().substr(-2)
                ),
            }
            return values
        }
        return null
    }, [result.invoice, amountDue])
    if (loading) return <Loader />

    return (
        <Container>
            {result && (
                <>
                    <h3 className="paymentCharge-header">
                        Request for Payment from HSPACE KITCHEN AND BATH
                    </h3>
                    <div className="paymentCharge-subtitle">
                        <div className="invoice-information">
                            Invoice {result.invoice.invoiceNo}
                        </div>
                        <div className="readonly-payment-information__details__items--separator"></div>
                        <div className="invoice-information">
                            Amount due ${amountDue}
                        </div>
                        <div className="readonly-payment-information__details__items--separator"></div>
                        <div className="invoice-information">
                            Due on {result.invoice.dueDate}
                        </div>
                    </div>
                    {amountDue > 0 ? (
                        <PaymentForm
                            amountDue={amountDue}
                            initialValues={initialValues}
                            invoiceId={result.invoice.id}
                            orderId={result.invoice.order.id}
                        />
                    ) : null}
                    <PaymentOrderDetails order={result.order} />
                </>
            )}
        </Container>
    )
}

export default PaymentCharge
