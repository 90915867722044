import { useDispatch } from 'react-redux'
import { PICTURE_POINT_YELLOW, POSITION_LEFT } from '../../constant/default'
import history from '../../history'
import { clearProducts } from '../../store/actions/productsActions'

const PicturePointItemDisplay = ({
    picturePointItem,
    background = '',
    samePage,
}) => {
    const dispatch = useDispatch()
    return (
        <div
            className="picture-point"
            style={{
                left: picturePointItem.xCoordinate + '%',
                top: picturePointItem.yCoordinate + '%',
            }}
            onClick={() => {
                if (!samePage) {
                    dispatch(clearProducts())
                }
                history.push(
                    `/products/${picturePointItem?.style?.category?.slug}/${picturePointItem?.style?.slug}`
                )
            }}
        >
            <div
                className={`${
                    background === PICTURE_POINT_YELLOW
                        ? 'picture-point-detail-yellow'
                        : `picture-point-detail`
                } ${
                    picturePointItem.itemPosition === POSITION_LEFT
                        ? 'picture-point-detail-left'
                        : 'picture-point-detail-right'
                }`}
            >
                <div>
                    <div className="picture-point-name-font">
                        <strong>{picturePointItem.pointName}</strong>
                    </div>
                    <div className="picture-point-des-font">
                        {picturePointItem.pointDes}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PicturePointItemDisplay
