import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import aboutUsReducer from './aboutUsReducer'
import accessoriesPageReducer from './accessoriesPageReducer'
import cabinetCareReducer from './cabinetCareReducer'
import cartReducer from './cartReducer'
import categoryReducer from './categoryReducer'
import clientReducer from './clientReducer'
import { displayMessagesReducer } from './displayMessagesReducer'
import { errorReducer } from './errorReducer'
import faqReducer from './faqReducer'
import homePageReducer from './homePageReducer'
import kitchenPageReducer from './kitchenPageReducer'
import loadingReducer from './loadingReducer'
import { messageReducer } from './messageReducer'
import orderReducer from './orderReducer'
import plannerPageReducer from './plannerPageReducer'
import policyReducer from './policyReducer'
import productsPageReducer from './productsPageReducer'
import productsReducer from './productsReducer'
import recommendReducer from './recommendReducer'
import requestDesignReducer from './requestDesignReducer'
import sampleReducer from './sampleReducer'
import selfAssembleReducer from './selfAssembleReducer'
import searchBarReducer from './serchBarReducer'
import supportPageReducer from './supportPageReducer'
import { userReducer } from './userReducer'
import vanitiesPageReducer from './vanitiesPageReducer'
import warrantyReducer from './warrantyReducer'

export default combineReducers({
    form: formReducer,
    user: userReducer,
    loading: loadingReducer,
    errors: errorReducer,
    messages: messageReducer,
    category: categoryReducer,
    cart: cartReducer,
    homePage: homePageReducer,
    productsPage: productsPageReducer,
    kitchenPage: kitchenPageReducer,
    vanitiesPage: vanitiesPageReducer,
    accessoriesPage: accessoriesPageReducer,
    plannerPage: plannerPageReducer,
    supportPage: supportPageReducer,
    products: productsReducer,
    warranty: warrantyReducer,
    cabinetCare: cabinetCareReducer,
    selfAssemble: selfAssembleReducer,
    faq: faqReducer,
    aboutUs: aboutUsReducer,
    sample: sampleReducer,
    searchBar: searchBarReducer,
    recommend: recommendReducer,
    client: clientReducer,
    policy: policyReducer,
    order: orderReducer,
    displayMessages: displayMessagesReducer,
    requestDesign: requestDesignReducer,
})
