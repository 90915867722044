import { useState, useEffect } from 'react'
import { useCurrentWidth } from '../screenWidth'
import history from '../../history'
const Carousel = ({ homePage }) => {
    // console.log('homePage ==>>', homePage)
    const [item, setItem] = useState(
        () => homePage[parseInt(localStorage.getItem('hs-online-count')) || 0]
    )
    const [count, setCount] = useState(
        parseInt(localStorage.getItem('hs-online-count')) || 0
    )
    const screenWidth = useCurrentWidth()
    useEffect(() => {
        const total = homePage.length
        let i = count
        const interval = setInterval(() => {
            if (i >= total - 1) {
                i = 0
            } else {
                ++i
            }
            setCount(i)
            setItem(homePage[i])
        }, 5000)
        return () => {
            localStorage.setItem('hs-online-count', i)
            clearInterval(interval)
        }
    }, [count])

    return (
        item && (
            <>
                <div className="carousel-box">
                    <div
                        className="home_big_pic_full"
                        style={
                            screenWidth < 769
                                ? {
                                      backgroundImage: `url(${
                                          item.mobilePicLink || item.picLink
                                      })`,
                                      backgroundPosition: 'center',
                                      backgroundSize: 'cover',
                                  }
                                : {
                                      backgroundImage: `url(${item.picLink})`,
                                      backgroundPosition: `${
                                          item.topAligned ? 'top' : 'center'
                                      }`,
                                      backgroundSize: 'cover',
                                  }
                        }
                    ></div>
                    <div className="slug-container">
                        <div className="slug-text">
                            <h2>{item.title}</h2>
                            <p className="home_big_des">{item.description}</p>
                            <div
                                className="home_big_button"
                                onClick={() => history.push(item.url)}
                            >
                                <div className="home_big_button_text">
                                    {item.button}
                                </div>
                            </div>
                            <div className="slug-count">
                                {homePage.map((item, i) => (
                                    <div
                                        key={i}
                                        className={
                                            count === i
                                                ? 'slug-count-dot-long'
                                                : 'slug-count-dot'
                                        }
                                        onClick={() => {
                                            if (count !== i) {
                                                setCount(i)
                                                setItem(homePage[i])
                                            }
                                        }}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default Carousel
