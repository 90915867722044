export const measureInchToMm = (inch) => {
    return parseInt(inch * 2.54 * 10)
}

export const orderStatusList = [
    { id: 1, status: 'New Order', color: '#e6ff99' },
    { id: 2, status: 'In Progress', color: '#ffcc99' },
    { id: 3, status: 'Shipped', color: '#ffffcc' },
    { id: 4, status: 'Ready to Pick Up', color: '#ffffcc' },
    { id: 5, status: 'Finished', color: '#99ccff' },
    { id: 8, status: 'Cancelled', color: '#ff99cc' },
    { id: 9, status: 'Refund', color: '#cccccc' },
]

export const getOrderStatus = (status) => {
    return orderStatusList.filter((list) => list.id === status)[0]
}
