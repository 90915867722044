import { useEffect } from 'react'
import { Container, Divider } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

const PriceMatchSuccess = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Container>
            <div className="payment-success-box">
                <div className="payment-success-header">
                    Price Match Submit Successful
                </div>
                <Divider />
                <div className="payment-success-body">
                    <div className="payment-success-left">
                        <CheckCircleIcon style={{ color: '#EDC400' }} />
                    </div>
                    <div className="payment-success-right">
                        <div className="payment-success-body1">
                            Thank you for your Price Match Submit!
                        </div>
                        <div className="payment-success-body2">
                            Our stuff will contact your soon!
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default PriceMatchSuccess
