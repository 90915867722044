import { GET_ALL_REQUEST_DESIGNS, GET_ONE_REQUEST_DESIGN } from '../types'

const initialState = {
    requestDesigns: null,
    requestDesign: null,
}

const requestDesignReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_REQUEST_DESIGNS:
            return {
                ...state,
                requestDesigns: payload,
                requestDesign: null,
            }
        case GET_ONE_REQUEST_DESIGN:
            return {
                ...state,
                requestDesign: payload,
            }
        default:
            return state
    }
}

export default requestDesignReducer
