import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_HOME_PIC_POINTS,
    GET_ALL_HOME_SMALL_PICS,
    GET_ALL_NAV_CATEGORIES,
    GET_HOMEPAGE,
} from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllNavItems = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/homepage/navitems')
        dispatch({ type: GET_ALL_NAV_CATEGORIES, payload: data.data })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const getHomePage = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/homepage/homepage')
        dispatch({
            type: GET_HOMEPAGE,
            payload: {
                smallPics: data.data.smallPics,
                picturePoints: data.data.picturePoints,
                homePage: data.data.homePage,
            },
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const getAllHomeSmallPics = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/homepage/homesmallpic')
        dispatch({ type: GET_ALL_HOME_SMALL_PICS, payload: data.data })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const getAllHomePicPoints = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/homepage/homepicpoint')
        dispatch({ type: GET_ALL_HOME_PIC_POINTS, payload: data.data })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}
