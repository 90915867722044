import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllfaqs } from '../store/actions/faqActions'
import { Divider, TextField } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import FaqItem from '../components/faq/FaqItem'
import { SEARCH_FAQS } from '../store/types'

const Faq = () => {
    const dispatch = useDispatch()
    const faqState = useSelector((state) => state.faq)
    const { faqs, keyword } = faqState

    const [search, setSearch] = useState('')

    useEffect(() => {
        dispatch(getAllfaqs(keyword))
    }, [dispatch, keyword])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({ type: SEARCH_FAQS, payload: search })
    }
    return (
        <Container>
            <div className="divider_mobile_title">
                <Divider />
            </div>
            <h1 className="faq_title">Frequently Asked Questions</h1>

            <form onSubmit={(e) => handleSubmit(e)} style={{ width: '100%' }}>
                <div className="faq_search">
                    <SearchIcon
                        style={{
                            color: '#edc400',
                            marginRight: '5px',
                            fontSize: '30px',
                        }}
                    />

                    <TextField
                        label="Search"
                        fullWidth
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </form>

            <div>
                {faqs && faqs.map((faq) => <FaqItem faq={faq} key={faq.id} />)}
            </div>
        </Container>
    )
}

export default Faq
