import { useDispatch } from 'react-redux'
import Container from '@material-ui/core/Container'
import history from '../../history'
import { clearProducts } from '../../store/actions/productsActions'

const StyleHeader = ({ styles }) => {
    const dispatch = useDispatch()
    return (
        <div className="style_header_container">
            <hr />
            <Container disableGutters={true}>
                <div className="style_header_box">
                    <div className="style_header_item_full"></div>
                    {styles &&
                        styles.map((style) => (
                            <div className="style_header_item" key={style.id}>
                                <img
                                    src={style.iconLink}
                                    alt="icon"
                                    width="50px"
                                    height="100px"
                                    style={{
                                        objectFit: 'contain',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        dispatch(clearProducts())
                                        style.comingSoon
                                            ? history.push('/comingsoon')
                                            : history.push(
                                                  `/products/${style.category.slug}/${style.slug}`
                                              )
                                    }}
                                />
                                <p>{style.name}</p>
                            </div>
                        ))}
                </div>
            </Container>
            <hr />
        </div>
    )
}

export default StyleHeader
