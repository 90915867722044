import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { composeWithDevTools } from 'redux-devtools-extension'

const clientInfoFromStorage = localStorage.getItem('HSOnlineClientInfo')
    ? JSON.parse(localStorage.getItem('HSOnlineClientInfo'))
    : null

const clientTokenFromStorage = localStorage.getItem('HSOnlineClientToken')
    ? JSON.parse(localStorage.getItem('HSOnlineClientToken'))
    : null

const shoppingCartFromStorage = localStorage.getItem('HSOnlineCart')
    ? JSON.parse(localStorage.getItem('HSOnlineCart'))
    : []

const checkoutStage = localStorage.getItem('HSOnlineStage')
    ? JSON.parse(localStorage.getItem('HSOnlineStage'))
    : 1

const checkoutPostCode = localStorage.getItem('HSOnlinePostCode')
    ? JSON.parse(localStorage.getItem('HSOnlinePostCode'))
    : ''

const checkoutShippingOption = localStorage.getItem('HSOnlineShippingOption')
    ? JSON.parse(localStorage.getItem('HSOnlineShippingOption'))
    : null

const checkoutShippingInformation = localStorage.getItem(
    'HSOnlineShippingInformation'
)
    ? JSON.parse(localStorage.getItem('HSOnlineShippingInformation'))
    : null

const checkoutShippingBilling = localStorage.getItem('HSOnlineShippingBilling')
    ? JSON.parse(localStorage.getItem('HSOnlineShippingBilling'))
    : null

const checkoutShippingType = localStorage.getItem('HSOnlineShippingType')
    ? JSON.parse(localStorage.getItem('HSOnlineShippingType'))
    : 1

const checkoutPromoCode = localStorage.getItem('HSOnlinePromoCode')
    ? JSON.parse(localStorage.getItem('HSOnlinePromoCode'))
    : ''
const checkoutPromoRate = localStorage.getItem('HSOnlinePromoRate')
    ? JSON.parse(localStorage.getItem('HSOnlinePromoRate'))
    : 0

const initialState = {
    client: {
        clientInfo: clientInfoFromStorage,
        clientToken: clientTokenFromStorage,
    },
    cart: {
        carts: shoppingCartFromStorage,
        stage: checkoutStage,
        postCode: checkoutPostCode,
        shippingOption: checkoutShippingOption,
        information: checkoutShippingInformation,
        billing: checkoutShippingBilling,
        shippingType: checkoutShippingType,
        promoCode: checkoutPromoCode,
        promoRate: checkoutPromoRate,
    },
}

const middleware = [thunk]
const storeMiddleware =
    process.env.REACT_APP_ENV === 'development'
        ? composeWithDevTools(applyMiddleware(...middleware))
        : applyMiddleware(...middleware)

const store = createStore(rootReducer, initialState, storeMiddleware)

export default store
