import { useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const FaqItemList = ({ faqitem }) => {
    const [open, setOpen] = useState(false)
    return (
        <div className="faq_item_list_container">
            <div className="faq_item_list_box" onClick={() => setOpen(!open)}>
                <div className="faq_item_list_title">{faqitem.question}</div>
                <div className="faq_item_list_icon">
                    {open ? <KeyboardArrowUpIcon /> : <ExpandMoreIcon />}
                </div>
            </div>
            {open && <div className="faq_item_list_info">{faqitem.answer}</div>}
        </div>
    )
}

export default FaqItemList
