import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Container from '@material-ui/core/Container'
import { getAllKitchenPages } from '../store/actions/kitchenPageActions'
import PageEndButton from '../components/PageEndButton'
import StyleHeader from '../components/styleHeader/StyleHeader'
import history from '../history'
import { SEARCH_PRODUCTS } from '../store/types'
import Meta from '../components/Meta'

const Kitchen = () => {
    const dispatch = useDispatch()
    const kitchenPageState = useSelector((state) => state.kitchenPage)
    const { styles } = kitchenPageState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!styles) {
            dispatch(getAllKitchenPages())
        }
    }, [dispatch, styles])
    return (
        <>
            <Meta
                title={
                    'Kitchen & Bath Cabinets Mississauga, Base Kitchen Cabinet'
                }
                description={
                    'Hspace Kitchen and Bath is your one-stop-shop for premium quality kitchen and bathroom cabinets. We also offer custom kitchen cabinets. Contact us at 1-833-770-7077.'
                }
            />
            {styles && <StyleHeader styles={styles} />}
            <Container disableGutters={true}>
                <div className="style_title">
                    <span className="style_title_line">
                        <hr />
                    </span>
                    <h1 className="style_title_word">Our Cabinets Styles</h1>
                    <span className="style_title_line">
                        <hr />
                    </span>
                </div>
                <div>
                    {styles &&
                        styles.map((style, index) => (
                            <div key={style.id}>
                                <div
                                    className={
                                        index % 2
                                            ? 'style_item_box_reverse'
                                            : 'style_item_box'
                                    }
                                >
                                    <div
                                        className={`style_item_detail ${
                                            index % 2 ? 'reverse' : ''
                                        }`}
                                    >
                                        <div>
                                            <h3>{style.name} Style</h3>
                                        </div>
                                        <div className="style_item_detail_des">
                                            {style.description}
                                        </div>
                                        <div
                                            className={`style_item_detail_color ${
                                                index % 2 ? 'reverse' : ''
                                            }`}
                                        >
                                            Choose a door color
                                        </div>
                                        <div
                                            className={`style_item_detail_colors ${
                                                index % 2 ? 'reverse' : ''
                                            }`}
                                        >
                                            {style?.stylecolors &&
                                                style?.stylecolors?.map(
                                                    (color) => (
                                                        <div
                                                            key={color.id}
                                                            className="style_item_detail_color_box"
                                                        >
                                                            <img
                                                                src={
                                                                    color.colorIcon
                                                                }
                                                                alt="icon"
                                                                onError={(
                                                                    event
                                                                ) => {
                                                                    event.target.src =
                                                                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                                                                    event.onerror =
                                                                        null
                                                                }}
                                                                onClick={() => {
                                                                    dispatch({
                                                                        type: SEARCH_PRODUCTS,
                                                                        payload:
                                                                            {
                                                                                searchColor:
                                                                                    color.id,
                                                                                searchSize:
                                                                                    '',
                                                                                searchFunc:
                                                                                    '',
                                                                                searchType:
                                                                                    '',
                                                                                searchOrderBy:
                                                                                    '',
                                                                            },
                                                                    })
                                                                    style.comingSoon
                                                                        ? history.push(
                                                                              '/comingsoon'
                                                                          )
                                                                        : history.push(
                                                                              `/products/${style.category.slug}/${style.slug}`
                                                                          )
                                                                }}
                                                            />
                                                            <p>
                                                                {
                                                                    color.colorName
                                                                }
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                    <div className="style_item_pic">
                                        <img
                                            src={style.picLink}
                                            alt="pic"
                                            width="100%"
                                        />
                                    </div>
                                </div>
                                {index !== styles.length - 1 && <hr />}
                            </div>
                        ))}
                </div>
            </Container>

            <PageEndButton buttonTitle="See the style you like? Order a wood sample!" />
        </>
    )
}

export default Kitchen
