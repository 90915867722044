import { errorsReturn } from '../errorsReturn'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { GET_ALL_REQUEST_DESIGNS, GET_ONE_REQUEST_DESIGN } from '../types'
import { logout } from './clientActions'
import history from '../../history'

export const getAllRequestDesigns = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        let res = await request.get(
            `client/requestdesign/listallbyclient`,

            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch({ type: GET_ALL_REQUEST_DESIGNS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneRequestDesign = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        let res = await request.get(
            `client/requestdesign/listonebyclient/${id}`,

            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch({ type: GET_ONE_REQUEST_DESIGN, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteOneRequestDesign = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        await request.delete(
            `client/requestdesign/deleteonebyclient/${id}`,

            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch(controlLoading(false))
        history.push('/clientdesign')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const selectRequestDesign =
    (id, requestId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                client: { clientToken },
            } = getState()
            await request.put(
                `client/requestdesign/clientselect/${id}`,
                id,

                {
                    headers: { Authorization: `Bearer ${clientToken}` },
                }
            )
            dispatch(controlLoading(false))
            dispatch(getOneRequestDesign(requestId))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
