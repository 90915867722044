import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { reduxForm, Field } from 'redux-form'
import {
    renderInputSmall,
    renderSelectSmall,
} from '../../components/ReduxFormInputs'
import { countryCodeList, monthList, yearList } from '../../service/monthYear'
import { paymentChargeCC } from '../../store/actions/paymentActions'
import DisplayMessages from '../DisplayMessages'

const provinceList = [
    'ON',
    'QC',
    'AB',
    'BC',
    'MB',
    'NB',
    'NL',
    'NT',
    'NS',
    'NU',
    'PE',
    'SK',
    'YT',
]

const PaymentForm = (props) => {
    const dispatch = useDispatch()
    const {
        handleSubmit,
        amountDue,
        invoiceId,
        orderId,
        submitting,
        pristine,
    } = props
    const recaptchaRef = useRef()
    const [showAmount, setShowAmount] = useState(0)

    useEffect(() => {
        setShowAmount(amountDue)
    }, [amountDue])

    const submitForm = async (formValues) => {
        const googleRes = await recaptchaRef.current.executeAsync()
        recaptchaRef.current.reset()
        formValues['googleRes'] = googleRes
        formValues['invoiceId'] = invoiceId
        formValues['orderId'] = orderId
        dispatch(paymentChargeCC(formValues))
    }
    return (
        <div className="paymentCharge-cc-input">
            <div className="paymentCharge-cc-input-header">
                Credit Card Payment
            </div>
            <div className="paymentCharge-cc-input-box">
                <div>
                    <div className="paymentCharge-cc-icons">
                        <span className="paymentCharge-cc-icon">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1672006019/cardLogo/visa_crfadw_u1blg4.svg"
                                width="70px"
                                alt="visa"
                            />
                        </span>
                        <span className="paymentCharge-cc-icon">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1672006022/cardLogo/mastercard_xh3vxl_oqkca9.svg"
                                width="70px"
                                alt="master"
                            />
                        </span>
                        <span className="paymentCharge-cc-icon">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1672006026/cardLogo/am_amex_06_wfskku_xkwtvm.png"
                                width="70px"
                                alt="amex"
                            />
                        </span>
                        <span className="paymentCharge-cc-icon">
                            <img
                                src="https://res.cloudinary.com/home-space/image/upload/v1672005983/cardLogo/181206_zfpotk.svg"
                                width="70px"
                                alt="amex"
                            />
                        </span>
                    </div>
                </div>
                <form
                    onSubmit={handleSubmit(submitForm)}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <DisplayMessages />
                    </div>

                    <div className="input-item-full">
                        <Field
                            name="address"
                            type="text"
                            label="Address"
                            max="200"
                            component={renderInputSmall}
                        />
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="city"
                                type="text"
                                label="City"
                                max="50"
                                component={renderInputSmall}
                            />
                        </div>
                        <div className={'input-item-half'}>
                            <Field
                                name="province"
                                label="Province"
                                component={renderSelectSmall}
                            >
                                {provinceList.map((province) => (
                                    <option value={province} key={province}>
                                        {province}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="postCode"
                                type="text"
                                label="Post Code"
                                max="10"
                                component={renderInputSmall}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="country"
                                label="Country"
                                component={renderSelectSmall}
                            >
                                {countryCodeList.map((country) => (
                                    <option
                                        key={country.Code}
                                        value={country.Code}
                                    >
                                        {country.Name}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="cardNumber"
                                type="number"
                                label="Card Number"
                                max="20"
                                component={renderInputSmall}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="paymentName"
                                type="text"
                                label="Name on card"
                                max="100"
                                component={renderInputSmall}
                            />
                        </div>
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="expiryMonth"
                                label="Expiry Month"
                                component={renderSelectSmall}
                            >
                                {monthList.map((month) => (
                                    <option key={month} value={month}>
                                        {month}
                                    </option>
                                ))}
                            </Field>
                        </div>
                        <div className={'input-item-half'}>
                            <Field
                                name="expiryYear"
                                label="Expiry Year"
                                component={renderSelectSmall}
                            >
                                {yearList.map((year) => (
                                    <option key={year} value={year}>
                                        {year}
                                    </option>
                                ))}
                            </Field>
                        </div>
                    </div>
                    <div className="input-double">
                        <div className="input-item-half">
                            <Field
                                name="paymentCvd"
                                type="number"
                                label="CVV"
                                max="5"
                                component={renderInputSmall}
                            />
                        </div>
                        <div className="input-item-half">
                            <Field
                                name="amount"
                                type="number"
                                label="Amount"
                                max="10"
                                component={renderInputSmall}
                                onChange={(e) => setShowAmount(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="paymentCharge-cc-button-box">
                        <button
                            type="submit"
                            className="paymentCharge-cc-button"
                            disabled={submitting || pristine}
                        >
                            <img
                                src="https://res.cloudinary.com/mengchenghui-com/image/upload/v1618589014/MCH_contract/padlock_unqdm0.png"
                                alt="securt"
                                width="20px"
                                height="20px"
                                style={{
                                    marginRight: '5px',
                                }}
                            />{' '}
                            <strong>Pay ${showAmount}</strong>
                        </button>
                    </div>
                    <div className="google-robert">
                        <ReCAPTCHA
                            ref={recaptchaRef}
                            size="invisible"
                            sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY}
                        />
                    </div>
                </form>
            </div>
        </div>
    )
}

const validate = (values) => {
    const errors = {}
    const requiredFields = [
        'address',
        'city',
        'province',
        'postCode',
        'country',
        'cardNumber',
        'paymentName',
        'expiryMonth',
        'expiryYear',
        'paymentCvd',
        'amount',
    ]
    requiredFields.forEach((field) => {
        if (!values[field]) {
            errors[field] = 'Required'
        }
    })

    return errors
}

export default reduxForm({
    form: 'paymentForm',
    validate,
})(PaymentForm)
