import { GET_ALL_NAV_CATEGORIES } from '../types'

const initialState = {
    navCategories: [],
}

export const categoryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_NAV_CATEGORIES:
            return {
                ...state,
                navCategories: payload,
            }

        default:
            return state
    }
}

export default categoryReducer
