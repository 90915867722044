import { GET_ALL_SELFASSEMBLES } from '../types'

const initialState = {
    selfAssembles: null,
}

export const selfAssembleReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SELFASSEMBLES:
            return {
                ...state,
                selfAssembles: payload,
            }

        default:
            return state
    }
}

export default selfAssembleReducer
