import { useDispatch, useSelector } from 'react-redux'
import { layoutNavList } from '../../constant/layoutNavList'
import history from '../../history'
import { setClientNavId } from '../../store/actions/clientActions'

const LayoutNav = () => {
    const dispatch = useDispatch()
    const clientState = useSelector((state) => state.client)
    const { clientNavId } = clientState
    return (
        <div className="layout-nav-box">
            {layoutNavList.map((list) => {
                return (
                    <div
                        key={list.id}
                        className="layout-nav-item-box"
                        style={
                            list.id === clientNavId
                                ? { background: '#fff5c4' }
                                : { background: '#ffffff' }
                        }
                        onClick={() => {
                            dispatch(setClientNavId(list.id))
                            history.push(list.url)
                        }}
                    >
                        <div className="layout-nav-item-icon">{list.icon}</div>
                        <div className="layout-nav-item-name">{list.name}</div>
                    </div>
                )
            })}
        </div>
    )
}

export default LayoutNav
