import { GET_ALL_ABOUTUS } from '../types'

const initialState = {
    header: null,
    headOffices: null,
    teamMembers: null,
}

export const aboutUsReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ABOUTUS:
            return {
                ...state,
                header: payload.header,
                headOffices: payload.headOffices,
                teamMembers: payload.teamMembers,
            }

        default:
            return state
    }
}

export default aboutUsReducer
