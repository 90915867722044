import { useState } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import Main from './pages/Main'
import Default from './pages/Default'
import Alert from './components/Alert'
import LoadingSet from './components/LoadingSet'
import Footer from './components/Footer'
import history from './history'
import Backdrop from './components/backDrop/Backdrop'
import SideDrawer from './components/sideDrawer/SideDrawer'
import Toolbar from './components/toolbar/Toolbar'
import ProductsPage from './pages/ProductsPage'
import Kitchen from './pages/Kitchen'
import Vanities from './pages/Vanities'
import Accessories from './pages/Accessories'
import Planner from './pages/Planner'
import Support from './pages/Support'
import Products from './pages/Products'
import Product from './pages/Product'
import Warranty from './pages/Warranty'
import CabinetCare from './pages/CabinetCare'
import SelfAssemble from './pages/SelfAssemble'
import Faq from './pages/Faq'
import AboutUs from './pages/AboutUs'
import Samples from './pages/Samples'
import MyCart from './pages/MyCart'
import Login from './pages/Login'
import Signin from './pages/Signin'
import SigninSuccess from './pages/SigninSuccess'
import ClientMain from './pages/ClientMain'
import ConfirmEmail from './pages/ConfirmEmail'
import Continue from './pages/Continue'
import ClientCheckOut from './pages/ClientCheckOut'
import ClientDesign from './pages/ClientDesign'
import ClientOrder from './pages/ClientOrder'
import ClientCart from './pages/ClientCart'
import ClientAccount from './pages/ClientAccount'
import GuestCheckout from './pages/GuestCheckout'
import Policy from './pages/Policy'
import ClientPaymentSuccess from './pages/ClientPaymentSuccess'
import GuestPaymentSuccess from './pages/GuestPaymentSuccess'
import ClientOrderInfo from './pages/ClientOrderInfo'
import TrackOrder from './pages/TrackOrder'
import TrackOrderInfo from './pages/TrackOrderInfo'
import ClientEdit from './pages/ClientEdit'
import ShoppingList from './pages/ShoppingList'
import ClientRequestDesign from './pages/ClientRequestDesign'
import RequestSuccess from './pages/RequestSuccess'
import ClientDesignInfo from './pages/ClientDesignInfo'
import ForgetPassword from './pages/ForgetPassword'
import ResetPassword from './pages/ResetPassword'
import PriceMatch from './pages/PriceMatch'
import PriceMatchSuccess from './pages/PriceMatchSuccess'
import BestPrice from './pages/BestPrice'
import ComingSoon from './pages/ComingSoon'
import CartPrint from './pages/CartPrint'
import Bogo from './pages/Bogo'
import Bmsm2022 from './pages/Bmsm2022'
import BoxingMonthPromotion from './pages/BoxingMonthPromotion'
import CountertopPage from './pages/CountertopPage'
import PaymentCharge from './pages/PaymentCharge'
import PaymentReceipt from './pages/PaymentReceipt'
// import Calendly from './components/Calendly'

function App() {
    const [sideDrawerOpen, setSideDrawerOpen] = useState(false)

    const drawerToggleClickHandler = () => {
        setSideDrawerOpen(!sideDrawerOpen)
    }

    const backdropClickHandler = () => {
        setSideDrawerOpen(false)
    }

    return (
        <Router history={history}>
            <div className="main__page">
                {/* <Calendly /> */}
                <Alert />
                <LoadingSet />
                <Toolbar drawerClickHandler={drawerToggleClickHandler} />
                <SideDrawer
                    show={sideDrawerOpen}
                    click={backdropClickHandler}
                />
                {sideDrawerOpen && <Backdrop click={backdropClickHandler} />}
                <main className="main__body">
                    <Switch>
                        <Route path="/" exact component={Main} />
                        <Route path="/login" component={Login} />
                        <Route
                            path="/forgetpassword"
                            component={ForgetPassword}
                        />
                        <Route path="/continue" component={Continue} />
                        <Route path="/trackorder" component={TrackOrder} />
                        <Route
                            path="/trackorderinfo/:token"
                            component={TrackOrderInfo}
                        />
                        <Route path="/clientmain" component={ClientMain} />
                        <Route path="/clientdesign" component={ClientDesign} />
                        <Route
                            path="/clientdesigninfo/:id"
                            component={ClientDesignInfo}
                        />
                        <Route
                            path="/resetpassword/:token"
                            component={ResetPassword}
                        />

                        <Route path="/clientorder" component={ClientOrder} />
                        <Route
                            path="/clientorderinfo/:id"
                            component={ClientOrderInfo}
                        />
                        <Route path="/clientcart" component={ClientCart} />
                        <Route
                            path="/clientpaymentsuccess/:orderId/:typeId"
                            component={ClientPaymentSuccess}
                        />
                        <Route
                            path="/guestpaymentsuccess/:orderId/:typeId"
                            component={GuestPaymentSuccess}
                        />
                        <Route
                            path="/clientaccount"
                            component={ClientAccount}
                        />
                        <Route path="/clientedit" component={ClientEdit} />
                        <Route
                            path="/clientcheckout"
                            component={ClientCheckOut}
                        />

                        <Route
                            path="/confirmemail/:token"
                            component={ConfirmEmail}
                        />
                        <Route path="/signin" component={Signin} />
                        <Route
                            path="/signinsuccessful/:id"
                            component={SigninSuccess}
                        />
                        <Route path="/cart" component={MyCart} />
                        <Route path="/cartprint" component={CartPrint} />
                        <Route
                            path="/guestcheckout"
                            component={GuestCheckout}
                        />
                        <Route path="/productspage" component={ProductsPage} />
                        <Route path="/cabinets" component={Kitchen} />
                        <Route path="/bestprice" component={BestPrice} />
                        <Route path="/homedecor" component={Vanities} />
                        <Route path="/accessories" component={Accessories} />
                        <Route path="/designcenter" component={Planner} />
                        <Route
                            path="/bookdesign"
                            component={ClientRequestDesign}
                        />
                        <Route
                            path="/requestsuccess/:projectId"
                            component={RequestSuccess}
                        />
                        <Route path="/pricematch" component={PriceMatch} />
                        <Route
                            path="/pricematchsuccess"
                            component={PriceMatchSuccess}
                        />

                        <Route path="/support" component={Support} />
                        <Route path="/warranty" component={Warranty} />
                        <Route path="/policy" component={Policy} />
                        <Route path="/cabinetcare" component={CabinetCare} />
                        <Route path="/selfassemble" component={SelfAssemble} />
                        <Route path="/faq" component={Faq} />
                        <Route path="/aboutus" component={AboutUs} />
                        <Route path="/product/:id" component={Product} />
                        <Route path="/sample/:slug" component={Samples} />
                        <Route path="/shoppinglist" component={ShoppingList} />
                        <Route
                            path="/products/:category/:style"
                            exact
                            component={Products}
                        />
                        <Route
                            path="/search/:keyword"
                            exact
                            component={Products}
                        />
                        <Route path="/comingsoon" component={ComingSoon} />
                        <Route path="/promo/bogo" component={Bogo} />
                        <Route path="/promo/bmsm2022" component={Bmsm2022} />
                        <Route
                            path="/promo/boxing"
                            component={BoxingMonthPromotion}
                        />
                        <Route path="/countertop" component={CountertopPage} />
                        <Route
                            path="/paymentcharge/:id"
                            component={PaymentCharge}
                        />
                        <Route
                            path="/paymentreceipt/:id"
                            component={PaymentReceipt}
                        />
                        <Route component={Default} />
                    </Switch>
                </main>
                <Footer />
            </div>
        </Router>
    )
}

export default App
