import { Container } from '@material-ui/core'
import React, { useEffect } from 'react'
import history from '../history'

const Bmsm2022 = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <div className="bogo_container">
                <div className="bogo_bgpic">
                    <div className="bogo_text_box">
                        <div className="bogo_text_area">
                            <h3>BUY MORE SAVE MORE</h3>
                            <p>PROMOTION VALID: 2022.12.01 - 2023.01.01</p>
                        </div>
                    </div>
                </div>
            </div>
            <Container disableGutters={true} style={{ padding: '30px 0' }}>
                <div className="bogo_detail_box">
                    <div className="bogo_detail_left">
                        <div>
                            <div className="bogo_detail_header">Cabinets</div>
                            <div className="bogo_detail_big_text">
                                Spend up to $3,000, get 10% off
                            </div>
                            <div className="bogo_detail_big_text">
                                Spend up to $3,001 - $5,500, get 20% off
                                purchase
                            </div>
                            <div className="bogo_detail_big_text">
                                Spend up to $5,501 - $7,500, get 25% off
                                purchase
                            </div>
                            <div className="bogo_detail_big_text">
                                Spend up to $7,501 - $10,000, get 30% off
                                purchase
                            </div>
                            <div className="bogo_detail_big_text">
                                Spend up to $10,001 - $15,000, get 35% off
                                purchase
                            </div>
                            <div className="bogo_detail_big_text">
                                Spend up to $15,001 or more, get 40% off
                                purchase
                            </div>
                            <div className="bogo_detail_text">
                                *Buy More Save More Sale applies to all in-stock
                                custom cabinets, including bathroom vanity.
                            </div>
                            <div className="bogo_detail_big_text"></div>
                        </div>
                        <button
                            className="bogo_detail_button"
                            onClick={() => history.push('/cabinets')}
                        >
                            Shop Now
                        </button>
                    </div>
                    <div className="bogo_detail_right">
                        <img
                            src="https://res.cloudinary.com/home-space/image/upload/v1660158109/New%20Website%20lookout%20%28for%20yanni%29/Cabient/pexels-curtis-adams-5071143_szqkcj.webp"
                            className="bogo_pic"
                            alt="pic"
                        />
                    </div>
                </div>
            </Container>
        </>
    )
}

export default Bmsm2022
