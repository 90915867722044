import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_FAQS } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllfaqs =
    (keyword = '') =>
    async (dispatch) => {
        try {
            let res = await request.get(`client/support/faq?keyword=${keyword}`)
            dispatch({
                type: GET_ALL_FAQS,
                payload: {
                    faqs: res.data.data,
                    keyword: keyword,
                },
            })
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors)
        }
    }
