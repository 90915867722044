import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_SAMPLES, SAMPLE_TOP_PAGE } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllSamples = (slug) => async (dispatch) => {
    try {
        const { data } = await request.get(`/client/sample/${slug}`)
        dispatch({
            type: GET_ALL_SAMPLES,
            payload: data.data,
        })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const sampleTopPage = (status) => async (dispatch) => {
    dispatch({ type: SAMPLE_TOP_PAGE, payload: status })
}
