import { GET_ALL_WARRANTIES } from '../types'

const initialState = {
    style: null,
    warranties: null,
}

export const warrantyReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_WARRANTIES:
            return {
                ...state,
                style: payload.style,
                warranties: payload.warranties,
            }

        default:
            return state
    }
}

export default warrantyReducer
