import { useDispatch, useSelector } from 'react-redux'
import TextField from '@material-ui/core/TextField'
import SearchIcon from '@material-ui/icons/Search'
import history from '../../history'
import { setSearchBarStatus } from '../../store/actions/searchBarActions'
import { logout, setClientNavId } from '../../store/actions/clientActions'

const SideDrawer = (props) => {
    const dispatch = useDispatch()
    let drawerClasses = ['side-drawer']
    if (props.show) drawerClasses = ['side-drawer open']

    const homePageState = useSelector((state) => state.homePage)
    const { navItems } = homePageState

    const searchBarState = useSelector((state) => state.searchBar)
    const { searchBar } = searchBarState

    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    return (
        <nav className={drawerClasses}>
            <div
                style={{
                    position: 'relative',
                    display: 'inline-block',
                    cursor: 'pointer',
                }}
                onClick={() => {
                    props.click()
                    dispatch(setSearchBarStatus(!searchBar))
                }}
            >
                <SearchIcon
                    style={{
                        position: 'absolute',
                        left: 0,
                        top: 20,
                        width: 20,
                        height: 20,
                        color: '#edc400',
                    }}
                />
                <TextField
                    id="demo2"
                    label="Search"
                    style={{ textIndent: 30 }}
                    disabled={true}
                />
            </div>
            <ul>
                <li></li>
                {navItems &&
                    navItems.map((item) => (
                        <li
                            key={item.id}
                            className="nav_font_blod"
                            onClick={() => {
                                props.click()
                                history.push(item.url)
                            }}
                        >
                            {item.displayName}
                        </li>
                    ))}

                {clientInfo ? (
                    <>
                        <li
                            style={{ marginTop: '2rem' }}
                            onClick={() => {
                                props.click()
                                dispatch(setClientNavId(0))
                                history.push('/clientmain')
                            }}
                        >
                            {clientInfo.firstName}
                        </li>
                        <li
                            onClick={() => {
                                props.click()
                                dispatch(logout())
                            }}
                        >
                            Logout
                        </li>
                    </>
                ) : (
                    <>
                        <li
                            style={{ marginTop: '2rem' }}
                            onClick={() => {
                                props.click()
                                history.push('/login')
                            }}
                        >
                            Login
                        </li>
                        <li
                            onClick={() => {
                                props.click()
                                history.push('/signin')
                            }}
                        >
                            Sign-in
                        </li>
                    </>
                )}
            </ul>
        </nav>
    )
}

export default SideDrawer
