import { useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import CloseIcon from '@material-ui/icons/Close'
import { useDispatch } from 'react-redux'
import { getErrors } from '../../store/actions/errorActions'

const DragDrop = ({ file, setFile, id }) => {
    const dispatch = useDispatch()
    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            if (acceptedFiles[0].size > 5242880) {
                dispatch(getErrors('Over size! Max 5M'))
            } else {
                setFile(
                    acceptedFiles.map((file) =>
                        Object.assign(file, {
                            preview: URL.createObjectURL(file),
                        })
                    )[0]
                )
            }
        },
    })

    useEffect(
        () => () => {
            // Make sure to revoke the data uris to avoid memory leaks
            URL.revokeObjectURL(file?.preview)
        },
        [file]
    )

    return (
        <section className="container">
            <div {...getRootProps()} className="drag-drop-input">
                <input {...getInputProps()} />
                {file ? (
                    <div className="drag-drop-input-pic">
                        <div
                            className="drag-drop-input-delete"
                            onClick={() => setFile(null)}
                        >
                            <CloseIcon style={{ color: '#ffffff' }} />
                        </div>
                        <img
                            src={file?.preview}
                            alt="pic"
                            className="drag-drop-pic"
                        />
                    </div>
                ) : (
                    <div className="drag-drop-input-text">
                        <p style={{ fontWeight: '600' }}>Side {id} photo</p>
                        <p>Drag & Drop or Browse</p>
                    </div>
                )}
            </div>
        </section>
    )
}

export default DragDrop
