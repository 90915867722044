import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Container } from '@material-ui/core'
import OrderInfoBase from '../components/order/OrderInfoBase'
import request from '../config/request'
import { getErrors } from '../store/actions/errorActions'

const TrackOrderInfo = ({ match }) => {
    const dispatch = useDispatch()
    const token = match.params.token
    const [order, setOrder] = useState(null)

    useEffect(() => {
        const getOrderByToken = async () => {
            try {
                let res = await request.get(
                    `client/order/listonebytoken/${token}`
                )
                setOrder(res.data.data)
            } catch (e) {
                const errors = {
                    msg: e?.response?.data?.msg,
                }
                dispatch(getErrors(errors?.msg))
            }
        }
        getOrderByToken()
    }, [dispatch, token])

    return (
        <>
            {order ? (
                <Container maxWidth="md">
                    <OrderInfoBase order={order} />
                </Container>
            ) : (
                ''
            )}
        </>
    )
}

export default TrackOrderInfo
