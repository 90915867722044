import { BMSM2022_CATEGORY } from '../../constant/promoCode'
import {
    ADD_TO_CART,
    CART_CHANGE_QTY,
    CART_REMOVE_ITEM,
    GET_DELIVERY_LOCATIONS,
    GET_PICKUP_LOCATIONS,
    MODIFY_POSTCODE,
    SHIPPING_BILLING,
    SHIPPING_INFORMATION,
    SHIPPING_OPTION,
    SHIPPING_STAGE,
    SHIPPING_TYPE,
    CLEANER_CARTS,
    UPDATE_PROMO_CODE,
    UPDATE_PROMO_CART,
    REMOVE_PROMO_CART,
    UPDATE_PROMO_BMSM2022_CODE,
} from '../types'

const initialState = {
    carts: [],
    stage: 1,
    promoCode: '',
    promoRate: 0,
    postCode: '',
    shippingType: 1,
    shippingOption: null,
    information: null,
    billing: null,
    deliveryLocations: [],
    pickupLocations: [],
}

export const cartReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case ADD_TO_CART:
            const existItem = state.carts.find((x) => x.id === payload.id)
            // console.log(existItem)
            if (existItem) {
                const existItemQty = state.carts
                    .filter((x) => x.productColorId === payload.productColorId)
                    .reduce((a, b) => (a += b.qty), 0)

                if (existItemQty >= payload.stock) {
                    return {
                        ...state,
                    }
                }
                const tempCart = state.carts.map((x) =>
                    x.id === payload.id ? { ...x, qty: x.qty + payload.qty } : x
                )
                return {
                    ...state,
                    carts: tempCart,
                }
            } else {
                return {
                    ...state,
                    carts: [...state.carts, payload],
                }
            }
        case CART_REMOVE_ITEM:
            return {
                ...state,
                carts: state.carts.filter((x) => x.id !== payload),
            }
        case CART_CHANGE_QTY:
            return {
                ...state,
                carts: state.carts.map((x) =>
                    x.id === payload.id ? { ...x, qty: payload.qty } : x
                ),
            }
        case SHIPPING_STAGE:
            return {
                ...state,
                stage: payload,
            }
        case MODIFY_POSTCODE:
            return {
                ...state,
                postCode: payload,
            }
        case SHIPPING_OPTION:
            return {
                ...state,
                shippingOption: payload,
            }
        case SHIPPING_INFORMATION:
            return {
                ...state,
                information: payload,
            }
        case SHIPPING_BILLING:
            return {
                ...state,
                billing: payload,
            }
        case SHIPPING_TYPE:
            return {
                ...state,
                shippingType: payload,
            }
        case GET_DELIVERY_LOCATIONS:
            return {
                ...state,
                deliveryLocations: payload,
            }
        case GET_PICKUP_LOCATIONS:
            return {
                ...state,
                pickupLocations: payload,
            }
        case UPDATE_PROMO_CODE:
            return {
                ...state,
                promoCode: payload,
                promoRate: 0,
            }
        case CLEANER_CARTS:
            return {
                carts: [],
                stage: 1,
                postCode: '',
                shippingType: 1,
                shippingOption: null,
                information: null,
                billing: null,
                deliveryLocations: [],
                pickupLocations: [],
            }
        case UPDATE_PROMO_CART:
            let tempCarts = state.carts
                .map((item) => {
                    return { ...item, promoActive: 0 }
                })
                .sort((a, b) => a.price - b.price)
            let baseAmount = state.carts
                .filter((item) => item.promoGroupId === 2)
                .reduce((a, b) => (a += b.qty), 0)
            const wallAmount = state.carts
                .filter((item) => item.promoGroupId === 1)
                .sort((a, b) => a.price - b.price)
            let baseArr = state.carts
                .filter((item) => item.promoGroupId === 2)
                .sort((a, b) => a.price - b.price)
            if (
                baseAmount > 0 &&
                wallAmount.length > 0 &&
                baseArr[baseArr.length - 1]?.price >= wallAmount[0]?.price
            ) {
                tempCarts = tempCarts.map((item) => {
                    if (
                        item.promoGroupId === 1 &&
                        baseAmount > 0 &&
                        baseArr.length > 0
                    ) {
                        let addQty = 0
                        let j = 0
                        for (let i = 0; i < baseArr.length; i++) {
                            if (baseArr[i].price >= item.price) {
                                const leftQty = parseInt(item.qty - addQty)
                                if (baseArr[i].qty >= leftQty) {
                                    baseArr[i].qty -= leftQty
                                    baseAmount -= leftQty
                                    addQty = 0
                                    return {
                                        ...item,
                                        promoActive: item.qty,
                                    }
                                } else if (baseArr[i].qty < item.qty) {
                                    if (baseAmount === baseArr[i].qty) {
                                        baseAmount = 0
                                        return {
                                            ...item,
                                            promoActive:
                                                baseArr[i].qty + addQty,
                                        }
                                    } else {
                                        addQty += baseArr[i].qty
                                        if (baseArr.length === 1) {
                                            baseAmount = 0
                                            return {
                                                ...item,
                                                promoActive: addQty,
                                            }
                                        } else {
                                            baseAmount -= baseArr[i].qty
                                            baseArr[i].qty = 0
                                            j++
                                        }
                                    }
                                }
                            } else {
                                baseAmount -= baseArr[i].qty
                                baseArr[i].qty = 0
                                j++
                            }
                        }
                        if (j > 0) {
                            baseArr.splice(0, j)
                        }
                        if (addQty > 0) {
                            return { ...item, promoActive: addQty }
                        }
                    }
                    return item
                })
            }
            return {
                ...state,
                carts: tempCarts,
            }
        case UPDATE_PROMO_BMSM2022_CODE:
            const tempBmsmCarts = state.carts.map((item) => {
                if (item.productCategory === BMSM2022_CATEGORY)
                    return { ...item, promoActive: 1 }
                return { ...item, promoActive: 0 }
            })
            const newTempCartsSum = tempBmsmCarts
                .filter((item) => item.promoActive === 1)
                .reduce((sum, product) => sum + product.price * product.qty, 0)
            let tempPromoRate = 0
            if (newTempCartsSum > 0 && newTempCartsSum <= 3000)
                tempPromoRate = 10
            if (newTempCartsSum > 3000 && newTempCartsSum <= 5500)
                tempPromoRate = 20
            if (newTempCartsSum > 5500 && newTempCartsSum <= 7500)
                tempPromoRate = 25
            if (newTempCartsSum > 7500 && newTempCartsSum <= 10000)
                tempPromoRate = 30
            if (newTempCartsSum > 10000 && newTempCartsSum <= 15000)
                tempPromoRate = 35
            if (newTempCartsSum > 15000) tempPromoRate = 40

            return {
                ...state,
                carts: tempBmsmCarts,
                promoRate: tempPromoRate,
            }
        case REMOVE_PROMO_CART:
            const newTempCarts = state.carts.map((item) => {
                return { ...item, promoActive: 0 }
            })
            return {
                ...state,
                carts: newTempCarts,
            }
        default:
            return state
    }
}

export default cartReducer
