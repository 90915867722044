import { REMOVE_CART_SUCCESSSFULLY } from '../../constant/default'
import {
    ADD_TO_CART,
    CART_CHANGE_QTY,
    CART_REMOVE_ITEM,
    GET_DELIVERY_LOCATIONS,
    GET_PICKUP_LOCATIONS,
    MODIFY_POSTCODE,
    REMOVE_PROMO_CART,
    SHIPPING_BILLING,
    SHIPPING_INFORMATION,
    SHIPPING_OPTION,
    SHIPPING_STAGE,
    SHIPPING_TYPE,
    UPDATE_PROMO_BMSM2022_CODE,
    UPDATE_PROMO_CART,
    UPDATE_PROMO_CODE,
} from '../types'
import { getErrors } from './errorActions'
import { errorsReturn } from '../errorsReturn'
import { createMessage } from './messageActions'
import request from '../../config/request'
import { controlLoading } from './loadingActions'
import { addToCartPopUpAction } from './productsActions'
import { BMSM2022_CATEGORY, promoCodeList } from '../../constant/promoCode'

export const addToCart = (values) => async (dispatch, getState) => {
    if (
        values.stock <=
        getState()
            .cart.carts.filter(
                (x) => x.productColorId === values.productColorId
            )
            .reduce((a, b) => (a += b.qty), 0) +
            values.qty
    ) {
        // dispatch(getErrors('Over Stock'))
        // console.log('values ===>>>', values)
        dispatch(addToCartPopUpAction(true, false, values.name))
    } else {
        dispatch({
            type: ADD_TO_CART,
            payload: values,
        })
        // dispatch(createMessage(ADD_CART_SUCCESSSFULLY))
        if (
            (values.productCategory === BMSM2022_CATEGORY ||
                values.promoGroupId) &&
            getState().cart.promoCode
        ) {
            dispatch(checkPromoProducts())
        }
        dispatch(addToCartPopUpAction(true, true, values.name))
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
    }
}

export const addAllToCartAction = (values) => async (dispatch, getState) => {
    dispatch({
        type: ADD_TO_CART,
        payload: values,
    })
    dispatch(checkPromoProducts())
    localStorage.setItem('HSOnlineCart', JSON.stringify(getState().cart.carts))
}

export const removeFromCart =
    (id, promoId, promoBm) => (dispatch, getState) => {
        dispatch({
            type: CART_REMOVE_ITEM,
            payload: id,
        })
        if (promoId || promoBm) {
            dispatch(checkPromoProducts())
        }
        dispatch(createMessage(REMOVE_CART_SUCCESSSFULLY))
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
    }

export const changeCartQty =
    (id, qty, promoId, promoBm) => (dispatch, getState) => {
        dispatch({
            type: CART_CHANGE_QTY,
            payload: { id, qty },
        })
        if (promoId || promoBm) {
            dispatch(checkPromoProducts())
        }
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
    }

export const shippingStage = (stage) => async (dispatch, getState) => {
    dispatch({
        type: SHIPPING_STAGE,
        payload: stage,
    })
    localStorage.setItem('HSOnlineStage', JSON.stringify(getState().cart.stage))
}

export const changePostCode = (value) => async (dispatch, getState) => {
    dispatch({
        type: MODIFY_POSTCODE,
        payload: value,
    })
    localStorage.setItem(
        'HSOnlinePostCode',
        JSON.stringify(getState().cart.postCode)
    )
}

export const chnageShippingType = (id) => async (dispatch, getState) => {
    dispatch({
        type: SHIPPING_TYPE,
        payload: id,
    })
    localStorage.setItem(
        'HSOnlineShippingType',
        JSON.stringify(getState().cart.shippingType)
    )
}

export const changeShippingOption = (values) => async (dispatch, getState) => {
    dispatch({
        type: SHIPPING_OPTION,
        payload: values,
    })
    localStorage.setItem(
        'HSOnlineShippingOption',
        JSON.stringify(getState().cart.shippingOption)
    )
}

export const shippingInformation = (values) => async (dispatch, getState) => {
    dispatch({
        type: SHIPPING_INFORMATION,
        payload: values,
    })
    localStorage.setItem(
        'HSOnlineShippingInformation',
        JSON.stringify(getState().cart.information)
    )
}

export const shippingBilling = (values) => async (dispatch, getState) => {
    dispatch({
        type: SHIPPING_BILLING,
        payload: values,
    })
    localStorage.setItem(
        'HSOnlineShippingBilling',
        JSON.stringify(getState().cart.billing)
    )
}

export const getAllDeliveryLocations = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/cart/deliverylocation')
        dispatch({ type: GET_DELIVERY_LOCATIONS, payload: data.data })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const getAllPickUpLocations = () => async (dispatch) => {
    try {
        const { data } = await request.get('/client/cart/pickuplocation')
        dispatch({ type: GET_PICKUP_LOCATIONS, payload: data.data })
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const checkPromoCode = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        const { data } = await request.post(
            `/client/clientpromocode/checkpromocode`,
            values
        )
        if (data.data.check) {
            dispatch({
                type: UPDATE_PROMO_CODE,
                payload: data.data.promoCode.trim(),
            })
            localStorage.setItem(
                'HSOnlinePromoCode',
                JSON.stringify(data.data.promoCode.trim())
            )
            dispatch(checkPromoProducts())
        }

        dispatch(controlLoading(false))
    } catch (e) {
        dispatch(deletePromoCode())
        dispatch(removePromoProducts())
        errorsReturn(e, dispatch, controlLoading, getErrors)
    }
}

export const deletePromoCode = () => async (dispatch) => {
    dispatch({
        type: UPDATE_PROMO_CODE,
        payload: null,
    })
    dispatch(removePromoProducts())
    localStorage.removeItem('HSOnlinePromoCode')
    localStorage.removeItem('HSOnlinePromoRate')
}

export const checkPromoProducts = () => async (dispatch, getState) => {
    const {
        cart: { carts, promoCode },
    } = getState()
    if (carts.length > 0 && promoCode === process.env.REACT_APP_PROMO_CODE) {
        dispatch({ type: UPDATE_PROMO_CART })
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
    }

    if (carts.length > 0 && promoCode === promoCodeList.BMSM2022) {
        dispatch({ type: UPDATE_PROMO_BMSM2022_CODE })
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
        localStorage.setItem(
            'HSOnlinePromoRate',
            JSON.stringify(getState().cart.promoRate)
        )
    }
}

export const removePromoProducts = () => async (dispatch, getState) => {
    const {
        cart: { carts },
    } = getState()
    if (carts.length > 0) {
        dispatch({ type: REMOVE_PROMO_CART })
        localStorage.setItem(
            'HSOnlineCart',
            JSON.stringify(getState().cart.carts)
        )
    }
}
