import { GET_ALL_FAQS, SEARCH_FAQS } from '../types'

const initialState = {
    faqs: null,
    keyword: '',
}

export const faqReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_FAQS:
            return {
                ...state,
                faqs: payload.faqs,
                keyword: payload.keyword,
            }
        case SEARCH_FAQS:
            return {
                ...state,
                keyword: payload,
            }
        default:
            return state
    }
}

export default faqReducer
