import request from '../../config/request'
import {
    ACCOUNT_ACTIVED,
    CLIENT_FORGET_PASSWORD_SUCCESS,
    CLIENT_PROFILE_UPDATE_SUCCESS,
    CLIENT_RESET_PASSWORD_SUCCESS,
    RESEND_EMAIL_SUCCESS,
} from '../../constant/default'
import history from '../../history'
import { errorsReturn } from '../errorsReturn'
import {
    CLIENT_LOGOIN_SUCCESS,
    CLIENT_LOGOUT,
    CLIENT_NAV_ID,
    DISPLAY_ERROR_MESSAGE,
} from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'
import { createMessage } from './messageActions'

export const login = (values) => async (dispatch) => {
    if (values.rememberMe) {
        localStorage.setItem('HSClientRemember', JSON.stringify(values.email))
    } else {
        localStorage.removeItem('HSClientRemember')
    }
    try {
        dispatch(controlLoading(true))
        const { data } = await request.post('client/auth/login', values)
        localStorage.setItem(
            'HSOnlineClientInfo',
            JSON.stringify(data.data.client)
        )
        localStorage.setItem(
            'HSOnlineClientToken',
            JSON.stringify(data.data.token)
        )
        dispatch({
            type: CLIENT_LOGOIN_SUCCESS,
            payload: {
                clientInfo: data.data.client,
                clientToken: data.data.token,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        // errorsReturn(e, dispatch, controlLoading, getErrors)
        const errors = {
            msg: e?.response?.data?.msg,
        }
        dispatch(controlLoading(false))
        dispatch({ type: DISPLAY_ERROR_MESSAGE, payload: errors?.msg })
    }
}

export const logout = () => (dispatch) => {
    localStorage.removeItem('HSOnlineClientInfo')
    localStorage.removeItem('HSOnlineClientToken')
    dispatch({ type: CLIENT_LOGOUT })
}

export const setClientNavId = (id) => (dispatch) => {
    dispatch({ type: CLIENT_NAV_ID, payload: id })
}

export const signin = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        let res = await request.post(`client/client/`, values)
        dispatch(controlLoading(false))
        history.push(`/signinsuccessful/${res.data.data.id}`)
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const confirmEmail = (token) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.get(`client/client/confirmation/${token}`)
        dispatch(createMessage(ACCOUNT_ACTIVED))
        dispatch(controlLoading(false))
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const resendConfirmEmail = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.get(`client/client/resendconfirmemail/${id}`)
        dispatch(createMessage(RESEND_EMAIL_SUCCESS))
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateClientProfile = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        const res = await request.put(
            `client/client/updateclientprofile/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch(createMessage(CLIENT_PROFILE_UPDATE_SUCCESS))
        localStorage.setItem(
            'HSOnlineClientInfo',
            JSON.stringify(res.data.data)
        )
        const newTokenValue = res.headers.authorization
        localStorage.setItem(
            'HSOnlineClientToken',
            JSON.stringify(newTokenValue)
        )
        dispatch({
            type: CLIENT_LOGOIN_SUCCESS,
            payload: {
                clientInfo: res.data.data,
                clientToken: newTokenValue,
            },
        })
        dispatch(controlLoading(false))
        history.push('/clientaccount')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const forgetPassword = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.put(`client/client/forgetpassword`, values)
        dispatch(createMessage(CLIENT_FORGET_PASSWORD_SUCCESS))
        dispatch(controlLoading(false))
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const resetPassword = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))

        await request.put(`client/client/resetpassword`, values)
        dispatch(createMessage(CLIENT_RESET_PASSWORD_SUCCESS))
        dispatch(controlLoading(false))
        history.push('/login')
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
