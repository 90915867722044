import { useDispatch, useSelector } from 'react-redux'
import { changeShippingOption } from '../../store/actions/cartActions'
const CheckoutPickUpList = ({ location }) => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState

    const handleOption = () => {
        const values = {
            shippingType: 2,
            pickUpLocationId: location.id,
        }
        dispatch(changeShippingOption(values))
    }
    return (
        <div className="pickup-list-box">
            <div className="pickup-list-radio">
                <input
                    type="radio"
                    name="pickUpId"
                    className="delivery-radio-input"
                    onClick={() => handleOption()}
                    value={0}
                    defaultChecked={
                        shippingOption?.shippingType === 2 &&
                        shippingOption?.pickUpLocationId === location.id
                    }
                />
            </div>
            <div className="pickup-list-address">
                <a
                    href={`https://maps.google.com/?q=${location.address}`}
                    target="_black"
                    style={{ textDecoration: 'none', color: '#000' }}
                >
                    <div>
                        {location.address1}{' '}
                        {location?.address2 && `, ${location?.address2}`}
                    </div>
                    <div className="pickup-address-city">
                        <div style={{ display: 'flex' }}>
                            <span>{location.city}</span>
                            <span className="mobile-display">, </span>
                        </div>
                        <div>
                            {location.province} {location.postCode}
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default CheckoutPickUpList
