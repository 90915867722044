import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { promoGroup } from '../../common/promoService'
import {
    addToCart,
    changeShippingOption,
    shippingStage,
} from '../../store/actions/cartActions'
import { productOption } from '../../store/actions/productsActions'
import { useCurrentWidth } from '../screenWidth'

const RecommendItem = ({ item }) => {
    const dispatch = useDispatch()
    const cartState = useSelector((state) => state.cart)
    const { shippingOption } = cartState
    const handleAddToCard = () => {
        const promoGroupId = promoGroup(item.product)
        const productCategory = item.product.style.category.slug
        if (
            item.product.style.category.productOption &&
            (item.product.doorDirection ||
                item.product.glassdoors.length > 0 ||
                item.product.cabinetdepths.length > 0)
        ) {
            const productSelect = {
                addProduct: item,
                name: item.product.name,
                promoGroupId,
                productCategory,
                doorDirection: item.product.doorDirection,
                glassdoors: item.product.glassdoors,
                cabinetdepths: item.product.cabinetdepths,
                productWidth: item.product.productWidth,
                productHeight: item.product.productHeight,
                productDepth: item.product.productDepth,
                shippingMethod: item.product.style.category.shippingMethod,
            }
            dispatch(productOption(true, productSelect))
        } else {
            const values = {
                id: `${item.id}-0-0-0`,
                productColorId: item.id,
                name: item.product.name,
                skuNo: item.skuNo,
                stock: item.stock,
                basePrice: item.price,
                price: item.price,
                picLink: item.productpictures.sort(
                    (a, b) => b.mainPage - a.mainPage
                )[0]?.picLink
                    ? item.productpictures.sort(
                          (a, b) => b.mainPage - a.mainPage
                      )[0]?.picLink
                    : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png',
                productWidth: item.product.productWidth,
                productHeight: item.product.productHeight,
                productDepth: item.product.productDepth,
                color: item.stylecolor.colorNumber,
                shippingMethod: item.product.style.category.shippingMethod,
                productCategory,
                doorDirection: '',
                glassOption: '',
                depthOption: '',
                qty: 1,
                promoGroupId,
            }
            dispatch(addToCart(values))
            if (shippingOption?.shippingType === 1) {
                dispatch(shippingStage(1))
                dispatch(changeShippingOption(null))
            }
        }
    }
    const screenWidth = useCurrentWidth()
    return (
        <div className="recommend_item_box">
            <Link to={`/product/${item.product.slug}`}>
                <div className="recommend_item_pic">
                    <img
                        src={
                            item.productpictures[0]?.picLink
                                ? item.productpictures[0]?.picLink
                                : 'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                        }
                        alt="pic"
                    />
                </div>
            </Link>
            <div className="recommend_item_info">
                <div className="recommend_item_title">
                    <div className="recommend_item_title_left">
                        <div className="recommend_item_title_name">
                            {screenWidth < 769
                                ? item.product.name.length > 27
                                    ? item.product.name.substr(0, 27) + '...'
                                    : item.product.name
                                : item.product.name.length > 70
                                ? item.product.name.substr(0, 70) + '...'
                                : item.product.name}
                        </div>
                        <div className="recommend_item_title_size">
                            {item.product.productWidth}" W x{' '}
                            {item.product.productHeight}" H x{' '}
                            {item.product.productDepth}" D
                        </div>
                    </div>
                    <div className="recommend_item_title_price">
                        ${item.price}
                    </div>
                </div>
                <div className="recommend_item_button">
                    <button onClick={() => handleAddToCard()}>
                        Add to cart
                    </button>
                </div>
            </div>
        </div>
    )
}

export default RecommendItem
