import { useMemo } from 'react'
import ClientOrderInfoItem from './ClientOrderInfoItem'
import { Chip, Divider } from '@material-ui/core'
import { getOrderStatus } from '../../constant/formula'
import moment from 'moment'
import { cartType } from '../../common/cardType'

const OrderInfoBase = ({ order }) => {
    const orderStatusDisplay = getOrderStatus(order?.orderStatus)
    const shippingAddress = order?.orderaddresses?.filter(
        (address) => address.addressType === 1
    )[0]
    const billingAddress = order?.orderaddresses?.filter(
        (address) => address.addressType === 2
    )[0]

    const shippingFee =
        order?.ordershipping?.shippingType === 1
            ? order?.ordershipping?.price
            : 0

    const paymentAmount = useMemo(() => {
        if (order?.orderpayments?.length === 0) return 0
        return (
            order?.orderpayments?.reduce((a, b) => {
                if (b.successful) return a + b.amount
                return a
            }, 0) || 0
        )
    }, [order])
    return (
        <>
            <div className="order-info-box">
                <div className="order-info-detail-title">Order Details</div>
                <Divider />
                <div className="order-info-detail-box">
                    <div className="order-info-detail-left">
                        <div className="order-left-box">
                            <div className="left-title">Order number:</div>
                            <div className="left-value">{order.orderNo}</div>
                        </div>
                        <div className="order-left-box">
                            <div className="left-title">Status:</div>
                            <div className="left-value">
                                <Chip
                                    label={orderStatusDisplay.status}
                                    style={{
                                        background: orderStatusDisplay.color,
                                    }}
                                />
                            </div>
                        </div>

                        <div className="order-left-box-flex">
                            <div className="left-title">Ship to:</div>
                            <div className="left-value">
                                {shippingAddress.firstName}{' '}
                                {shippingAddress.lastName}
                                <br />
                                {shippingAddress.address}
                                <br />
                                {shippingAddress.city},{' '}
                                {shippingAddress.province}{' '}
                                {shippingAddress.postCode}
                                <br />
                                {shippingAddress.phone}
                            </div>
                        </div>
                    </div>
                    <div className="order-info-detail-right">
                        <div className="order-left-box-flex">
                            <div className="left-title">Bill to:</div>
                            <div className="left-value">
                                {billingAddress.firstName}{' '}
                                {billingAddress.lastName}
                                <br />
                                {billingAddress.address}
                                <br />
                                {billingAddress.city}, {billingAddress.province}{' '}
                                {billingAddress.postCode}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="order-shipping-box">
                <div className="order-info-detail-title">Actions</div>
                <Divider />
                {order?.ordershippinghistorys?.map((action) => (
                    <div key={action.id} className="order-action-box">
                        <div className="order-action-item-date">
                            {' '}
                            {moment(action.historyDate).format('lll')}
                        </div>
                        <div className="order-action-item-detail">
                            {action.hsitoryAction}
                        </div>
                    </div>
                ))}
            </div>

            <div className="order-shipping-box">
                <div className="order-info-detail-title">Shipping</div>
                <Divider />
                <div className="order-left-box-flex">
                    <div className="left-title">Shipping Type:</div>
                    <div className="left-value">
                        {order.ordershipping.shippingType === 1
                            ? order.ordershipping.shippingMethod === 1
                                ? 'Package'
                                : 'Freight'
                            : 'Pick up'}
                    </div>
                </div>
                {order.ordershipping.shippingType === 1 ? (
                    <>
                        {order?.ordershipping?.courier && (
                            <div className="order-left-box-flex">
                                <div className="left-title">Courier:</div>
                                <div className="left-value">
                                    {order.ordershipping.courier}
                                </div>
                            </div>
                        )}
                        {order?.ordershipping?.trackingNo && (
                            <div className="order-left-box-flex">
                                <div className="left-title">Tracking No:</div>
                                <div className="left-value">
                                    {order.ordershipping.trackingNo}
                                </div>
                            </div>
                        )}
                        {order?.ordershipping?.sentDate ? (
                            <div className="order-left-box-flex">
                                <div className="left-title">Sent Date:</div>
                                <div className="left-value">
                                    {moment(
                                        order.ordershipping.sentDate
                                    ).format('lll')}
                                </div>
                            </div>
                        ) : (
                            ''
                        )}
                    </>
                ) : (
                    <>
                        <div className="order-left-box-flex">
                            <div className="left-title">Pick Up Location:</div>
                            <div className="left-value">
                                {order.ordershipping.pickuplocation.address1},{' '}
                                {order.ordershipping.pickuplocation.address2}
                                <br />
                                {order.ordershipping.pickuplocation.city},{' '}
                                {order.ordershipping.pickuplocation.province},{' '}
                                {order.ordershipping.pickuplocation.country}{' '}
                                {order.ordershipping.pickuplocation.postCode}
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="order-items-box">
                <div className="order-info-detail-title">Items</div>
                <Divider />
                <div className="order-items">
                    {order.orderitems.map((item) => (
                        <ClientOrderInfoItem
                            key={item.id}
                            item={item}
                            promoCodeName={order.promoCodeName}
                            promoRate={order.promoRate}
                        />
                    ))}
                </div>
                <Divider />
                <div className="item-price-box">
                    <div className="item-price-item">Merchandise Subtotal</div>
                    <div className="item-price-price">
                        ${order.amount.toFixed(2)}
                    </div>
                </div>
                {order?.ordershipping?.shippingType === 1 ? (
                    <div className="item-price-box">
                        <div className="item-price-item">Shipping</div>
                        <div className="item-price-price">
                            {shippingFee
                                ? `$${shippingFee.toFixed(2)}`
                                : 'Free'}
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {order.promoCodeName && (
                    <div className="item-price-box">
                        <div className="item-price-item">
                            Promo Code {order.promoCodeName}{' '}
                            {order.promoRate ? ` - ${order.promoRate}%` : ''}
                        </div>
                        <div className="item-price-price">
                            - ${order.discount.toFixed(2)}
                        </div>
                    </div>
                )}
                <div className="item-price-box">
                    <div className="item-price-item">GST/HST</div>
                    <div className="item-price-price">
                        ${order.tax.toFixed(2)}
                    </div>
                </div>
                <Divider />
                <div className="item-price-box">
                    <div className="item-price-item price-total">
                        Order Total
                    </div>
                    <div className="item-price-price price-total">
                        ${order.total.toFixed(2)}
                    </div>
                </div>
                {order.orderpayments.length > 0 &&
                    order.orderpayments.map((payment) => (
                        <div className="item-price-box" key={payment.id}>
                            <div
                                className="payment-order-price-item"
                                style={{
                                    fontSize: '12px',
                                    lineHeight: '20px',
                                }}
                            >
                                <div>
                                    Invoice No:{' '}
                                    {payment?.orderinvoice?.invoiceNo}
                                </div>
                                <div>
                                    Payment on{' '}
                                    {moment(payment.paymentDate).format('ll')}
                                </div>
                                <div>
                                    Payment Method:{' '}
                                    {payment.paymentType === 'CC' ? (
                                        <span>
                                            {cartType(payment.cardType)} ****
                                            {payment.cardNo}
                                        </span>
                                    ) : (
                                        <span>{payment.paymentType}</span>
                                    )}
                                </div>
                            </div>
                            <div className="item-price-price">
                                ${payment.amount.toFixed(2)}
                            </div>
                        </div>
                    ))}
                <Divider />
                <div className="item-price-box">
                    <div className="item-price-item price-total">
                        Order Balance
                    </div>
                    <div className="item-price-price price-total">
                        ${(order.total - paymentAmount).toFixed(2)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OrderInfoBase
