import FaqItemList from './FaqItemList'

const FaqItem = ({ faq }) => {
    return (
        <div className="faq_item_box">
            <div className="faq_category">{faq.name}</div>
            <div className="faq_info">
                {faq.qnaitems.map((faqitem) => (
                    <FaqItemList key={faqitem.id} faqitem={faqitem} />
                ))}
            </div>
        </div>
    )
}

export default FaqItem
