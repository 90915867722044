import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import 'swiper/components/navigation/navigation.min.css'
import Grid from '@material-ui/core/Grid'

import SwiperCore, { Pagination, Navigation } from 'swiper/core'
import RecommendItem from './RecommendItem'
import { useCurrentWidth } from '../screenWidth'

SwiperCore.use([Pagination, Navigation])

const Recommend = ({ recommendLists }) => {
    const screenWidth = useCurrentWidth()

    return screenWidth > 768 ? (
        <div className="recommend_box">
            <Grid container spacing={0}>
                {recommendLists?.map((item) => (
                    <Grid item xs={4} key={item.id}>
                        <RecommendItem item={item} />
                    </Grid>
                ))}
            </Grid>
        </div>
    ) : (
        <div>
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                slidesPerGroup={1}
                loop={true}
                loopFillGroupWithBlank={true}
                navigation={true}
                className="mySwiper"
            >
                {recommendLists?.map((item) => (
                    <SwiperSlide key={item.id}>
                        <RecommendItem item={item} />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}

export default Recommend
