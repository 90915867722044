import { errorsReturn } from '../errorsReturn'
import { controlLoading } from './loadingActions'
import request from '../../config/request'
import { getErrors } from './errorActions'
import { GET_ALL_ORDERS, GET_ONE_ORDER } from '../types'
import { logout } from './clientActions'

export const getAllOrders = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        let res = await request.get(
            `client/order/listallbyclient`,

            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch({ type: GET_ALL_ORDERS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneOrder = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            client: { clientToken },
        } = getState()
        let res = await request.get(
            `client/order/listonebyclient/${id}`,

            {
                headers: { Authorization: `Bearer ${clientToken}` },
            }
        )
        dispatch({ type: GET_ONE_ORDER, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
