import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Container } from '@material-ui/core'
import history from '../history'
import LayoutNav from './layout/LayoutNav'

const Layout = ({ children }) => {
    const clientState = useSelector((state) => state.client)
    const { clientInfo } = clientState

    useEffect(() => {
        window.scrollTo(0, 0)
        if (!clientInfo) {
            history.replace('/login')
        }
    }, [clientInfo])

    return (
        clientInfo && (
            <Container disableGutters={true}>
                <div className="layout-box">
                    <div className="layout-nav">
                        <LayoutNav />
                    </div>
                    <div className="layout-children">{children}</div>
                </div>
            </Container>
        )
    )
}

export default Layout
