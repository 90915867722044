export const USER_ADD_SUCCESSSFULLY = 'User Added Successfully'
export const USER_UPDATE_SUCCESSSFULLY = 'User Updated Successfully'

export const POSITION_LEFT = 1

export const PICTURE_POINT_YELLOW = 'Yes'

export const SAMPLE_CABINET = 'cabinet-sample'
export const SAMPLE_COUNTERTOP = 'countertop-sample'

export const ADD_CART_SUCCESSSFULLY = 'Added successfully'
export const REMOVE_CART_SUCCESSSFULLY = 'Removed successfully'
export const ACCOUNT_ACTIVED =
    'Your account already actived, please login to your account'
export const RESEND_EMAIL_SUCCESS = 'Email sent successfully'
export const CLIENT_PROFILE_UPDATE_SUCCESS = 'Profile updated successfully'
export const CLIENT_FORGET_PASSWORD_SUCCESS = 'Please check your email'
export const CLIENT_RESET_PASSWORD_SUCCESS = 'Reset password successfully'

export const buildTypeLists = [
    {
        name: 'Kitchen',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1640031482/Request_Design/Screen_Shot_2021-12-20_at_3.17.58_PM_hl5myj.png',
        id: 1,
    },
    {
        name: 'Bathroom',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1640031501/Request_Design/Screen_Shot_2021-12-20_at_3.18.18_PM_tnlh4f.png',
        id: 2,
    },
    {
        name: 'Others',
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1640031523/Request_Design/Screen_Shot_2021-12-20_at_3.18.41_PM_srfnqw.png',
        id: 3,
    },
]

export const sizeMeasureLists = [
    { id: 1, name: 'ft' },
    { id: 2, name: 'm' },
]

export const requestPic = (buildType) => {
    let requestState = {}
    switch (buildType) {
        case 1:
            requestState = {
                buildPic:
                    'https://res.cloudinary.com/home-space/image/upload/v1640031482/Request_Design/Screen_Shot_2021-12-20_at_3.17.58_PM_hl5myj.png',
                buildTypeName: 'Kitchen',
            }
            break
        case 2:
            requestState = {
                buildPic:
                    'https://res.cloudinary.com/home-space/image/upload/v1640031501/Request_Design/Screen_Shot_2021-12-20_at_3.18.18_PM_tnlh4f.png',
                buildTypeName: 'Bathroom',
            }

            break
        case 3:
            requestState = {
                buildPic:
                    'https://res.cloudinary.com/home-space/image/upload/v1640031523/Request_Design/Screen_Shot_2021-12-20_at_3.18.41_PM_srfnqw.png',
                buildTypeName: 'Others',
            }

            break
        default:
            requestState = {}
    }
    return requestState
}

export const homePageItems = [
    {
        id: 1,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1650751135/Home_page/Icon_29_w6lcq1.png',
        title: 'E-Commence',
        subTitle: 'Buy your new kitchen in just a few clicks',
    },
    {
        id: 2,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1650751135/Home_page/Icon_30_vqwhkj.png',
        title: 'Design System',
        subTitle: 'Design your vision and avail expert designer advice',
    },
    {
        id: 3,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1650751136/Home_page/Icon_31_vuroqh.png',
        title: 'Price Transparency',
        subTitle: 'View each unit’s price to stay within budget while buying',
    },
    {
        id: 4,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1650751135/Home_page/Icon_32_vmsjxj.png',
        title: 'High-Quality & Craftsmanship',
        subTitle:
            'Impeccable craftsmanship, attention to detail, and strict quality adherence - we’ve got it all',
    },
    {
        id: 5,
        picLink:
            'https://res.cloudinary.com/home-space/image/upload/v1650751135/Home_page/Icon_33_vnrbmc.png',
        title: 'Quick Responses',
        subTitle:
            'Get quick answers to your questions from our customer support team',
    },
]

export const smallPicBackground = [
    { id: 0, color: '#56745B' },
    { id: 1, color: '#EDC400' },
    { id: 2, color: '#BAAA9E' },
]

export const homedecorBackground = [
    { id: 0, color: '#EDC400' },
    { id: 1, color: '#56745B' },
    { id: 2, color: '#6C5948' },
    { id: 2, color: '#BAAA9E' },
]

export const styleArr = ['transition', 'modern', 'classic']
